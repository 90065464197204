import React, { useState } from "react";
import { Link } from "react-router-dom";

import { api } from "@/apis";
import FormInput from "@/components/FormElements/FormInput";
// import Datepicker from "@/components/FormElements/Datepicker";
import Loader from "../../components/Loader/Loader";
import {
	Form,
	FormTitle,
	FormRow,
	FormActions,
	FormFooter,
	FormMessage,
} from "./styles";

const RegisterForm = (props) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [errors, setErrors] = useState("");
	const [hasError, setHasError] = useState(false);
	const [isEmailSent, setIsEmailSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		try {
			await api.post("/users/register", {
				firstName,
				lastName,
				email,
				code,
			});
			setHasError(false);
			setIsEmailSent(true);
		} catch (e) {
			setHasError(true);
			setErrors(e.response ? e.response.data.errors : []);
		} finally {
			setIsLoading(false);
		}
	};

	const renderErrors = () => {
		return Object.values(errors).map((error, i) => {
			return <p key={i}>{error}</p>;
		});
	};

	return (
		<Form onSubmit={onSubmit}>
			<FormTitle>Регистрация</FormTitle>

			<FormRow>
				<FormInput
					label="Име"
					name="firstname"
					type="firstname"
					placeholder="Иван"
					value={firstName}
					required
					onChange={(e) => {
						setFirstName(e.target.value);
					}}
				/>
			</FormRow>

			<FormRow>
				<FormInput
					label="Фамилия"
					name="lastname"
					type="lastname"
					placeholder="Иванов"
					value={lastName}
					required
					onChange={(e) => {
						setLastName(e.target.value);
					}}
				/>
			</FormRow>

			<FormRow>
				<FormInput
					label="Имейл адрес"
					name="email"
					type="email"
					placeholder="you@gmail.com"
					value={email}
					required
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>
			</FormRow>

			<FormRow>
				<FormInput
					label="Код за регистрация"
					name="code"
					type="code"
					placeholder="XXXXXX"
					value={code}
					required
					onChange={(e) => {
						setCode(e.target.value);
					}}
					maxLength={6}
				/>
			</FormRow>

			{hasError && (
				<FormMessage color="var(--danger)">{renderErrors()}</FormMessage>
			)}

			{isEmailSent && (
				<FormMessage color="var(--success-dark)">
					<p>
						Изпратихме ви имейл. За да продължите регистрацията следвайте
						инструкциите от имейла.
					</p>
				</FormMessage>
			)}

			<FormActions>
				<button className="btn btn--block" type="submit">
					{!isLoading ? "Регистрация" : <Loader />}
				</button>
			</FormActions>

			<FormFooter>
				<div>
					<strong>
						Вече имаш акаунт?{" "}
						<Link className="link" to="/auth">
							Вход
						</Link>
					</strong>
					<div>
						* Регистрацията е активна само за потребители, получили покана с код
						за достъп.
					</div>
				</div>
			</FormFooter>
		</Form>
	);
};

export default RegisterForm;
