// import iconEarobicsSrc from "@/assets/images/ico-aerobics.svg";
// import iconHighIntensitySrc from "@/assets/images/ico-highintensity.svg";
// import iconStabilitySrc from "@/assets/images/ico-stability.svg";
// import iconStrengthSrc from "@/assets/images/ico-strength.svg";
// import iconStretchingSrc from "@/assets/images/ico-stretching.svg";

import { PhysicalActivityContainer, PhysicalActivityTitle } from "../styles";
import Field from "./Field";
import { translateArray, translateValues } from "./translation";

const PhysicalActivity = ({ physicalHealth }) => {
	const translated = translateValues(physicalHealth);
	return (
		<div>
			<PhysicalActivityTitle>Физическа активност</PhysicalActivityTitle>
			<PhysicalActivityContainer>
				<p>
					<Field
						header={"1. Ниво на физическа активност"}
						paragraph={translated.activityLevel}
					/>

					<Field
						header={"2. Видове упражнения"}
						paragraph={translateArray(physicalHealth.exercise.types).join(", ")}
					/>

					<Field
						header={"3. Часове тренировка на седмица"}
						paragraph={`${physicalHealth.exercise.time} часа`}
					/>

					<Field
						header={"4. Честота на силов тренинг"}
						paragraph={translated.resistanceTrainingFrequency}
					/>

					<Field
						header={"5. Дейности за кардиоваскуларно здраве"}
						paragraph={translated.activitiesCardiovascularHealth}
					/>

					<Field
						header={"6. Управление на интензивността"}
						paragraph={physicalHealth.exercise.intensityManagement}
					/>
					<Field
						header={"7. Стратегия за възстановяване"}
						paragraph={translated.recoveryAndRestApproach}
					/>

					<Field
						header={"8. Включване на упражнения за дълголетие"}
						paragraph={physicalHealth.exercise.considerationOfLongevitySpecific}
					/>

					<Field
						header={"9. Оценка на тренировъчната рутина"}
						paragraph={translated.exercise.routineEnjoymentSustainability}
					/>

					<Field
						header={"10. Програмиране на физическата активност"}
						paragraph={translated.trackingFitnessDevices}
					/>

					<Field
						header={
							"11. Роля на физическата активност в подхода към здравето и дълголетието"
						}
						paragraph={translated.roleOfPhysicalActivityInHealthLongevity}
					/>
				</p>
			</PhysicalActivityContainer>
		</div>
	);
};

export default PhysicalActivity;

// {
/* <div>
			<PhysicalActivityTitle>
				Физическа активност през седмицата
			</PhysicalActivityTitle>
			<PhysicalActivityContainer>
				<div className="exercise__card">
					{additionalInfo?.physicalActivity?.aerobicExercise?.has ? (
						<>
							<img src={iconEarobicsSrc} alt="Аеробни упражнения" />
							<p>
								Аеробни упражнения:{" "}
								{additionalInfo?.physicalActivity?.aerobicExercise?.details}
							</p>
						</>
					) : (
						<p>Не прави аеробни упражнения</p>
					)}
				</div>

				<div className="exercise__card">
					{additionalInfo?.physicalActivity?.highIntensityExercise?.has ? (
						<>
							<img
								src={iconHighIntensitySrc}
								alt="Високоинтензивни упражнения"
							/>
							<p>
								Високоинтензивни упражнения:{" "}
								{
									additionalInfo?.physicalActivity?.highIntensityExercise
										?.details
								}
							</p>
						</>
					) : (
						<p>Не прави високоинтензивни упражнения</p>
					)}
				</div>

				<div className="exercise__card">
					{additionalInfo?.physicalActivity?.stabilityExercise?.has ? (
						<>
							<img src={iconStabilitySrc} alt="Упражнения за стабилност" />
							<p>
								Упражнения за стабилност:{" "}
								{additionalInfo?.physicalActivity?.stabilityExercise?.details}
							</p>
						</>
					) : (
						<p>Не прави упражнения за стабилност</p>
					)}
				</div>

				<div className="exercise__card">
					{additionalInfo?.physicalActivity?.strengthExercise?.has ? (
						<>
							<img src={iconStrengthSrc} alt="Упражнения за сила" />
							<p>
								Упражнения за сила:{" "}
								{additionalInfo?.physicalActivity?.strengthExercise?.details}
							</p>
						</>
					) : (
						<p>Не прави упражнения за сила</p>
					)}
				</div>

				<div className="exercise__card">
					{additionalInfo?.physicalActivity?.stretchingExercise?.has ? (
						<>
							<img src={iconStretchingSrc} alt="Упражнения за разтягане" />
							<p>
								Упражнения за разтягане:{" "}
								{additionalInfo?.physicalActivity?.stretchingExercise?.details}
							</p>
						</>
					) : (
						<p>Не прави упражнения за разтягане</p>
					)}
				</div>
			</PhysicalActivityContainer>
		</div> */
// }
