import styled from "styled-components";

export const DeleteComponent = styled.div`
    padding: 20px;

    .delete__title {
        font-size: 28px;
        text-align: center;
    }
    .delete__desc {
        text-align: center;
        font-size: 18px;
    }
    .delete__names {
        text-align: center;
        margin-top: 30px;
        font-size: 20px;
    }
    .delete__buttons {
        padding: 5px 20px;
        display: flex;
        justify-content: space-around;
    }
    
    .btn__delete {
		border: 1px solid var(--danger-dark);
		background-color: var(--danger-dark);
		transition: 0.2s;

		&:hover {
			background: var(--danger-dark);
    		border-color: var(--danger-dark);
			opacity: 0.7;
		}
	}
`;