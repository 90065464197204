import styled from "styled-components";

export const ProfileContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;

	@media (max-width: 768px) {
		gap: 20px;
	}

	.profile__col {
		width: 33%;
	}
	.profile__col--title {
		display: flex;
		justify-content: center;
		gap: 15px;
	}
	.profile__col--actions {
		display: flex;
		justify-content: center;
		gap: 10px;
	}

	.profile-btn {
		border: none;
		width: 42px;
		height: 42px;
		padding: 8px;
	}
	.profile-btn.profile-btn__edit {
		background-color: var(--yellow);
	}
	.profile-btn.profile-btn__delete {
		background-color: var(--danger-dark);
	}

	@media (max-width: 768px) {
		.desktop__only {
			display: none;
		}

		.profile__col {
			width: 100%;
		}
	}
`;

export const DropdownMenu = styled.div`
	position: absolute;
	top: 100%;
	z-index: 1;
	padding: 10px 15px;
	left: -120px;
	display: flex;
	gap: 10px;
	background-color: #eaeaea;
	margin-top: 5px;
	border-radius: 15px;
	display: ${(props) => (props.isOpen ? "flex" : "none")};
`;
