import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import AnalysisItem from "@/components/admin/users/components/AnalysisItem";
import Section from "@/components/section/Section/Section";
import Loader from "@/components/Loader/LoaderBig";
import Tabs from "@/components/Tabs/Tabs";

import { NoResults, Analys } from "./styles";

const Protocols = (props) => {
	const { user } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [protocols, setProtocols] = useState([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (user.role === "admin") {
			history.push("/dashboard/admin");
		}
	}, [user, history]);

	useEffect(() => {
		setProtocols(user.analyses);
		setIsLoading(false);
	}, [user]);

	const months = [
		"януари",
		"февруари",
		"март",
		"април",
		"май",
		"юни",
		"юли",
		"август",
		"септември",
		"октомври",
		"ноември",
		"декември",
	];

	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		const day = date.getUTCDate();
		const month = months[date.getUTCMonth()];
		const year = date.getUTCFullYear();
		return (
			<>
				<strong>{`${month} ${day},`}</strong> {year}
			</>
		);
	};

	const renderAnalysisItems = (analysisCategory) => {
		if (analysisCategory && analysisCategory.length > 0) {
			return (
				<>
					{analysisCategory
						.slice()
						.reverse()
						.slice(0, isExpanded ? analysisCategory.length : 3)
						.map((item, index) => (
							<AnalysisItem
								key={index}
								text={item.text}
								date={formatDate(item.date)}
							/>
						))}
					{analysisCategory.length > 3 && (
						<button
							className="btn analysis__toggle"
							onClick={() => setIsExpanded(!isExpanded)}
						>
							{isExpanded ? "Скрий" : "Покажи всички"}
						</button>
					)}
				</>
			);
		} else {
			return <p>Няма анализи в тази категория.</p>;
		}
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Loader />
			) : isEmpty(protocols) ? (
				<NoResults>
					<h3 className="noresults__title">Няма налични Анализи/Протоколи.</h3>
				</NoResults>
			) : (
				<Section>
					<Section.Head flex>
						<h3 style={{ marginBottom: "20px" }}>Всички протоколи</h3>
					</Section.Head>

					<Section.Body>
						{protocols && (
							<Analys>
								<Tabs>
									<Tabs.Tab linkName="Подробни">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.detailed)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Обобщени">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.summary)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Хранителни">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.nutrition)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Добавки">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.supplements)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Физически">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.physical)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Сън">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.sleep)}
										</div>
									</Tabs.Tab>
									<Tabs.Tab linkName="Стрес">
										<div
											className={`analysis__list ${
												isExpanded ? "expanded" : ""
											}`}
										>
											{renderAnalysisItems(protocols.stress)}
										</div>
									</Tabs.Tab>
								</Tabs>
							</Analys>
						)}
					</Section.Body>
				</Section>
			)}
		</React.Fragment>
	);
};

export default connect((state) => ({
	user: state.user,
}))(Protocols);
