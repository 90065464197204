import styled from "styled-components";

export const Package = styled.div`
	.active & {
		.package__title__icon {
			background: #fff;
		}
	}

	.package__row {
		display: flex;
		align-items: center;
		margin: 0 -10px;

		@media (max-width: 768px) {
			align-items: flex-start;
			flex-direction: column;
			gap: 20px;
		}
	}

	.package__col {
		width: 32%;
		padding: 0 10px;
		display: flex;
		align-items: center;

		&.small {
			width: 19%;
		}

		&.large {
			width: 27%;
		}

		@media (max-width: 768px) {
			width: 100%;
			justify-content: space-between;

			&.small {
				width: 100%;
			}

			&.large {
				width: 100%;
			}
		}
	}

	.package__col.col-actions {
		justify-content: flex-end;
		@media (max-width: 768px) {
			justify-content: space-between;
		}
	}

	.package__title {
		display: flex;
		align-items: center;
		margin: 0;
	}

	.package__title__icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin-right: 15px;
		background: #ecf1f8;
		transition: background 0.3s;
	}

	.package__cards {
		display: flex;
		margin: 0 -12px;
	}

	.package__actions {
		padding: 16px 0;
	}

	.gpt-loading {
		display: inline;
	}

	@media (max-width: 768px) {
		.package__row {
			align-items: center;
		}
		.package__title__text {
			width: 100%;
			text-align: center;
		}
		.btn__share {
			margin-left: 39px;
		}

		.gpt-loading {
			display: inline;
		}
	}
`;

export const UserProfileBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	.warning__noinfo {
		margin-top: 20px;
	}

	.top__boxes {
		display: flex;
		gap: 20px;
	}

	.general__info__box {
		display: flex;
		align-items: center;
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		flex-grow: 3;
	}

	.profile__user__icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		border-radius: 10px;
		margin-right: 15px;
		background: #ecf1f8;
		transition: background 0.3s;
	}

	.profile__user__icon__img {
		width: 60%;
		height: 60%;
	}

	.profile__user__info {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	.profile__user__name {
		margin-bottom: 8px;
	}

	.medical__history__box {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		flex-grow: 2;
	}

	.medical__history__text {
		margin: 0;
	}

	.detailed__info__box {
		border-radius: 10px;
	}

	.analysis__list {
		display: flex;
		flex-direction: column;
		gap: 20px;
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		align-items: center;
	}

	.send__analysis {
		display: flex;
		justify-content: space-between;
		padding: 20px 0;
		gap: 20px;
	}

	.text__analysis {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.title__text__analysis {
		font-weight: 500;
		font-size: 18px;
	}

	.success-message-container {
		height: 24px;
		line-height: 24px;
		color: var(--success);
		font-size: 0.9rem;
		text-align: center;
		transition: opacity 0.3s ease-in-out;
		opacity: 0;
	  }
	  
	  .success-message-container.show {
		opacity: 1;
	  }

	  .error__message {
		color: var(--danger);
	  }

	.input__text__analysis {
		width: 100%;
		height: 140px;
		resize: none;
		border: 1px solid var(--blue);
		padding: 10px;
		border-radius: 5px;
		transition: box-shadow 0.2s;

		&:focus {
			outline: none; 
			transition: box-shadow 0.2s;
			box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
		}
	}

		.file__analysis {
			background-color: #fff;
			padding: 20px;
			border-radius: 10px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 20px;
			width: 35%;
		}

		.file__box {
			margin-top: 40px;
			position: relative;
			border: 2px dashed var(--blue);
			padding: 20px;
			text-align: center;
			transition: border-color 0.3s;
			width: 100%;
			height: 140px;

			&:hover {
				border-color: var(--blue-dark);
			}
		}

		.placeholder-text {
			color: var(--blue);

			&:hover {
				color: var(--blue-dark);
			}
		}

		.file__analysis input[type="file"] {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
			z-index: 1;
		}
	}
`;

export const PhysicalActivityContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 20px;

	.exercise__card {
		flex-basis: calc(20% - 2em);
		width: calc(20% - 2em);
		height: 170px;
		margin: 1em;
		padding: 1em;
		border: 1px solid #ccc;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		text-align: center;
		box-sizing: border-box;
	}

	.exercise__card img {
		width: 50px;
		height: 50px;
		margin-bottom: 1em;
	}

	.exercise__card p {
		margin-top: 0.5em;
	}
`;

export const PhysicalActivityTitle = styled.h5`
	margin-top: 20px;
	font-weight: 500;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const NaturalHabitsContainer = styled.div`
	padding: 20px;

	.meal__description {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.meal__description__text {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
	}

	.meal__times {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		margin: 20px 0;
	}

	.meal__time {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border: 1px solid #e0e0e0;
		border-radius: 5px;
		background-color: #ffffff;
	}

	.meal__time img {
		width: 100px;
		height: 100px;
		margin-bottom: 1em;
	}

	.meal__part {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: 500;
	}

	.meal__time__value {
		font-size: 16px;
		font-weight: 500;
	}

	.daily__consumption__title {
		margin-top: 10px;
		font-weight: 500;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.weekly__consumption__title {
		margin-top: 40px;
		font-weight: 500;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.weekly__consumption {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 2px;
	}

	.food__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 120px;
		margin: 1em;
		padding: 1em;
		border: 1px solid #ccc;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		text-align: center;
		box-sizing: border-box;
	}

	.food__item img {
		width: 50px;
		height: 50px;
		margin-bottom: 1em;
	}

	.food__item__count {
		font-size: 16px;
		font-weight: 500;
	}
`;

export const SleepHabitsContainer = styled.div`
	padding: 20px;

	.sleep__issues {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px;
		margin: 20px 0;
	}

	.sleep__issues__title {
		font-weight: 500;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.sleep__issue__text {
		font-size: 1rem;
		margin: 0;
	}

	.sleep__duration {
		display: flex;
		justify-content: center;
		gap: 20px;
		padding: 20px 120px;
	}

	.sleep__hours,
	.naps__count {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		border: 1px solid #e0e0e0;
		border-radius: 5px;
		background-color: #ffffff;
	}

	.sleep__hours__count,
	.naps__count__value {
		font-size: 16px;
		font-weight: 500;
	}

	.sleep__hours img {
		width: 100px;
		height: 100px;
		margin-bottom: 1em;
	}

	.naps__count img {
		width: 100px;
		height: 100px;
		margin-bottom: 1em;
	}
`;

export const AdditionalHabitsContainer = styled.div`
	padding: 20px;

	.habits__title {
		font-weight: 500;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.habit__description {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.habit__description__text {
		margin: 0;
		font-size: 16px;
	}

	.alcohol__consumption {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.alcohol__consumption__value {
		margin-left: 10px;
		font-weight: bold;
	}
`;

export const AnalysisItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #f7f7f7;
	gap: 20px;
	border-radius: 10px;
	padding: 20px;
	width: 100%;

	.analysis__text {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
	}
`;

export const Container = styled.div`
	margin-top: 20px;

	table {
		width: 100%;
		border-collapse: collapse;
		border-radius: 10px;
		overflow: hidden;
	}

	th,
	td {
		border: 1px solid black;
		padding: 8px 10px;
		text-align: center;
		border: none;
	}

	th {
		background-color: var(--blue);
		color: #fff;
		border: none;
	}

	tr:nth-child(odd) {
		background-color: #fff;
	}

	tr:nth-child(even) {
		background-color: #325cdd33;
	}

	td:not(:nth-child(1)) {
		width: 5%;
		white-space: nowrap;
	}
`;

export const MemberInfo = styled.div`
	margin-bottom: 20px;
`;

export const MemberName = styled.h4`
	font-size: 1.2em;
	margin-bottom: 10px;
	color: #333;
`;

export const InfoItem = styled.div`
	margin-bottom: 5px;
	font-size: 0.9em;

	strong {
		color: #555;
	}
`;
