const faqData = [
	{
		question:
			"Why do I need a 40+ biomarker blood test? Can't I just opt for a Complete Blood Count test?",
		answer:
			"While a Complete Blood Count (CBC) provides valuable insights, a comprehensive blood test with 40+ biomarkers delves deeper. It evaluates various aspects of health by each organ, giving a large context of your health parameters, such as cholesterol levels, inflammation markers, and micronutrient levels, offering a more comprehensive view of your health. This broader scope allows for early detection of potential issues and a more personalized approach to proactive prevention.",
	},
	{
		question:
			"What can I see on my genetic test that can't be seen on a blood test?",
		answer:
			"Genetic tests unveil your unique DNA blueprint, providing information about your inherited traits, disease predispositions, and potential health risks. Unlike blood tests, which capture current physiological states, genetic tests offer insights into your genetic makeup, empowering you to make informed decisions about your genetically preferred lifestyle and preventive measures based on your genetic predispositions.",
	},
	{
		question: "Why should I worry about genes that may never become active?",
		answer:
			"Understanding potential genetic risks doesn't imply certainty; it's about awareness and proactive prevention. Armed with this knowledge, you can adopt lifestyle changes and screenings tailored to your genetic profile, reducing the likelihood of certain conditions. It's not about worry but rather empowering you to proactively control your health. Over time, we will have a progressive improvement over testing, diagnostic, and prevention strategies, therefore, knowing your personal risks will empower you to care for your health better.",
	},
	{
		question:
			"I already have a 23andMe genetic test. What is the difference with a gene test that is focused on health monitoring and prevention?",
		answer:
			"While 23andMe provides ancestry and basic health information, a health-focused genetic test goes deeper into analyzing specific health-related genes where established intervention can reduce their potentially pathologic effect. It offers actionable insights into disease risks, nutrient metabolism, biological processes of activating hormones, and cellular processes, enabling personalized health monitoring and prevention strategies. It's a targeted approach to optimize your well-being and minimize health risks.",
	},
	{
		question:
			"Isn't it just about eating healthy, exercising, and sleeping well? What's there to personalize about it?",
		answer:
			"Personalization in health optimization considers your unique genetic makeup, blood markers, and lifestyle. It tailors recommendations based on your specific needs and biological preferences, ensuring that the pillars of good health, such as your nutrition, exercise, and sleep plans, are finely tuned to maximize their effectiveness for your individual health goals. It's about optimizing these general principles to fit you like a glove.",
	},
	{
		question: "What's the difference between Medicine 2.0 and 3.0?",
		answer:
			"Medicine 2.0 focuses on treating existing conditions reactively. Medicine 2.0 gave us valuable insights into the biological mechanisms of acute diseases and has greatly reduced mortality by integrating medical and surgical interventions optimized to treat diseases but not necessarily cure them. While Medicine 2,0 has used rigorous, randomized clinical trials to assess the safety of the treatments, the conclusions from these are made under the assumption that we are all the same, so-called “normal distribution” of people, where statistically, most of us would benefit from treatment. In contrast, Medicine 3.0 shifts the paradigm towards proactive prevention and personalized care. Taking into account the personal habits, traits, local microbiomes, and genetic variants that make us all individuals, Medicine 3.0 proposes an intelligent approach to understanding one’s health risks and taking well-calculated measures to reduce them. It harnesses advanced technologies, genetic insights, and comprehensive health data to tailor interventions before diseases manifest, emphasizing a holistic and individualized approach to well-being.",
	},
	{
		question: "What is the difference between lifespan and longevity?",
		answer:
			"Lifespan refers to the total number of years a person lives, while longevity encompasses the duration and quality of life in those years. Longevity emphasizes maintaining optimal health and vitality throughout life, extending the period of healthy and active living rather than merely extending the overall lifespan.",
	},
	{
		question:
			"What is the importance & difference of healthspan over the lifespan?",
		answer:
			"Healthspan is about maximizing the number of healthy, vibrant years, focusing on the quality of life rather than just its duration. While lifespan is the total years one lives, healthspan emphasizes living those years to the fullest, free from debilitating diseases and with optimal physical and mental well-being.",
	},
	{
		question:
			"Extending lifespan vs. extending healthspan vs. reversing aging?",
		answer:
			"Extending lifespan aims to increase the total years of life. Extending healthspan goes a step further, promoting a longer period of vibrant and disease-free living. Reversing aging involves interventions to undo or mitigate the biological aging process, striving for a rejuvenated physiological state. The ultimate goal is not just a longer life but a longer, healthier, and more fulfilling one",
	},
];

export default faqData;
