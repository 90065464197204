import React from 'react';

import {
	Filters,
	Filter
} from './styles';

const FiltersComponent = ({children}) => {
	return (
		<Filters className="filters">
			<div className="filters__container">
				{children}
			</div>
		</Filters>
	);
}

FiltersComponent.Filter = ({value, label, name, options, onChange}) => {
	return (
		<Filter className="filter">
			<div className="filter__label">{label}</div>

			<div className="select">
				<select name={name} onChange={onChange} value={value}>
					{options.map((option, index) => {
						return (
							<option key={`${name}-${index}`} value={option.value}>{option.label}</option>
						);
					})}
				</select>
			</div>
		</Filter>
	);
}

export default FiltersComponent;
