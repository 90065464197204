import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "@/apis";
import { fetchSharedResult } from "@/ducks/sharedResults";

import dayjs from "@/components/configureDayjs";

import Accordion from "@/components/Accordion/Accordion";
import BiomarkerSimple from "@/components/biomarkers/BiomarkerSimple/BiomarkerSimple";
import BiomarkerDetail from "@/components/biomarkers/BiomarkerDetail/BiomarkerDetail";
import Filters from "@/components/filters/Filters";
import IconText from "@/components/ui/IconText/IconText";

import iconCalendarSrc from "@/assets/images/ico-calendar.svg";

import { PinDiv, PackageBought, Form, FormMessage } from "./styles";

const Pin = (props) => {
	const [pins, setPins] = useState(Array(5).fill(""));
	const [date, setDate] = useState("");
	const [errors, setErrors] = useState("");
	const { id } = useParams();
	const { results, fetchSharedResult } = props;

	const result = (results && results[id])?results[id].results:null;
	const isExpired = (results && results[id])?dayjs().diff(dayjs(results[id].expires))>0:true;

	const inputRefs = useRef([]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const pin = pins.join('')
		api.post(`/shared-result/${id}/authorize`, { pin })
			.then((response) => {
				fetchSharedResult(id).catch(e => {
					setErrors({ message: e.response.data.message });	
				})
			})
			.catch((e) => {
				setErrors({ message: e.response.data.message });
			});
	};

	const handleInputChange = (index, value) => {
		const newPins = [...pins];
		newPins[index] = value;
		setPins(newPins);

		if (value && index < inputRefs.current.length - 1) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (event, index) => {
		if (event.key === "Backspace" && !pins[index] && index > 0) {
			inputRefs.current[index - 1].focus();
		}
	};

	const handlePaste = (event) => {
		event.preventDefault();

		const pastedData = event.clipboardData.getData("text/plain");
		const newPins = pastedData.slice(0, 5).split("").map((char) => char.slice(0, 1));

		setPins(newPins);
		inputRefs.current[4].focus();
	};

	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return <p key={i}>{error}</p>;
			});
		}
	};

	useEffect(() => {
		if (result && !isExpired) {
			const dateObj = new Date(result[0].filter((item) => item.current).map((item) => item.dateResults));
			let year = dateObj.getFullYear();
			const months = ["Януари", "Февруари", "Март", "Април", "Май", "Юни", "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември"];
			const monthName = months[dateObj.getMonth()];
			let day = dateObj.getDate();

			if (day < 10) {
				day = "0" + day;
			}

			setDate({
				year: year,
				month: monthName,
				day: day,
			});
		}
	}, [result, isExpired]);
	return (
		<PinDiv>
			{result && !isExpired ? (
				<PackageBought>
					<div className="package__head">
						<div className="package__head__inner">
							<Filters>
								<IconText icon={iconCalendarSrc} iconAlt="Икона на календар">
									<strong>
										{date.month} {date.day},
									</strong>{" "}
									{date.year}
								</IconText>
							</Filters>
						</div>
					</div>
					<div className="package__body">
						<div className="package__table">
							<div className="package__table__head">
								<div className="package__table__row">
									<div className="package__table__col large">
										<div className="package__table__label">
											Име на теста (изследване)
										</div>
									</div>

									<div className="package__table__col small">
										<div className="package__table__label">Резултат</div>
									</div>

									<div className="package__table__col small">
										<div className="package__table__label">Единици</div>
									</div>

									<div className="package__table__col">
										<div className="package__table__label">
											Референтни стойности
										</div>
									</div>

									<div className="package__table__col medium">
										<div className="package__table__label">Графика</div>
									</div>
								</div>
							</div>
						</div>
						{result.map((array, index) =>
							array.filter((item) => item.current)
								.map((item) => item.value &&(
									<Accordion key={`${Math.random()}`}>
										<Accordion.Head>
											<BiomarkerSimple
												title={item.biomarker[0].label}
												result={item.value}
												unit={item.biomarker[0].metric}
												id={index}
												referent={{
													min: `${item.referenceValues.min}`,
													max: `${item.referenceValues.max}`,
												}}
												packageId={id}
											/>
										</Accordion.Head>
										<Accordion.Body>
											<BiomarkerDetail
												id={index}
												description={item.biomarker[0].description}
												packageId={id}
											/>
										</Accordion.Body>
									</Accordion>
								))
							)}
					</div>
				</PackageBought>
			) : (
				<Form onSubmit={handleSubmit}>
					<label className="pin__desc">За да достъпите споделените резултати, трябва да въведете 5-цифрения PIN код:</label>
					<div className="pin__container">
						{pins.map((pin, index) => (
							<input
								key={index}
								className="pin__input"
								type="number"
								min={0}
								max={9}
								value={pin}
								onChange={(e) => handleInputChange(index, e.target.value)}
								onKeyDown={(e) => handleKeyDown(e, index)}
								onPaste={handlePaste}
								ref={(el) => (inputRefs.current[index] = el)}
							/>
						))}
					</div>
					<button className="btn" type="submit">Преглед</button>
					<FormMessage color="var(--danger)">{renderErrors()}</FormMessage>
				</Form>
			)}
		</PinDiv>
	);
};

export default connect(
	(state) => ({
		results: state.sharedResults.list,
	}),
	{
		fetchSharedResult,
	}
)(Pin);
