import styled from 'styled-components';

export const PackageBought = styled.section`
	margin-bottom: 22px;

	.package__head__inner {
		display: flex;
		justify-content: space-between;
		margin: 24px 0;
	}

	.package__title {
		display: flex;
		align-items: center;
		margin: 0;
	}

	.package__title__icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		border-radius: 10px;
		margin-right: 15px;
		background: #fff;
		box-shadow: var(--shadow);
		transition: background .3s;
	}

	.package__table__head {
		padding: 0 62px 8px 24px;
	}

	.package__table__row {
		display: flex;
	}

	.package__table__col {
		width: 22%;

		&.small {
			width: 14%;
		}

		&.medium {
			width: 24%;
		}

		&.large {
			width: 26%;
		}
	}

	.package__table__label {
		font-size: 12px;
	}

	.filters {
		margin: 0;
	}

	.package__actions {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	@media screen and (max-width: 768px) {
		.package__head__inner {
			flex-direction: column;
			gap: 16px;
		}
		.filters__container {
			align-items: flex-start;
		}
		.btn__share {
			padding: 0 16px;
		}

		.package__table {
			display: none;
		}

		.package__actions {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			margin-top: 10px;
		}
	}
`;
