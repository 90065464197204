import React from "react";
import { FooterNavContainer, FooterLink } from "./styles";

const GDPRFooter = () => {
	return (
		<FooterNavContainer>
			<FooterLink
				// target="_blank"
				href="#"
			>
				Общи условия
			</FooterLink>
			<FooterLink
				// target="_blank"
				href="#"
			>
				Политика за бисквитки
			</FooterLink>
			<FooterLink
				// target="_blank"
				href="#"
			>
				Политика за личните данни
			</FooterLink>
		</FooterNavContainer>
	);
};

export default GDPRFooter;
