import React from "react";
import iconFruitSrc from "@/assets/images/ico-fruit.svg";
import iconVegetableSrc from "@/assets/images/ico-vegetable.svg";
import iconMeatSrc from "@/assets/images/ico-meat.svg";
import iconFishSrc from "@/assets/images/ico-fish.svg";
import iconSnacksSrc from "@/assets/images/ico-snacks.svg";
import iconCoffeeSrc from "@/assets/images/ico-coffee.svg";
import iconTeaSrc from "@/assets/images/ico-tea.svg";
// import iconClockSrc from "@/assets/images/ico-clock.svg";

import { NaturalHabitsContainer } from "../styles";
import { translateValues } from "./translation";

const NutritionalHabits = ({ habits }) => {
	const translated = translateValues(habits);
	return (
		<NaturalHabitsContainer>
			<h5 className="daily__consumption__title">Хранения през деня</h5>

			<div className="meal__description">
				<p className="meal__description__text">
					Алергии към храни - {translated.foodAllergies}
				</p>
				<p className="meal__description__text">
					{translated.numberOfFeedingsPerDay} хранения на ден
				</p>
				<p className="meal__description__text">
					Описание на начин на хранене - {translated.approach}
				</p>
				<p className="meal__description__text">
					Главен източник на протеин - {translated.primaryProteinSource}
				</p>
				<p className="meal__description__text">
					Консумация преработени или рафинирани храни -{" "}
					{translated.processedRefinedFoodsConsumption}
				</p>
				<p className="meal__description__text">
					Tипичен източник на хранителни мазнини -{" "}
					{translated.typicalDietaryFatsSource}
				</p>
			</div>

			<h5 className="weekly__consumption__title">
				Консумация на различни храни през седмицата
			</h5>

			<div className="weekly__consumption">
				<div className="food__item">
					<img src={iconFruitSrc} alt="Плодове" />{" "}
					<span className="food__item__count">
						{translated.portionsOfFruitPerWeek}
					</span>
				</div>
				<div className="food__item">
					<img src={iconVegetableSrc} alt="Зеленчуци" />{" "}
					<span className="food__item__count">
						{translated.portionsOfVegetablesPerWeek}
					</span>
				</div>
				<div className="food__item">
					<img src={iconMeatSrc} alt="Червено месо" />{" "}
					<span className="food__item__count">
						{translated.portionsOfRedMeatPerWeek}
					</span>
				</div>
				<div className="food__item">
					<img src={iconFishSrc} alt="Риба" />{" "}
					<span className="food__item__count">
						{translated.portionsOfFishPerWeek}
					</span>
				</div>
				<div className="food__item">
					<img src={iconSnacksSrc} alt="Снаксове и сладки" />{" "}
					<span className="food__item__count">
						{translated.portionsOfSnacksAndSweetsPerWeek}
					</span>
				</div>
				<div className="food__item">
					<img src={iconCoffeeSrc} alt="Кафе" />{" "}
					<span className="food__item__count">
						{translated.caffeinatedDrinksPerDay}
					</span>
				</div>
				<div className="food__item">
					<img src={iconTeaSrc} alt="Билков чай" />{" "}
					<span className="food__item__count">
						{translated.herbalTeasPerDay}
					</span>
				</div>
			</div>
		</NaturalHabitsContainer>
	);
};

export default NutritionalHabits;
