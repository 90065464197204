// Footer.js
import React, { useRef } from "react";
import logoSrc from "@/assets/images/logoWhite.png";
import { Link } from "react-router-dom";
import instagramIcon from "@/assets/images/instagram.svg";
import fbIcon from "@/assets/images/fb.svg";
import linkedinIcon from "@/assets/images/linkedin.svg";
import styled from "styled-components";
import useIntoView from "../../hooks/useIntoView";
import { SlideUp } from "./styles";
import JoinForm from "./joinForm";
import { media } from "../../GlobalStyles";

const FooterContainer = styled.footer`
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
`;

const CTAContainer = styled.div`
	background-color: #f2f7fb;
	padding: 60px 0;
	text-align: center;
	${media.md`
		padding: 20px 0;
	`}
`;

const FormHolder = styled(SlideUp)`
	width: 900px;
	margin: 0 auto;
	${media.md`
		width: 300px;
	`}
`;

const FooterContent = styled.div`
	max-width: 1200px;
	padding: 30px 0;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	${media.md`
		flex-direction: column;
		gap: 24px;
	`}
`;

const FooterBackground = styled.div`
	background-color: #060464;
	${media.md`
		padding: 0 16px;
	`}
`;

const FooterLogoAndRights = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 30px;
	color: #fff;
	font-size: 18px;
`;

const FooterLogo = styled.img`
	width: 165px;
	height: 40px;
`;

const FooterRights = styled.div`
	font-size: 18px;
	color: #fff;
	margin: 0;
	${media.md`
		font-size: 12px;
		letter-spacing: 0;
		line-height: 13px;
	`}
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 30px;

	${media.md`
		flex-direction: column-reverse;
		align-items: flex-start;
		gap: 15px;
	`}
`;

const SocialLinks = styled.div`
	display: flex;
	align-items: space-between;
	gap: 15px;
`;

const Icon = styled.a`
	width: 32px;
	height: 32px;
	background: #8382b2;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background 0.3s;
	&:hover {
		background: #b5b4d1;
	}

	& > img {
		height: 14px;
		width: 14px;
	}
`;

const LegalLinks = styled.div`
	display: flex;
	gap: 30px;

	a {
		color: #fff;
		text-decoration: none;
		font-size: 18px;

		${media.md`
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 13px;
		`}
	}
`;

const HeaderComponent = styled.h2`
	font-weight: 600;
	font-size: 36px;
	letter-spacing: 0;
	line-height: 39px;
	margin-bottom: 60px;

	${media.md`
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;
		margin-bottom: 24px;
	`}
`;

const Form = styled(JoinForm)`
	margin: 0 auto;
	justify-content: center;
`;

const Footer = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);

	return (
		<FooterContainer ref={observeEl}>
			<CTAContainer>
				<FormHolder seen={seen}>
					<HeaderComponent>
						Join the waiting list now to get exclusive access to the platform
						when we launch
					</HeaderComponent>
					<Form />
				</FormHolder>
			</CTAContainer>
			<FooterBackground>
				<FooterContent>
					<FooterLogoAndRights>
						<FooterLogo src={logoSrc} alt="NeoLife лого" />
						<FooterRights>©2023 NeoLife. All rights reserved</FooterRights>
					</FooterLogoAndRights>
					<Links>
						<SocialLinks>
							<Icon href="https://www.linkedin.com/">
								<img src={linkedinIcon} alt="linkedin" />
							</Icon>
							<Icon iconSize={7} href="https://www.facebook.com/">
								<img src={fbIcon} alt="facebook" />
							</Icon>
							<Icon href="https://www.instagram.com/">
								<img src={instagramIcon} alt="instagram" />
							</Icon>
						</SocialLinks>
						<LegalLinks>
							<Link to="#">Privacy policy</Link>
							<Link to="#">Terms of use</Link>
						</LegalLinks>
					</Links>
				</FooterContent>
			</FooterBackground>
		</FooterContainer>
	);
};

export default Footer;
