import styled from "styled-components";

export const NavContainer = styled.div`
	display: flex;
`;

export const FooterNavContainer = styled(NavContainer)`
	align-items: space-between;
	justfiy-content: space-between;
`;

export const FooterLink = styled.a`
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	text-decoration: none;
	color: var(--text-color);
	margin: 2rem 25px;

	&:hover {
		text-decoration: underline;
	}
`;
