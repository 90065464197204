import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PackageSimple from "@/components/packages/PackageSimple/PackageSimple";
import dayjs from "@/components/configureDayjs";
import Accordion from "@/components/Accordion/Accordion";
import Card from "@/components/card/Card/Card";
// import dayjs from "@/components/configureDayjs";
import Modal from "@/components/Modal/Modal";
import FormAddResults from "@/components/forms/FormAddResults/FormAddResults";
import { setIconByPackageType } from "@/components/utils";
import { accordionContext } from "../../Accordion/Accordion";

import { ListPackages, Cards, CardsContainer, CardsActions } from "./styles";

const GptResponseTextComponent = (props) => {
	const { chatGptAnalysis } = props;
	const { gptResponse } = useContext(accordionContext);

	if (!(chatGptAnalysis || gptResponse)) return null;

	return (
		<div>
			<div className="package__table__label">
				<b>Анализ от ChatGPT</b>
			</div>
			<div
				style={{
					marginTop: "5px",
					marginBottom: "15px",
					whiteSpace: "pre-line",
				}}
			>
				{chatGptAnalysis || gptResponse}
			</div>
			{/* <textarea
				style={{ width: "100%", height: "300px", resize: "none" }}
				value={chatGptAnalysis || gptResponse}
				readOnly
			/> */}
		</div>
	);
};

const ListPackagesComponent = (props) => {
	const {
		userPackages,
		filters,
		isAdminView,
		userId,
		connectedUserPackages: reduxUserPackages,
	} = props;
	const packagesToUse = userPackages || reduxUserPackages;

	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		const body = document.querySelector("body");

		body.classList.toggle("modal-opened", isModalVisible);
	}, [isModalVisible]);

	const separateResultsByLabel = (results) => {
		const resultsNormal = [];
		const resultsObservation = [];
		const resultsRisk = [];

		results.forEach((result) => {
			if (
				!result.label &&
				result.value !== undefined &&
				result.referenceValues
			) {
				const value = result.value;
				const min = result.referenceValues.min;
				const max = result.referenceValues.max;
				const minObserve = min ? min * 0.8 : null;
				const maxObserve = max ? max * 1.2 : null;

				if ((min === null || value >= min) && (max === null || value <= max)) {
					result.label = "normal";
				} else if (
					(minObserve !== null &&
						min !== null &&
						value > minObserve &&
						value < min) ||
					(max !== null &&
						maxObserve !== null &&
						value > max &&
						value < maxObserve)
				) {
					result.label = "observation";
				} else {
					result.label = "risk";
				}
			}

			if (result.label === "normal") {
				resultsNormal.push(result);
			} else if (result.label === "observation") {
				resultsObservation.push(result);
			} else if (result.label === "risk") {
				resultsRisk.push(result);
			}
		});

		return {
			resultsNormal,
			resultsObservation,
			resultsRisk,
		};
	};

	const renderPackages = () => {
		if (packagesToUse == null) return;

		const sortedPackages = [...packagesToUse].sort((a, b) => {
			if (a.dateResults && b.dateResults) {
				return new Date(b.dateResults) - new Date(a.dateResults);
			}
			if (a.dateResults) {
				return -1;
			}
			if (b.dateResults) {
				return 1;
			}
			return new Date(b.date) - new Date(a.date);
		});

		return sortedPackages
			.filter((packageSingle) => {
				if (filters["name"] === "*" && filters["type"] === "*") {
					return true;
				}

				if (filters["name"] === "*") {
					return packageSingle.healthBundle.type === filters.type;
				}

				if (filters["type"] === "*") {
					return packageSingle.healthBundle.name === filters.name;
				}

				return (
					packageSingle.healthBundle.name === filters.name &&
					packageSingle.healthBundle.type === filters.type
				);
			})
			.map((userPackage) => {
				if (userPackage.dateResults === undefined) return null;
				return (
					<Accordion
						key={userPackage._id}
						id={userPackage._id}
						gptResponseBuffered={userPackage.chatGptAnalysis}
						showAnalyzeButtonInit={userPackage.chatGptAnalysis ? false : true}
					>
						<Accordion.Head>
							<PackageSimple
								icon={setIconByPackageType("common")}
								// title={userPackage.healthBundle.fullName}
								date={
									userPackage.date
										? userPackage.date
										: {
											day: dayjs(userPackage.dateResults).format("DD"),
											month: dayjs(userPackage.dateResults).format("MMMM"),
											year: dayjs(userPackage.dateResults).format("YYYY"),
										}
								}
								// dateResults={dayjs(userPackage.dateResults).format(
								// 	"DD.MM.YYYY"
								// )}
								laboratory={userPackage.laboratory}
								downloadLink={
									userPackage.attachment ? userPackage.attachment.url : false
								}
								id={userPackage._id}
								isAdminView={isAdminView}
							/>
						</Accordion.Head>

						<Accordion.Body>
							<Cards>
								{
									<GptResponseTextComponent
										chatGptAnalysis={userPackage.chatGptAnalysis}
									/>
								}
								<CardsContainer>
									<Card
										title="в норма"
										color="var(--cyan)"
										indicators={
											separateResultsByLabel(userPackage.results).resultsNormal
										}
										link={`/dashboard/user-packages/${userPackage._id}#normal`}
										linkState={{ isAdmin: true, userProfileId: userId }}
									/>

									<Card
										title="нуждаещи се от наблюдение"
										color="var(--yellow)"
										indicators={
											separateResultsByLabel(userPackage.results)
												.resultsObservation
										}
										link={`/dashboard/user-packages/${userPackage._id}#observation`}
										linkState={{ isAdmin: true, userProfileId: userId }}
									/>

									<Card
										title="в риск"
										color="var(--danger)"
										indicators={
											separateResultsByLabel(userPackage.results).resultsRisk
										}
										link={`/dashboard/user-packages/${userPackage._id}#risk`}
										linkState={{ isAdmin: true, userProfileId: userId }}
									/>
								</CardsContainer>
								<CardsActions>
									<Link
										to={{
											pathname: `/dashboard/user-packages/${userPackage._id}`,
											state: { isAdmin: true, userProfileId: userId },
										}}
										className="link-default"
									>
										Към подробен анализ
										<svg
											width="17"
											height="14"
											viewBox="0 0 17 14"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g
												stroke="currentColor"
												strokeWidth=".9"
												fill="none"
												fillRule="evenodd"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<path d="M1.346 7.132h14.601M10.16 1.346l5.787 5.785-5.787 5.786" />
											</g>
										</svg>
									</Link>
								</CardsActions>
							</Cards>
						</Accordion.Body>
					</Accordion>
				);
			});
	};

	return (
		<ListPackages>
			{/* <div className="btn__enter__results">
				<button className="btn" onClick={() => setIsModalVisible(true)}>
					Въведи резултати
				</button>
			</div> */}

			{renderPackages()}

			{isModalVisible && (
				<Modal onModalClose={() => setIsModalVisible(false)}>
					<FormAddResults onModalClose={() => setIsModalVisible(false)} />
				</Modal>
			)}
		</ListPackages>
	);
};

const mapStateToProps = (state) => ({
	connectedUserPackages: state.userPackages.list,
});

export default connect(mapStateToProps)(ListPackagesComponent);
