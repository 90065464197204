import styled from 'styled-components';

export const Field = styled.div`
	position: relative;
	display: block;

	.react-date-picker {
		display: flex;

		&__wrapper {
			border: 0;
			border-bottom: 1px solid var(--border-color);
		}

		&__clear-button {
			display: none;
		}

		&__calendar-button {
			display: none;
		}
	}

	.field__tooltip {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;

		&:hover {
			.field__tooltip__text {
				opacity: 1;
				pointer-events: all;
			}
		}

		&__icon {
			font-size: 0;
			cursor: help;
		}

		&__text {
			position: absolute;
			top: 50%;
			left: 100%;
			z-index: 5;
			transform: translateY(-50%);
			width: 240px;
			padding: 10px;
			border-radius: 8px;
			margin-left: 10px;
			background: #fff;
			box-shadow: 0 2px 24px rgba(0, 0, 0, .2);
			opacity: 0;
			pointer-events: none;
		}
	}
`
export const FieldControls = styled.div``
export const FieldError = styled.div`
	min-height: 18px;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	color: var(--danger);
`

export const Label = styled.label`
	display: block;
	margin-bottom: 7px;
	font-size: 14px;

	span {
		color: var(--danger);
	}
`

export const Input = styled.input`
	appearance: none;
	display: block;
	width: 100%;
	height: 26px;
	padding:0;
	border: 0;
	border-bottom: ${props => props.readOnly ? '0': '1px solid var(--border-color)'};
	border-bottom: 1px solid var(--border-color);
	border-radius: 0;
	outline: 0;
	background: transparent;
	box-shadow: none;
	font-size: 16px;
	transition: border-color var(--transition);

	&:focus {
		border-bottom-color: var(--body-text);
		box-shadow: none;
	}

	&::placeholder {
		color: inherit;
		opacity: .5;
	}
`
