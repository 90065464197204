import React from "react";
import { LoaderContainer } from "./styles";

const LoaderBig = () => {
	return (
		<>
			<LoaderContainer>
				<div className="dot"></div>
				<div className="dot"></div>
				<div className="dot"></div>
				<div className="dot"></div>
				<div className="dot"></div>
			</LoaderContainer>
		</>
	);
};

export default LoaderBig;
