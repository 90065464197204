import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Normalize } from "styled-normalize";
import GlobalStyles from "@/GlobalStyles";
import { store, persistor } from "@/components/configureStore";
import HighlightInit from "@/components/configureHighlight";

import App from "@/components/App";

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<HighlightInit />
			<Normalize />
			<GlobalStyles />
			<App />
		</PersistGate>
	</Provider>,
	document.querySelector("#root")
);
