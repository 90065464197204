import { AdditionalHabitsContainer } from "../styles";
import Field from "./Field";

const PersonalHealth = ({
	height,
	weight,
	circAbdomen,
	circNeck,
	diagnosedIllness,
	allergiesDetails,
	painDetails,
	medications,
	supplementsAndVitamins,
	operations,
	hospitalization,
}) => {
	return (
		<AdditionalHabitsContainer>
			<Field header={"1. Височина"} paragraph={height} />

			<Field header={"2. Тегло"} paragraph={weight} />

			<Field header={"3. Обиколка на врата"} paragraph={circNeck} />

			<Field header={"4. Обиколка на корема"} paragraph={circAbdomen} />

			<Field
				header={"5. Заболявания, с които сте били диагностицирани"}
				paragraph={diagnosedIllness}
			/>

			<Field header={"6. Детайли за алергии"} paragraph={allergiesDetails} />

			<Field header={"7. Детайли за болка"} paragraph={painDetails} />

			<Field header={"8. Лекарства, които приемате"} paragraph={medications} />

			<Field
				header={"9. Хранителни добавки и витамини"}
				paragraph={supplementsAndVitamins}
			/>

			<Field header={"10. Хоспитализации"} paragraph={hospitalization} />

			<Field header={"11. Операции"} paragraph={operations} />
		</AdditionalHabitsContainer>
	);
};

export default PersonalHealth;
