import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import logoImageSrc from "@/assets/images/logo-image.png";

import { Card } from "./styles";

const CardComponent = ({
	title,
	color,
	indicators,
	link = "/",
	linkState,
	onLinkClick,
}) => {
	const linkObj = linkState ? { pathname: link, state: linkState } : link;

	const renderCardContent = () => {
		if (indicators.length) {
			return (
				<Fragment>
					<div className="card__content">
						<h4 className="card__title">Показатели</h4>
						<h5 className="card__subtitle">{title}</h5>

						<ul className="card__list">
							{indicators.map((indicator, index) => {
								// show max 3 elements
								if (index > 2) return null;

								return (
									<li key={`indicator-simple-${indicator.biomarker._id}`}>
										<span className="card__list__text">
											{indicator.biomarker.label}
										</span>
										<span className="card__list__value">{indicator.value}</span>
									</li>
								);
							})}
						</ul>
					</div>

					<div className="card__actions">
						<Link to={linkObj} className="link-default" onClick={onLinkClick}>
							Виж показателите {title}
							<svg
								width="17"
								height="14"
								viewBox="0 0 17 14"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g
									stroke="currentColor"
									strokeWidth=".9"
									fill="none"
									fillRule="evenodd"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path d="M1.346 7.132h14.601M10.16 1.346l5.787 5.785-5.787 5.786" />
								</g>
							</svg>
						</Link>
					</div>
				</Fragment>
			);
		} else {
			return (
				<div className="card__image">
					<img width="40px" height="53px" src={logoImageSrc} alt="Лого" />

					<h4 className="card__title">Няма показатели {title}</h4>
				</div>
			);
		}
	};

	return (
		<Card className="card" color={color}>
			<div className="card__inner">{renderCardContent()}</div>
		</Card>
	);
};

export default CardComponent;
