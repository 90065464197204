import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
	SectionSeparator,
	SectionHeader,
	ContentContainer,
	SlideUp,
} from "./styles";
import arrow from "@/assets/images/arrow-down-slim.svg";
import useIntoView from "../../hooks/useIntoView";
import { media } from "../../GlobalStyles";

// Styled components here
const FaqSection = styled(ContentContainer)`
	padding: 90px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
	${media.md`
		padding: 20px 0;
		gap: 16px;
		margin-bottom: 20px;
	`}
`;

const FaqHeader = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;

	& > h6 {
		margin-bottom: 16px;
	}

	${media.md`
	margin-bottom: 0;
	margin-bottom: px;

	`}
`;

const FaqContainer = styled(SlideUp)`
	user-select: none;
	border-radius: 3px;
	background-color: #f2f7fb;
	padding: 15px 30px;
	width: 100%;
	cursor: pointer;
	${media.md`
		padding: 15px;
	`}
`;

const Question = styled.button`
	background: none;
	border: none;
	text-align: left;
	width: 100%;
	font-size: 18px;
	font-weight: 600;
	color: #333;
	cursor: pointer;
	padding: 0;
	padding-right: 80px;
	position: relative;

	&:after {
		position: absolute;
		right: 20px;
		top: 0%;
		content: "";
		background: url(${arrow}) no-repeat center;
		width: 50px;
		height: 100%;
		float: right;
		transform: ${(props) =>
		props.expanded ? "rotate(180deg)" : "rotate(0deg)"};
		transition: transform 0.3s ease;
	}
	${media.md`
	  	font-size: 18px;
  		letter-spacing: 0;
  		line-height: 20px;
		&:after{
			scale: 0.7;
			height: 20%;
			right: 0;
		}
		padding-right: 40px;

	`}
`;

const Answer = styled.div`
	transition: all 0.2s ease-in-out;
	max-height: ${(props) => (props.expanded ? "300px" : "0")};
	overflow: hidden;
	line-height: 28px;
	font-size: 16px;
	opacity: 0.75;
	${media.md`
		line-height: 20px;
	`}
`;

// FAQ Component
const Faq = ({ data }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);
	const toggleExpansion = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	return (
		<FaqSection id="faq" ref={observeEl}>
			<FaqHeader>
				<SectionSeparator>How can we help?</SectionSeparator>
				<SectionHeader>Frequently Asked Questions</SectionHeader>
			</FaqHeader>
			{data.map((item, index) => (
				<FaqContainer
					key={index}
					onClick={() => toggleExpansion(index)}
					expanded={expandedIndex === index}
					seen={seen}
					delay={index / 12}
				>
					<Question expanded={expandedIndex === index}>
						{item.question}
					</Question>
					<Answer expanded={expandedIndex === index}>
						<p>{item.answer}</p>
					</Answer>
				</FaqContainer>
			))}
		</FaqSection>
	);
};

export default Faq;
