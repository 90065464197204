import { H } from "highlight.run";

function HighlightInit() {
	if (
		!process.env.REACT_APP_HIGHLIGHT_ID ||
		!process.env.REACT_APP_TRACING_ORIGINS
	)
		return null;
	const tracingOrigins =
		process.env.REACT_APP_TRACING_ORIGINS === "true"
			? true
			: process.env.REACT_APP_TRACING_ORIGINS.split(", ");

	// console.log(tracingOrigins);

	H.init(process.env.REACT_APP_HIGHLIGHT_ID, {
		tracingOrigins: tracingOrigins,
		networkRecording: {
			enabled: true,
			recordHeadersAndBody: true,
			urlBlocklist: [
				// insert urls you don't want to record here
			],
		},
	});

	return null;
}

export default HighlightInit;
