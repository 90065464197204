import styled from 'styled-components';

export const Filters = styled.div`
	margin-bottom: 16px;

	.filters__container {
		display: flex;
		margin: 0 -16px;

		@media (max-width: 768px) {
			flex-direction: column;
			gap: 10px;
		}
	}

	.icon-text,
	.filter {
		padding: 0 16px;
	}

	@media (max-width: 768px) {
		.filters__container {
			align-items: flex-end;
		}
	}
`;
export const Filter = styled.div`
	display: flex;
	align-items: center;

	.filter__label {
		font-size: 14px;
	}

	.select {
		margin-left: 10px;

		select {
			border-bottom-color: currentColor;
		}
	}
`;
