import React from "react";
import iconClockSrc from "@/assets/images/ico-clock.svg";
import napSrc from "@/assets/images/ico-nap.svg";

import { SleepHabitsContainer } from "../styles";
import { translateValues } from "./translation";

const SleepHabits = ({ sleepHabits }) => {
	const translated = translateValues(sleepHabits);
	return (
		<SleepHabitsContainer>
			<h5 className="sleep__issues__title">Проблеми със съня</h5>

			<div className="sleep__issues">
				<p className="sleep__issue__text">
					Проблеми със заспиването - {translated.sleepDifficulty}
				</p>
				<p className="sleep__issue__text">
					Ползване на устройства с екрани преди заспиване -{" "}
					{translated.deviceUsageBeforeBed}
				</p>
				<p className="sleep__issue__text">
					Апнея по време на сън - {translated.nighttimeBreathingDifficulty}
				</p>
				<p className="sleep__issue__text">
					Оценка на съня - {translated.sleepQuality}
				</p>
				<p className="sleep__issue__text">
					Прием на лекарства за заспиване - {translated.sleepAidsUsage}
				</p>
				<p className="sleep__issue__text">
					Следване на график за сън - {translated.consistentSleepSchedule}
				</p>
			</div>

			<h5 className="sleep__issues__title">Дължина на съня</h5>

			<div className="sleep__duration">
				<div className="sleep__hours">
					<img src={iconClockSrc} alt="Часове сън" />
					<span className="sleep__hours__count">
						{translated.hoursOfSleep} на нощ
					</span>
				</div>
				<div className="naps__count">
					<img src={napSrc} alt="Дрямки" />
					<span className="naps__count__value">
						Дрямки на ден -{translated.daytimeNappingFrequency}
					</span>
				</div>
			</div>
		</SleepHabitsContainer>
	);
};

export default SleepHabits;
