import React from "react";
import { AnalysisItemContainer } from "../styles";

const AnalysisItem = ({ text, date }) => {
	return (
		<AnalysisItemContainer>
			<p className="analysis__text">{text}</p>
			<span>{date}</span>
		</AnalysisItemContainer>
	);
};

export default AnalysisItem;
