import styled from "styled-components";
export const QuestionnaireFilledContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 80vw;
	height: 80vh;
	align-items: center;
	margin: 0 auto;
`;

export const QuestionnaireIntroContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 10;
	background-color: white;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

	${(props) =>
		props.active &&
		`
        opacity: 0;
        visibility: hidden; 
    `}
`;

export const IntroHeading = styled.h2`
	margin-bottom: 2rem;
`;
