import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { api } from '@/apis';
import history from '@/components/history';
import { fetchUserPackages } from '@/ducks/userPackages';

import FormInput from '@/components/FormElements/FormInput';

import {
	FormContainer,
} from './styles';

const FormAddResults = props => {
	const { onModalClose, fetchUserPackages, user } = props;
	const [userPackages, setUserPackages] = useState({});
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [packageId, setPackageId] = useState('');
	const [errors, setErrors] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		api.get('/user-health-bundles?accessParent')
		.then(response => {
			const filteredData = response.data.filter(obj => obj.results.length === 0);
			setUserPackages(filteredData);
		}).catch(error => {
			setErrors(error.response.data.message)
		});
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (userPackages.length) {
			setPackageId(userPackages[0]._id);
		}
	// eslint-disable-next-line
	}, [userPackages]);

	const onSubmit = async (event) => {
		event.preventDefault();

		setIsLoading(true);
		setErrors(null)

		await api.post(`user-health-bundles/${packageId}/results`, {
			username,
			password
		})
		.then(r => {
			setIsSuccess(true);
			fetchUserPackages();
			setIsLoading(false);
		})
		.catch(e => {
			setIsLoading(false);
			setErrors(e.response.data.message);
		});
	}

	const renderPackageOptions = () => {
		let options = [];

		userPackages.forEach(pack => {
			const option = {
				value: pack._id,
				label: `${pack.laboratory.fullName}` //Was ${pack.healthBundle.fullName} - ${pack.laboratory.fullName} (${pack.laboratory.city})
			}
			options.push(option)
		});

		return options;
	}

	const redirectToPackagesPage = () => {
		onModalClose();
		history.push('/dashboard/packages');
	}

	const handleFormActionsRender = () => {
		if (userPackages.length === 0 && !isSuccess) {
			return (
				<Fragment>
					<p>
						Все още нямате закупен пакет. {user.role === 'patient' && <button type="button" onClick={redirectToPackagesPage} className="link"><strong>Закупи пакет</strong></button>}
					</p>
				</Fragment>
			)
		}

		if (isSuccess) {
			return (
				<Fragment>
					<button className="btn" type="button" onClick={onModalClose}>
						Готово
					</button>
				</Fragment>
			);
		}

		if (userPackages.length > 0) {
			return (
				<Fragment>
					<button className="btn" type="submit">
						Изпрати
					</button>

					<button className="btn btn--outline" type="button" onClick={onModalClose}>
						Отмени
					</button>
				</Fragment>
			);
		}
	}

	return (
		<FormContainer className="form-results">
			<div className="form-results__head">
				<h3 className="form-results__title">Резултати от изследвания</h3>

				<div className="form-results__entry">
					<p>Въведи потребителско име и парола от талона, получен от лабораторията за проследяване на онлайн резултати.</p>
				</div>
			</div>

			<div className="form-results__body">
				<p className={isLoading ? 'loading__msg' : 'loading__msg__hidden'}>Зареждане...</p>
				<form onSubmit={onSubmit} className={isLoading ? 'loading' : ''}>
					{ userPackages.length > 0 &&
						<div className="form-results__row">
							<FormInput
								label="Избери лаборатория" // Was "Избери пакет"
								name="select-lab" // Was "Избери пакет"
								type="text"
								placeholder="Избери лаборатория" // Was "Избери пакет"
								required
								autocomplete="new-password"
								options={renderPackageOptions()}
								onChange={e => setPackageId(e.target.value)}
							/>
						</div>
					}

					<div className="form-results__row">
						<FormInput
							label="ID (от талончето)"
							name="results_id"
							type="text"
							placeholder="1-108989"
							value={username}
							required
							autocomplete="new-password"
							onChange={e => {setUsername(e.target.value)}}
						/>
					</div>

					<div className="form-results__row">
						<FormInput
							label="Парола (от талончето)"
							name="results_password"
							type="password"
							placeholder="Въведи парола"
							value={password}
							required
							autocomplete="new-password"
							onChange={e => {setPassword(e.target.value)}}
						/>
					</div>

					{ isSuccess &&
						<div className="form-results__success">
							<p>Успешно добавихте резултатите си!</p>
						</div>
					}

					{errors &&
						<div className="form-results__error">
							<p>{errors}</p>
						</div>
					}

					<div className="form-results__actions">
						{handleFormActionsRender()}
					</div>
				</form>
			</div>
		</FormContainer>
	);
}

export default connect(
	state => ({
		user: state.user,
	}),
	{
		fetchUserPackages
	}
)(FormAddResults);
