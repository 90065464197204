import React, { useState, useEffect } from "react";
import Modal from '@/components/Modal/Modal';
import DeleteMember from '@/components/family/DeleteMember/DeleteMember';
import EditMember from '@/components/family/EditMember/EditMember';
import LoginMember from '@/components/family/LoginMember/LoginMember';

import editIcon from "@/assets/images/ico-edit.svg";
import deleteIcon from "@/assets/images/ico-delete.svg";
import loginIcon from "@/assets/images/ico-login.svg";

import {
	ProfileContainer
} from './styles';  

const FamilyMember = ({ profile }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	const { _id, firstName, lastName, medicalProfile } = profile;
	const gender = medicalProfile.gender === "male" ? 'Мъж' : 'Жена';

	const date = new Date(medicalProfile.birthDate)
	const day = date.getDate().toString().padStart(2, "0");
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const year = date.getFullYear();
	const birthDate = `${day}.${month}.${year}`;

	function openModal(content) {
		setModalContent(content);
		setIsModalVisible(true);
	  }
	const closeModal = () => {
		setIsModalVisible(false);
	}

	useEffect(() => {
		const body = document.querySelector('body');

		body.classList.toggle('modal-opened', isModalVisible);
	}, [isModalVisible]);
	
	return (
		<ProfileContainer>
			<div className="profile__col profile__col--title">
				<p className="profile__names">
					{firstName} {lastName}
				</p>
			</div>

			<div className="profile__col profile__col--age desktop__only">
				<p className="profile__age">{birthDate} г.</p>
			</div>

			<div className="profile__col profile__col--gender  desktop__only">
				<p className="profile__gender">{gender}</p>
			</div>

			<div className="profile__col profile__col--actions">
				<button className="btn profile-btn profile-btn__login" type="button" onClick={() => openModal('login')}>
					<img src={loginIcon} alt="Иконка за влизане в профил" />
				</button>
				<button className="btn profile-btn profile-btn__edit" type="button" onClick={() => openModal('edit')}>
					<img src={editIcon} alt="Иконка за промяна" />
				</button>
				<button className="btn profile-btn profile-btn__delete" type="button" onClick={() => openModal('delete')}>
					<img src={deleteIcon} alt="Иконка за изтриване" />
				</button>
			</div>

			{isModalVisible ? 
				modalContent === 'delete' ? (
					<Modal onModalClose={() => closeModal()} color="var(--danger)">
            			<DeleteMember id={_id} closeModal={closeModal} firstName={firstName} lastName={lastName} />
					</Modal>
          		) : (
					modalContent === 'edit' ?
						<Modal onModalClose={() => closeModal()} color="var(--yellow)">
            				<EditMember id={_id} closeModal={closeModal} profile={profile} />
						</Modal>
					:
						<Modal onModalClose={() => closeModal()} color="var(--blue)">
            				<LoginMember profile={profile} closeModal={() => closeModal()}/>
						</Modal>
          			)
				: <></>
			}
		</ProfileContainer>
	);
};

export default FamilyMember;
