import styled from 'styled-components';

export const Card = styled.div`
	width: 33.3333%;
	padding: 0 12px;

	@media (max-width: 768px) {
		width: 100%;
	}

	.card__inner {
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 10px 17px;
		border-radius: 4px;
		background: #fff;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 6px;
			height: 100%;
			background: ${props => props.color};
		}
	}

	.card__content {
		flex-grow: 1;
	}

	.card__image {
		margin-bottom: 5px;
		text-align: center;
	}

	.card__title {
		margin: 0 0 7px;
	}

	.card__subtitle {
		margin: 0 0 5px;
		color: ${props => props.color};
		font-weight: 700;
	}

	.card__list {
		list-style-type: none;
		padding: 0;
		margin: 0 0 20px;

		li {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
			font-size: 14px;
		}
	}

	.card__list__value {
		font-weight: 700;
		color: ${props => props.color};
	}

	.card__actions {
		text-align: center;
	}
`;
