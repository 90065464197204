import React, { useEffect } from "react";
import {
	Switch,
	Route,
	Link,
	useRouteMatch,
	useHistory,
} from "react-router-dom";
import { connect } from "react-redux";

import LoginForm from "@/components/auth/LoginForm";
import RegisterForm from "@/components/auth/RegisterForm";
import ActivateUserForm from "@/components/auth/ActivateUserForm";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm";
import RequestResetPasswordForm from "@/components/auth/RequestResetPasswordForm";
import dayjs from "@/components/configureDayjs";

import logoImageSrc from "@/assets/images/logo-image.png";
import logoSrc from "@/assets/images/logo.png";
import {
	Container,
	Aside,
	Content,
	ContentHeader,
	ContentBody,
} from "./styles";

const AuthPage = (isSignedIn, expTimestamp) => {
	let { path } = useRouteMatch();
	let history = useHistory();

	const isTimestampValid = dayjs().diff(dayjs(isSignedIn.expTimestamp)) < 0;

	useEffect(() => {
		if (isSignedIn.isSignedIn && isTimestampValid) {
			history.push("/dashboard");
		}
	}, [isSignedIn, history, isTimestampValid]);

	return (
		<Container>
			<Aside>
				<img src={logoImageSrc} alt="Logo" />
			</Aside>

			<Content>
				<ContentHeader>
					<Link to="/">
						<img src={logoSrc} alt="NeoLife лого" />
					</Link>
				</ContentHeader>

				<ContentBody>
					<Switch>
						<Route path={path} exact component={LoginForm} />
						<Route path={`${path}/register`} component={RegisterForm} />
						<Route
							path={`${path}/activate-user`}
							component={ActivateUserForm}
						/>
						<Route
							path={`${path}/reset-password`}
							component={ResetPasswordForm}
						/>
						<Route
							path={`${path}/request-reset-password`}
							component={RequestResetPasswordForm}
						/>
					</Switch>
				</ContentBody>
			</Content>
		</Container>
	);
};

export default connect((state) => ({
	isSignedIn: state.auth.isSignedIn,
	expTimestamp: state.auth.expTimestamp,
}))(AuthPage);
