const parseData = (data) => {
	const defaultDisorders = {
		cardiovascular: false,
		pulmonary: false,
		gastrointestinal: false,
		liverkidney: false,
		psychiatric: false,
		cancer: false,
		autoimmune: false,
		physical: false,
		genetic: false,
		operations: false,
		other: "",
	};

	const mapDisorders = (disorderList, comment) => {
		const disorders = { ...defaultDisorders };
		disorderList.forEach((disorder) => {
			if (disorders.hasOwnProperty(disorder) && disorder !== "other")
				disorders[disorder] = true;
		});
		if (comment) {
			disorders.other = comment;
		}
		return disorders;
	};

	const mapOtherForMultiChoice = (option, commentPart) => {
		console.log(option, commentPart);
		return option?.map
			? option.map((item) => (item === "other" ? commentPart : item))
			: option === "other"
			? commentPart
			: option;
	};

	return {
		goal: mapOtherForMultiChoice(data?.goal, data?.["goal-Comment"]), //page1 Goal
		//#region page2 Demographics
		gender: (data?.gender ? "female" : "male") ?? null,
		birthDate: new Date(data?.birthDate) ?? null,
		height: data?.height ?? null,
		weight: data?.weight ?? null,
		circNeck: data?.circNeck ?? null,
		circAbdomen: data?.circAbdomen ?? null,
		//#endregion page2

		//#region page3 Current health state
		diagnosedIllness: data?.diagnosedIllness ?? null,
		allergiesDetails: data?.allergiesDetails ?? null,
		painDetails: data?.painDetails ?? null,
		medications: data?.medications ?? null,
		supplementsAndVitamins: data?.supplementsAndVitamins ?? null,
		//#endregion page3

		//#region page4 Past health state
		hospitalization: data?.hospitalization ?? null,
		operations: data?.operations ?? null,
		//#endregion page4

		//#region page5 Family history
		family: {
			mother: {
				alive: data?.family?.mother?.alive ?? null,
				age: data?.family?.mother?.age ?? null,
				disorders: mapDisorders(
					data?.family?.mother?.disorders ?? [],
					data?.family?.mother?.["disorders-Comment"] ?? ""
				),
			},
			father: {
				alive: data?.family?.father?.alive ?? null,
				age: data?.family?.father?.age ?? null,
				disorders: mapDisorders(
					data?.family?.father?.disorders ?? [],
					data?.family?.father?.["disorders-Comment"] ?? ""
				),
			},
			maternalGrandmother: {
				disorders: mapDisorders(
					data?.family?.maternalGrandmother?.disorders ?? [],
					data?.family?.maternalGrandmother?.["disorders-Comment"] ?? ""
				),
			},
			maternalGrandfather: {
				disorders: mapDisorders(
					data?.family?.maternalGrandfather?.disorders ?? [],
					data?.family?.maternalGrandfather?.["disorders-Comment"] ?? ""
				),
			},
			paternalGrandmother: {
				disorders: mapDisorders(
					data?.family?.paternalGrandmother?.disorders ?? [],
					data?.family?.paternalGrandmother?.["disorders-Comment"] ?? ""
				),
			},
			paternalGrandfather: {
				disorders: mapDisorders(
					data?.family?.paternalGrandfather?.disorders ?? [],
					data?.family?.paternalGrandfather?.["disorders-Comment"] ?? ""
				),
			},
		},

		//#endregion page5

		//#region page6 Physical health
		physicalHealth: {
			activityLevel: data?.physicalHealth?.activityLevel ?? null,
			exercise: {
				types: mapOtherForMultiChoice(
					data?.physicalHealth?.exercise?.types,
					data.physicalHealth?.exercise?.["types-Comment"]
				),
				time: data?.physicalHealth?.exercise?.time ?? null,
				intensityManagement:
					data?.physicalHealth?.exercise?.intensityManagement ?? null,
				considerationOfLongevitySpecific: mapOtherForMultiChoice(
					data?.physicalHealth?.exercise?.considerationOfLongevitySpecific,
					data?.physicalHealth?.exercise?.[
						"considerationOfLongevitySpecific-Comment"
					]
				),
				routineEnjoymentSustainability:
					data?.physicalHealth?.exercise?.routineEnjoymentSustainability ??
					null,
			},
			resistanceTrainingFrequency:
				data?.physicalHealth?.resistanceTrainingFrequency ?? null,
			activitiesCardiovascularHealth: mapOtherForMultiChoice(
				data?.physicalHealth?.activitiesCardiovascularHealth,
				data?.physicalHealth?.["activitiesCardiovascularHealth-Comment"]
			),
			recoveryAndRestApproach: mapOtherForMultiChoice(
				data?.physicalHealth?.recoveryAndRestApproach,
				data?.physicalHealth?.["recoveryAndRestApproach-Comment"]
			),
			trackingFitnessDevices:
				data?.physicalHealth?.trackingFitnessDevices ?? null,
			roleOfPhysicalActivityInHealthLongevity:
				data?.physicalHealth?.roleOfPhysicalActivityInHealthLongevity ?? null,
		},
		//#endregion page6

		//#region page7 Nutrition
		nutrition: {
			approach: mapOtherForMultiChoice(
				data?.nutrition?.approach,
				data?.nutrition?.["approach-Comment"]
			),
			primaryProteinSource: mapOtherForMultiChoice(
				data?.nutrition?.primaryProteinSource,
				data?.nutrition?.["primaryProteinSource-Comment"]
			),
			processedRefinedFoodsConsumption:
				data?.nutrition?.processedRefinedFoodsConsumption ?? null,
			typicalDietaryFatsSource: mapOtherForMultiChoice(
				data?.nutrition?.typicalDietaryFatsSource,
				data?.nutrition?.["typicalDietaryFatsSource-Comment"]
			),
			foodAllergies: mapOtherForMultiChoice(
				data?.nutrition?.foodAllergies,
				data?.nutrition?.["foodAllergies-Comment"]
			),
			numberOfFeedingsPerDay: data?.nutrition?.numberOfFeedingsPerDay ?? null,
			portionsOfFruitPerWeek: data?.nutrition?.portionsOfFruitPerWeek ?? null,
			portionsOfVegetablesPerWeek:
				data?.nutrition?.portionsOfVegetablesPerWeek ?? null,
			portionsOfRedMeatPerWeek:
				data?.nutrition?.portionsOfRedMeatPerWeek ?? null,
			portionsOfFishPerWeek: data?.nutrition?.portionsOfFishPerWeek ?? null,
			portionsOfSnacksAndSweetsPerWeek:
				data?.nutrition?.portionsOfSnacksAndSweetsPerWeek ?? null,
			caffeinatedDrinksPerDay: data?.nutrition?.caffeinatedDrinksPerDay ?? null,
			herbalTeasPerDay: data?.nutrition?.herbalTeasPerDay ?? null,
		},
		//#endregion page7

		//#region page8 Cognitive health
		cognitiveHealth: {
			readSomethingAndNeedToReadAgain:
				data?.cognitiveHealth?.readSomethingAndNeedToReadAgain ?? null,
			confuseRightAndLeftWhenGivingDirections:
				data?.cognitiveHealth?.confuseRightAndLeftWhenGivingDirections ?? null,
			failToListenToPeoplesNames:
				data?.cognitiveHealth?.failToListenToPeoplesNames ?? null,
			forgetWhichWayToTurnOnRoad:
				data?.cognitiveHealth?.forgetWhichWayToTurnOnRoad ?? null,
			forgetAppointments: data?.cognitiveHealth?.forgetAppointments ?? null,
			forgetOnTipOfYourTongue:
				data?.cognitiveHealth?.forgetOnTipOfYourTongue ?? null,
			forgetWhatYouCameToTheShopsToBuy:
				data?.cognitiveHealth?.forgetWhatYouCameToTheShopsToBuy ?? null,
			cantThinkOfAnythingToSay:
				data?.cognitiveHealth?.cantThinkOfAnythingToSay ?? null,
			startDoingOneThingAndGetDistracted:
				data?.cognitiveHealth?.startDoingOneThingAndGetDistracted ?? null,
		},
		//#endregion page8

		//#region page9 Emotional Health
		emotionalHealth: {
			stressManagementStrategies: mapOtherForMultiChoice(
				data?.emotionalHealth?.stressManagementStrategies,
				data?.emotionalHealth?.["stressManagementStrategies-Comment"]
			),
			handlingChallengingSituations:
				data?.emotionalHealth?.handlingChallengingSituations ?? null,
			prioritizingTimeForJoyRelaxation:
				data?.emotionalHealth?.prioritizingTimeForJoyRelaxation ?? null,
			frequencyOfConnectingForEmotionalSupport:
				data?.emotionalHealth?.frequencyOfConnectingForEmotionalSupport ?? null,
			selfTalkAndSelfEsteemStrategies: mapOtherForMultiChoice(
				data?.emotionalHealth?.selfTalkAndSelfEsteemStrategies,
				data?.emotionalHealth?.["selfTalkAndSelfEsteemStrategies-Comment"]
			),
			senseOfPurposeStrategies: mapOtherForMultiChoice(
				data?.emotionalHealth?.senseOfPurposeStrategies,
				data?.emotionalHealth?.["senseOfPurposeStrategies-Comment"]
			),
			satisfactionWithWorkLifeBalance:
				data?.emotionalHealth?.satisfactionWithWorkLifeBalance ?? null,
		},
		//#endregion page9

		//#region page 10 Sleep habits
		sleepHabits: {
			hoursOfSleep: data?.sleepHabits?.hoursOfSleep ?? null,
			sleepQuality: data?.sleepHabits?.sleepQuality ?? null,
			consistentSleepSchedule:
				data?.sleepHabits?.consistentSleepSchedule ?? null,
			sleepDifficulty: data?.sleepHabits?.sleepDifficulty ?? null,
			deviceUsageBeforeBed: data?.sleepHabits?.deviceUsageBeforeBed ?? null,
			daytimeNappingFrequency:
				data?.sleepHabits?.daytimeNappingFrequency ?? null,
			nighttimeBreathingDifficulty:
				data?.sleepHabits?.nighttimeBreathingDifficulty ?? null,
			sleepAidsUsage: data?.sleepHabits?.sleepAidsUsage ?? null,
		},
		//#endregion page10

		//#region page11 Additional habits
		additionalHabits: {
			smokingHistory: data?.additionalHabits?.smokingHistory ?? null,
			secondHandSmokeExposure:
				data?.additionalHabits?.secondHandSmokeExposure ?? null,
			alcoholConsumption: data?.additionalHabits?.alcoholConsumption ?? null,
			recreationalDrugUse: data?.additionalHabits?.recreationalDrugUse ?? null,
		},
		//#endregion page11
	};
};

export default parseData;
