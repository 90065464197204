import styled from "styled-components";
import {
	ContentContainer,
	DotsBackground,
	SectionHeader,
	SectionParagraph,
	SectionSeparator,
	SlideLeft,
	SlideRight,
} from "./styles";
import { useState } from "react";
import check from "@/assets/images/checkmark.svg";
import heartRateGraph from "@/assets/images/heart-rate-graph.svg";
import stepsGraph from "@/assets/images/steps-graph.svg";
import cortisolGraph from "@/assets/images/cortisol-graph.svg";
import { useRef } from "react";
import useIntoView from "../../hooks/useIntoView";
import arrow from "@/assets/images/arrow-down-slim.svg";
import { media } from "../../GlobalStyles";

const graphs = [stepsGraph, heartRateGraph, cortisolGraph];

const StrategySection = styled(ContentContainer)`
	display: flex;
	justify-content: flex-start;
	gap: 60px;
	margin: 130px auto;
	${media.md`
		margin: 50px auto;
		flex-direction: column-reverse;
		gap: 0;
	`}
`;

const Profile = styled.div`
	display: flex;
	z-index: 1;
	flex-direction: column;
`;

const DropDown = styled(SlideRight)`
	margin: 14px 0;
`;

const DropDownHeader = styled.h2`
	user-select: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 29px;
	cursor: pointer;
	text-wrap: balance;

	&::before {
		content: "";
		background: url(${check});
		height: 13px;
		width: 24px;
		margin-right: 14px;
	}

	&::after {
		content: "";
		margin: auto;
		margin-right: 0;
		background: url(${arrow}) no-repeat center;
		width: 40px;
		height: 15px;
		transform: ${({ expanded }) =>
		expanded ? "rotate(180deg)" : "rotate(0deg)"};
		transition: transform 0.2s ease;
	}

	& + div {
		max-height: ${(props) => (props.expanded ? "150px" : "0")};
	}

	& + div > p {
		margin: 15px 0 0 35px;
		width: 65%;
	}

	${media.md`
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;

		& > span {
			width: 75%;
		}

		&::after {
			scale: 0.8;
		}
	`}
`;

const Holder = styled(SlideRight)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 600px;
	${media.md`
		height: 520px;
	`}
`;

const GraphHolder = styled(SlideLeft)`
	z-index: 1;
	position: relative;
	left: -113.1px;
	background: #f2f7fb;
	& > img {
		position: relative;
	}

	& > img:first-child {
		margin: 60px 0 0 100px;
	}
	& > img:nth-child(2) {
		top: -30px;
		margin: 0 0 55px 165px;
	}
	& > img:last-child {
		position: absolute;
		bottom: 210px;
		left: 300px;
	}
	${media.md`
		left: 0;
		width: 75%;
		& > img {
			width: 80%;
		}
		& > img:first-child {
			margin: 50px 0 0 20px;
		}
		& > img:nth-child(2) {
			top: -20px;
			margin: 0 0 55px 55px;
		}
		& > img:last-child {
			position: absolute;
			bottom: 143px;
			left: 115px;
		}
	`}
`;

const Dots = styled(DotsBackground)`
	${media.md`
		position: absolute;
		left: 43%;
		margin-top: 825px;
		scale: 0.6;
	`}
`;
const Graph = styled(SlideLeft)`
	width: 70%;
`;

const Strategy = ({ data }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);
	const toggleExpansion = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	return (
		<StrategySection id="strategy" ref={observeEl}>
			<GraphHolder seen={seen}>
				{graphs.map((graph, index) => (
					<Graph as="img" seen={seen} key={index} src={graph} />
				))}
			</GraphHolder>
			<Holder seen={seen}>
				<SectionSeparator>The Product</SectionSeparator>
				<SectionHeader>Premium longevity package</SectionHeader>
				<Profile>
					{data?.map &&
						data.map(({ header, description }, index) => (
							<DropDown seen={seen} delay={index / 10} key={index}>
								<DropDownHeader
									expanded={expandedIndex === index}
									onClick={() => toggleExpansion(index)}
								>
									<span>{header}</span>
								</DropDownHeader>
								<SectionParagraph as="div" expanded={expandedIndex === index}>
									<p>{description}</p>
								</SectionParagraph>
							</DropDown>
						))}
				</Profile>
				<Dots left={37.5} top={550} />
			</Holder>
		</StrategySection>
	);
};

export default Strategy;
