import React from "react";

import { Container } from "../styles";

const FamilyHealthInfo = ({ familyData }) => {
	const translatedDisorders = {
		cardiovascular: "Сърдечно-съдова",
		pulmonary: "Белодробна",
		gastrointestinal: "Храносмилателна",
		liverkidney: "Чернодробна/бъбречна",
		psychiatric: "Психична",
		cancer: "Рак",
		autoimmune: "Аутоимунна",
		physical: "Физическа",
		genetic: "Генетична",
		operations: "Операции",
		other: "Друго",
	};

	const translatedNames = {
		mother: "Майка",
		father: "Баща",
		maternalGrandmother: "Баба (от майчина страна)",
		maternalGrandfather: "Дядо (от майчина страна)",
		paternalGrandmother: "Баба (от бащина страна)",
		paternalGrandfather: "Дядо (от бащина страна)",
	};

	return (
		<Container>
			<table>
				<thead>
					<tr>
						<th>Член на семейството</th>
						{Object.values(translatedDisorders).map((disorder) => (
							<th key={disorder}>
								{disorder === "Чернодробна/бъбречна"
									? "Чернодробна/ бъбречна"
									: disorder === "Храносмилателна"
									? "Храносми лателна"
									: disorder}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{Object.entries(familyData).map(([memberName, memberData]) => (
						<tr key={memberName}>
							<td>{translatedNames[memberName] || memberName}</td>

							{Object.values(memberData.disorders).map((disorder, index) => (
								<td key={index}>{disorder ? <b>Да</b> : "Не"}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</Container>
	);
};

export default FamilyHealthInfo;
