const strategyData = [
	{
		header: `1:1 longevity specialist consultations`,

		description: `Our specialists will help you set your current condition & goals, explain in detail the genetic and blood data that matters to you, and adjust your protocols based on your progress.`,
	},

	{
		header: `Syncing all the data`,

		description: `We analyze and structure 50+ blood biomarkers, your unique genetic profile, subjective assessment and wearables data.`,
	},

	{
		header: `Easy to understand analysis & advice`,

		description: `We translate this complex heavy information into step-by-step personalized guides into a tailor-made health plan in five health domains - nutritional biochemistry, exercise physiology, sleep, stress and supplementation.`,
	},

	{
		header: `Personalized dashboard`,

		description: `Every complex system has a dashboard, your body should have one as well. Track the metrics that matter most to you, so you can achieve your health goals.`,
	},

	{
		header: `State-of-the-art AI analytics`,

		description: `Our proprietary algorithms generate insights that no single person can because of the overwhelming amount of data.`,
	},

	{
		header: `Own and control your data`,

		description: `All the personal health information is anonymized and stored in a security pod that you control. Download, delete or give access to other tools and algorithms - the decision is yours.`,
	},
];

export default strategyData;
