import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import Modal from '@/components/Modal/Modal';
import AddMember from '@/components/family/AddMember/AddMember';
import FamilyMember from '@/components/family/FamilyMember/FamilyMember';
import { fetchFamily } from '@/ducks/family';

import {
	ProfileFamily,
} from './styles';


const FamilyProfile = ({ family, fetchFamily }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchFamily();
    }, [fetchFamily]);

    useEffect(() => {
		const body = document.querySelector('body');

		body.classList.toggle('modal-opened', isModalVisible);
	}, [isModalVisible]);

    const openModal = () => {
		setIsModalVisible(true);
	}
    const closeModal = () => {
        setIsModalVisible(false);
    };

	return (
		<ProfileFamily>
            {family && family.length > 0 &&
			<div className="profile__table__row">
				<div className="profile__table__col">
					<div className="profile__table__label">Имена</div>
                </div>
				<div className="profile__table__col desktop__only">
					<div className="profile__table__label">Дата на раждане</div>
                </div>
				<div className="profile__table__col desktop__only">
					<div className="profile__table__label">Пол</div>
				</div>
				<div className="profile__table__col">
					<div className="profile__table__label">Влез/Промени/Изтрий</div>
				</div>
			</div>}


            {family && family.length > 0 ? family.map(profile => (
                <FamilyMember key={profile._id} profile={profile} />
            )) : <p>Няма налични профили</p>}

            <div className="profile__add">
                <button className="btn btn--add" type="button" onClick={openModal}>Добави профил</button>
            </div>

            {isModalVisible && (
				<Modal onModalClose={() => closeModal()} color='var(--blue)'>
					<AddMember closeModal={closeModal} />
				</Modal>
			)}
		</ProfileFamily>
	);
};



export default connect(
	(state) => ({
		family: state.family.list,
	}),
	{
		fetchFamily,
	}
)(FamilyProfile);