import styled from "styled-components";

export const Form = styled.form`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .pin__container {
        display: flex;
        justify-content: center;
      }

    .pin__desc{
        text-align: center;
    }
      
    .pin__input {
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        margin-right: 1rem;
        text-align: center;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: none;
        outline: none;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
      
    .pin__input:last-child {
        margin-right: 0;
    }
`;

export const FormMessage = styled.div`
	min-height: 32px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
	color: ${props => props.color || 'var(--text-color)'};
`;

export const PinDiv = styled.div`
	display: flex;
`;

export const PackageBought = styled.section`
margin-bottom: 22px;

.package__head__inner {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
}

.package__title {
    display: flex;
    align-items: center;
    margin: 0;
}

.package__title__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 10px;
    margin-right: 15px;
    background: #fff;
    box-shadow: var(--shadow);
    transition: background .3s;
}

.package__table__head {
    padding: 0 62px 8px 24px;
}

.package__table__row {
    display: flex;
}

.package__table__col {
    width: 22%;

    &.small {
        width: 14%;
    }

    &.medium {
        width: 24%;
    }

    &.large {
        width: 26%;
    }
}

.package__table__label {
    font-size: 12px;
}

.filters {
    margin: 0;
}

@media (max-width: 767px) {
    .package__table__row {
        display: none;
    }
`;
