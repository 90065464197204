import React, { useEffect, createRef } from 'react';
import { createPortal } from 'react-dom';

import { Modal } from './styles';

const ModalComponent = (props) => {
	const modalRef = createRef();
	const { children, onModalClose, color } = props;

	useEffect(() => {
		const keyListener = (e) => {
			const listener = keyListenersMap.get(e.keyCode);
			return listener && listener(e);
		}

		document.addEventListener('keydown', keyListener);

		return () => document.removeEventListener('keydown', keyListener);
	});

	const trapFocus = (e) => {
		const focusableModalElements = modalRef.current.querySelectorAll(
			'button, [href], input[type="text"]:not([readonly]), select, textarea, [tabindex]:not([tabindex="-1"])'
		);

		const firstElement = focusableModalElements[0];
		const lastElement =
			focusableModalElements[focusableModalElements.length - 1];

		if (e.shiftKey && document.activeElement === firstElement) {
			// If shift key is pressed and the focus is on the first element, move focus to the last element
			lastElement.focus();
			e.preventDefault();
		} else if (!e.shiftKey && document.activeElement === lastElement) {
			// If shift key is not pressed and the focus is on the last element, move focus to the first element
			firstElement.focus();
			e.preventDefault();
		}
	};



	const keyListenersMap = new Map([
		[27, onModalClose],
		[9, trapFocus]
	]);

	const renderModal = () => {
		return (
			<Modal className="modal" role="dialog" aria-modal="true" color={color}>
				<div className="modal__dialog" ref={modalRef}>
					<div className="modal__content">
						<button className="modal__close" title="Затвори" onClick={onModalClose}>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<path d="M10.943 10l5.185 5.186-.942.942L10 10.943l-5.185 5.185-.943-.942L9.057 10 3.872 4.815l.943-.943L10 9.057l5.186-5.185.942.943L10.943 10z" />
							</svg>
						</button>

						<div className="modal__body">
							{children}
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	return createPortal(renderModal(), document.body);
}

export default ModalComponent;
