import styled from 'styled-components';

export const BiomarkerContainer = styled.div`
	padding: 20px 0;
	border-top: 1px solid var(--border-color);

	.biomarker-detail__inner {
		display: flex;
		align-items: center;
		margin: 0 -20px;
	}

	.biomarker-detail__media {
		width: 480px;
		padding: 0 20px;
	}

	.biomarker-detail__content {
		width: calc(100% - 480px);
		padding: 0 20px;
	}

	.biomarker-detail__entry {
		font-size: 14px;

		h4 {
			margin: 0 0 10px;
		}

		p {
			margin: 0 0 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: 768px) {
		.biomarker-detail__inner {
			flex-direction: column;
		}
		.biomarker-detail__media {
			width: 100%;
		}
		.biomarker-detail__content {
			margin-top: 20px;
			width: 100%;
		}
	}
`;
