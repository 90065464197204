import { AdditionalHabitsContainer } from "../styles";
import Field from "./Field";
import { translateValues } from "./translation";

const CognitiveHealth = ({
	data = {
		cantThinkOfAnythingToSay: "Very often",
		confuseRightAndLeftWhenGivingDirections: "Often",
		failToListenToPeoplesNames: "Very often",
		forgetAppointments: "Very often",
		forgetOnTipOfYourTongue: "Very often",
		forgetWhatYouCameToTheShopsToBuy: "Very often",
		forgetWhichWayToTurnOnRoad: "Very often",
		readSomethingAndNeedToReadAgain: "Very often",
		startDoingOneThingAndGetDistracted: "Very often",
	},
}) => {
	const translated = translateValues(data);
	console.log(data);
	return (
		<AdditionalHabitsContainer>
			<Field
				header={"1. Честота на четене и нужда от повторно четене"}
				paragraph={`${translated.readSomethingAndNeedToReadAgain}`}
			/>

			<Field
				header={"2. Спътване на ляво и дясно при даване на насоки"}
				paragraph={`${translated.confuseRightAndLeftWhenGivingDirections}`}
			/>

			<Field
				header={"3. Забравяне на имената на хората"}
				paragraph={`${translated.failToListenToPeoplesNames}`}
			/>

			<Field
				header={"4. Забравяне на пътя на позната улица"}
				paragraph={`${translated.forgetWhichWayToTurnOnRoad}`}
			/>

			<Field
				header={"5. Забравяне на срещи"}
				paragraph={`${translated.forgetAppointments}`}
			/>

			<Field
				header={"6. Забравяне на нещо на върха на езика"}
				paragraph={`${translated.forgetOnTipOfYourTongue}`}
			/>

			<Field
				header={"7. Забравяне за какво сте дошли в магазина"}
				paragraph={`${translated.forgetWhatYouCameToTheShopsToBuy}`}
			/>

			<Field
				header={"8. Затруднение при измисляне на нещо за казане"}
				paragraph={`${translated.cantThinkOfAnythingToSay}`}
			/>

			<Field
				header={"9. Начин на работа: започване на нещо и разсейване"}
				paragraph={`${translated.startDoingOneThingAndGetDistracted}`}
			/>
		</AdditionalHabitsContainer>
	);
};

export default CognitiveHealth;
