import React, { useRef } from "react";
import styled, { css } from "styled-components";
import BenefitsFirst from "@/assets/images/medicine-img.png";
import BenefitsSecond from "@/assets/images/digital-img.png";
import BenefitsThird from "@/assets/images/prevention-img.png";
import {
	SectionSeparator,
	SectionHeader,
	DotsBackground,
	SlideLeft,
	SlideRight,
} from "./styles";
import { media } from "../../GlobalStyles";
import useIntoView from "../../hooks/useIntoView";

const BenefitsContainer = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1200px;
	margin: auto;
	padding: 100px 0 90px 0;
	gap: 165px;

	${media.md`
		margin: 0;
		max-width: 343px;
		gap: 75px;
		margin: 0 auto;
		padding: 50px 0 0 0;
		margin-bottom: 50px;
	`}
`;

const SectionIntro = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 60px;
	${media.md`
		margin-bottom: 0;
		gap: 0;
	`}
`;

const Benefit = styled.div`
	display: flex;
	justify-content: space-between;

	&:nth-child(even) {
		flex-direction: row-reverse;
	}

	${media.md`
		flex-direction: column-reverse;
		flex-direction: column;
		&:nth-child(even) {
			flex-direction: column;
			align-items: flex-end;
		}
	`}
`;

const TextContainer = styled(SlideLeft)`
	width: 50%;
	${media.md`
		width: 100%;
	`}
`;

const TextContainerReversed = styled(SlideRight)`
	width: 50%;
	${media.md`
		width: 100%;
	`}
`;

const ImageContainerCss = css`
	z-index: 1;
	width: 40%;
	position: relative; // Ensure the container is positioned relatively

	&::before {
		content: "";
		position: absolute;
		width: 120%;
		height: 100%;
		background-color: #f2f7fb;
		z-index: -1;
		top: -16.5%;
		left: 20%;
	}

	${Benefit}:nth-child(2) &::before {
		left: -40%;
	}

	img {
		align-self: flex-end;
		display: block;
		width: 100%;
		height: auto;
	}
	${media.md`
		width: 70%;
		margin-top: 16.5%;
	`}
`;

const ImageContainer = styled(SlideRight)`
	${ImageContainerCss}
`;

const ReverseImageContainer = styled(SlideLeft)`
	${ImageContainerCss}
`;

const Subtitle = styled.h3`
	font-size: 26px;
	font-weight: 600;
	line-height: 29px;
	margin-bottom: 30px;
	${media.md`
		margin-bottom: 15px;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;		
	`}
`;

const Content = styled.p`
	opacity: 0.75;
	font-size: 16px;
	line-height: 28px;
	${media.md`
		font-size: 14px;
		letter-spacing: 0;
		line-height: 16px;
	`}
`;

const Dots = styled(DotsBackground)`
	${media.md`
		position: absolute;
		left: ${({ leftResp }) => `${leftResp}%`} ;
		margin-top: ${({ topResp }) => `${topResp}px`};
	`}
`;

const Benefits = () => {
	const observeEl1 = useRef(null);
	const observeEl2 = useRef(null);
	const observeEl3 = useRef(null);
	const seen1 = useIntoView(observeEl1);
	const seen2 = useIntoView(observeEl2);
	const seen3 = useIntoView(observeEl3);
	return (
		<BenefitsContainer id="benefits">
			<Benefit ref={observeEl1}>
				<TextContainer seen={seen1}>
					<SectionIntro>
						<SectionSeparator>Medicine 3.0</SectionSeparator>
						<SectionHeader>
							Benefits of preventive and personalized medicine
						</SectionHeader>
					</SectionIntro>
					<Subtitle>Personalization:</Subtitle>
					<Content>
						Imagine a healthcare approach that sees you as an individual, not
						just a patient. That's the essence of Personalization in Medicine
						3.0. It's about crafting medical strategies that fit your unique
						genetic makeup, lifestyle choices, and personal preferences. <br />
						<br />
						No more generic treatments—here, it's all about tailoring healthcare
						to suit your specific needs. It's a shift from the old
						one-size-fits-all model to a more personalized, effective, and
						understanding approach to your well-being.
					</Content>
				</TextContainer>
				<ImageContainer seen={seen1}>
					<img src={BenefitsFirst} alt="Personalization" />
				</ImageContainer>
				<Dots width={120} top={575} left={59} leftResp={7} topResp={570} />
			</Benefit>
			<Benefit ref={observeEl2}>
				<TextContainerReversed seen={seen2}>
					<Subtitle>Digital Integration:</Subtitle>
					<Content>
						In Medicine 3.0, technology isn't just an accessory; it's a
						game-changer. Digital integration is about seamlessly blending
						advanced technologies like artificial intelligence, telemedicine,
						and health apps into your healthcare experience. <br />
						<br />
						Think of it as having a suite of tools that make healthcare more
						efficient and accessible. Whether it's a virtual consultation,
						real-time health monitoring, or utilizing wearables, it's about
						leveraging technology to enhance the quality and convenience of your
						healthcare journey.
					</Content>
				</TextContainerReversed>
				<Dots width={120} top={600} left={40.5} leftResp={70} topResp={490} />
				<ReverseImageContainer seen={seen2}>
					<img src={BenefitsSecond} alt="Digital Integration" />
				</ReverseImageContainer>
			</Benefit>
			<Benefit ref={observeEl3}>
				<TextContainer seen={seen3}>
					<Subtitle>Preventive Focus:</Subtitle>
					<Content>
						Medicine 3.0 is rewriting the healthcare script with a focus on
						prevention rather than just treatment. Instead of waiting for
						symptoms to appear, we're proactively diving into your genetic and
						environmental risk factors to predict and prevent potential health
						issues. <br />
						<br />
						It's like having a health guardian that works to keep you well
						before problems arise. This shift towards preventive care means
						embracing a holistic approach, from lifestyle adjustments to early
						detection methods. It's not just about fixing problems; it's about
						keeping you in the best possible health for the long run.
					</Content>
				</TextContainer>
				<Dots width={120} top={575} left={58} leftResp={7} topResp={495} />
				<ImageContainer seen={seen3}>
					<img src={BenefitsThird} alt="Prevent focus" />
				</ImageContainer>
			</Benefit>
		</BenefitsContainer>
	);
};

export default Benefits;
