export const json = {
	logoFit: "cover",
	logoPosition: "right",
	pages: [
		{
			name: "goalPage",
			elements: [
				{
					type: "checkbox",
					name: "goal",
					title: "What is your motivation to purchase this service?",
					isRequired: true,
					choices: [
						{
							value: "Weight management",
							text: "Weight management",
						},
						{
							value: "General health optimization (or specific symptoms)",
							text: "General health optimization (or specific symptoms)",
						},
						{
							value: "Find your personalized exercise routine",
							text: "Find your personalized exercise routine",
						},
						{
							value: "Regulating hormone balance",
							text: "Regulating hormone balance",
						},
						{
							value: "Improve dietary habits",
							text: "Improve dietary habits",
						},
					],
					showOtherItem: true,
					showSelectAllItem: true,
				},
			],
			title: "Goal",
		},
		{
			name: "demographics",
			elements: [
				{
					type: "panel",
					name: "physicalCredentialsPanel",
					elements: [
						{
							type: "expression",
							name: "question1",
							title:
								"All fields with an asterisk (*) are required fields and must be filled out in order to process the information in strict confidentiality.",
						},
						{
							type: "boolean",
							name: "gender",
							title: "Sex",
							defaultValue: "indeterminate",
							isRequired: true,
							labelTrue: "Female",
							labelFalse: "Male",
						},
						{
							type: "text",
							name: "birthDate",
							startWithNewLine: false,
							title: "Date of birth",
							isRequired: true,
							inputType: "date",
							min: "1900-01-01",
							maxLength: 25,
						},
						{
							type: "text",
							name: "height",
							title: "Height in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "weight",
							startWithNewLine: false,
							title: "Weight in kg",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "circNeck",
							title: "Circumference of neck in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "circAbdomen",
							startWithNewLine: false,
							title: "Circumference of abdomen in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
					],
				},
			],
			title: "Demographics",
		},
		{
			name: "currentHealth",
			elements: [
				{
					type: "panel",
					name: "currentHealthPanel",
					elements: [
						{
							type: "expression",
							name: "question2",
							title:
								"Please describe your current health status if you have any chronic illnesses, acute or chronic pain, allergies, and any medications you currently take.",
						},
						{
							type: "comment",
							name: "diagnosedIllness",
							title: "Diagnosed illness",
						},
						{
							type: "comment",
							name: "allergiesDetails",
							title: "Allergy",
							allowResize: false,
						},
						{
							type: "comment",
							name: "painDetails",
							title: "Pain and location",
							allowResize: false,
						},
						{
							type: "comment",
							name: "medications",
							title: "Medications",
							allowResize: false,
						},
						{
							type: "comment",
							name: "supplementsAndVitamins",
							title: "Supplements and vitamins",
						},
					],
				},
			],
			title: "Current health state",
		},
		{
			name: "pastHealth",
			elements: [
				{
					type: "panel",
					name: "panel3",
					elements: [
						{
							type: "comment",
							name: "hospitalization",
							title: "Hospitalization",
						},
						{
							type: "comment",
							name: "operations",
							title: "Operations",
						},
					],
					title:
						"Please describe past hospitalizations, operations, and medications.",
				},
			],
			title: "Past health state",
		},
		{
			name: "familyHistory",
			elements: [
				{
					type: "panel",
					name: "familyHistoryPanel",
					elements: [
						{
							type: "boolean",
							name: "family.mother.alive",
							title: "Mother - Alive",
							defaultValue: "true",
							isRequired: true,
							labelTrue: "Yes",
							labelFalse: "No",
						},
						{
							type: "text",
							name: "family.mother.age",
							startWithNewLine: false,
							title: "Mother Age",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "tagbox",
							name: "family.mother.disorders",
							title: "Mother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "boolean",
							name: "family.father.alive",
							title: "Father - alive",
							defaultValue: "true",
							isRequired: true,
							labelTrue: "Yes",
							labelFalse: "No",
						},
						{
							type: "text",
							name: "family.father.age",
							startWithNewLine: false,
							title: "Father Age",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "tagbox",
							name: "family.father.disorders",
							title: "Father Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.maternalGrandmother.disorders",
							title: "Maternal Grandmother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.maternalGrandfather.disorders",
							title: "Maternal Grandfather Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.paternalGrandmother.disorders",
							title: "Paternal Grandmother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.paternalGrandfather.disorders",
							title: "Paternal Grandfather Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
					],
					description:
						"All fields with an asterisk (*) are required fields and must be filled out in order to process the information in strict confidentiality.",
				},
			],
			title: "Family history",
		},
		{
			name: "physicalHealth",
			elements: [
				{
					type: "radiogroup",
					name: "physicalHealth.activityLevel",
					title:
						"How would you describe your current level of physical activity?",
					isRequired: true,
					choices: [
						"Sedentary (little to no physical activity)",
						"Lightly active (minimal exercise or occasional walks)",
						"Moderately active (regular exercise a few times a week)",
						"Very active (intensive exercise most days of the week)",
					],
				},
				{
					type: "checkbox",
					name: "physicalHealth.exercise.types",
					title: "What types of exercise do you currently engage in?",
					isRequired: true,
					choices: [
						"Aerobic exercises (e.g., running, cycling, swimming)",
						"Strength training (e.g., weightlifting, resistance exercises, bodyweight exercises)",
						"Flexibility and mobility exercises (e.g., yoga, stretching, Pilates)",
						"High-intensity interval training (HIIT)",
						"Balance exercises (e.g., tai chi, stability exercises)",
						"Endurance activities (e.g., hiking, long walks)",
					],
					showOtherItem: true,
					showNoneItem: true,
				},
				{
					type: "text",
					name: "physicalHealth.exercise.time",
					title: "Average hours of exercise per week",
					isRequired: true,
					inputType: "number",
					min: 0,
					max: 168,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.resistanceTrainingFrequency",
					title:
						"How often do you incorporate resistance training into your exercise routine?",
					isRequired: true,
					choices: [
						"Rarely or never",
						"Occasionally",
						"Regularly, at least once a week",
						"Regularly, multiple times a week",
					],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.activitiesCardiovascularHealth",
					title:
						"Do you engage in activities that promote cardiovascular health?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "comment",
					name: "physicalHealth.exercise.intensityManagement",
					title:
						"How do you manage your overall exercise intensity? (please specify)",
					isRequired: false,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.recoveryAndRestApproach",
					title: "What is your approach to recovery and rest days?",
					isRequired: true,
					choices: [
						"I rarely take rest days",
						"I take rest days occasionally",
						"I prioritize regular rest and recovery",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.exercise.considerationOfLongevitySpecific",
					title:
						"Have you ever considered incorporating longevity-specific exercises into your routine?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "radiogroup",
					name: "physicalHealth.exercise.routineEnjoymentSustainability",
					title:
						"How would you rate your exercise routine in terms of enjoyment and sustainability?",
					isRequired: true,
					choices: [
						"Very enjoyable and sustainable",
						"Enjoyable but not sustainable",
						"Sustainable but not enjoyable",
						"Neither enjoyable nor sustainable",
					],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.trackingFitnessDevices",
					title:
						"Do you track your physical activity or use any fitness devices?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "No"],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.roleOfPhysicalActivityInHealthLongevity",
					title:
						"What role does physical activity play in your overall approach to health and longevity?",
					isRequired: true,
					choices: [
						"Essential component",
						"Important but not central",
						"Minor role",
						"No role",
					],
				},
			],
			title: "Physical Health",
		},
		{
			name: "nutrition",
			elements: [
				{
					type: "radiogroup",
					name: "nutrition.approach",
					title: "How would you describe your current approach to nutrition?",
					isRequired: true,
					choices: [
						"Balanced and varied",
						"Plant-based",
						"Mediterranean",
						"Low-carb",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.primaryProteinSource",
					title: "What is your primary source of protein in your diet?",
					isRequired: true,
					choices: [
						"Animal sources (meat, fish, poultry)",
						"Plant-based sources (legumes, tofu, tempeh)",
						"Combination of both",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.processedRefinedFoodsConsumption",
					title: "How frequently do you consume processed or refined foods?",
					isRequired: true,
					choices: [
						"Rarely or never",
						"Occasionally",
						"Regularly, but in moderation",
						"Frequently",
					],
				},
				{
					type: "radiogroup",
					name: "nutrition.typicalDietaryFatsSource",
					title: "What is your typical source of dietary fats?",
					isRequired: true,
					choices: [
						"Healthy fats (avocado, nuts, olive oil)",
						"Saturated fats (butter, fatty meats)",
						"Combination of both",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.foodAllergies",
					title: "Do you have any food allergies?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "radiogroup",
					name: "nutrition.numberOfFeedingsPerDay",
					title: "What is the typical number of feedings per day?",
					isRequired: true,
					choices: ["Two or less", "Three or more"],
				},
				{
					type: "panel",
					name: "nutrientsPerWeekDescription",
					elements: [
						{
							type: "text",
							name: "nutrition.portionsOfFruitPerWeek",
							title: "Number of portions of fruit per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfVegetablesPerWeek",
							title: "Number of portions of vegetables per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfRedMeatPerWeek",
							title: "Number of portions of red meat per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfFishPerWeek",
							title: "Number of portions of fish per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfSnacksAndSweetsPerWeek",
							title: "Number of portions of snacks and sweets per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.caffeinatedDrinksPerDay",
							title: "Number of caffeinated drinks per day:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.herbalTeasPerDay",
							title: "Number of herbal teas per day:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
					],
					title: "Please describe the number of nutrients per week:",
				},
			],
			title: "Nutrition",
		},
		{
			name: "cognitiveHealth",
			elements: [
				{
					type: "radiogroup",
					name: "cognitiveHealth.readSomethingAndNeedToReadAgain",
					title:
						"How often do you find that you read something and realize you haven't thought about it and need to read it again?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.confuseRightAndLeftWhenGivingDirections",
					title:
						"Do you find you confuse right and left when giving directions?",
					isRequired: true,
					choices: ["Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.failToListenToPeoplesNames",
					title:
						"Do you fail to listen to people’s names when you are meeting them?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetWhichWayToTurnOnRoad",
					title:
						"Do you find you forget which way to turn on a road you know well but rarely use?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetAppointments",
					title: "Do you find you forget appointments?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetOnTipOfYourTongue",
					title:
						"Do you find you can’t quite remember something, although it’s “on the tip of your tongue”?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetWhatYouCameToTheShopsToBuy",
					title: "Do you find you forget what you came to the shops to buy?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.cantThinkOfAnythingToSay",
					title: "Do you find you can’t think of anything to say?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.startDoingOneThingAndGetDistracted",
					title:
						"Do you start doing one thing at home and get distracted into doing something else (unintentionally)?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
			],
			title: "Cognitive Health",
		},
		{
			name: "emotionalHealth",
			elements: [
				{
					type: "checkbox",
					name: "emotionalHealth.stressManagementStrategies",
					title: "How do you typically manage stress in your life?",
					isRequired: true,
					choices: [
						"Regular exercise",
						"Mindfulness or meditation",
						"Seeking support from friends or family",
						"Engaging in hobbies or activities",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I have a hard time managing stress",
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.handlingChallengingSituations",
					title:
						"How well do you feel you handle challenging situations or setbacks?",
					isRequired: true,
					choices: ["Very well", "Moderately well", "Not very well", "Poorly"],
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.prioritizingTimeForJoyRelaxation",
					title:
						"Do you prioritize and allocate time for activities that bring you joy and relaxation?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely or never"],
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.frequencyOfConnectingForEmotionalSupport",
					title:
						"How often do you connect with friends or loved ones for emotional support?",
					isRequired: true,
					choices: ["Daily", "Weekly", "Monthly", "Rarely or never"],
				},
				{
					type: "checkbox",
					name: "emotionalHealth.selfTalkAndSelfEsteemStrategies",
					title:
						"What strategies do you employ to promote positive self-talk and self-esteem?",
					isRequired: true,
					choices: [
						"Affirmations",
						"Journaling",
						"Cognitive-behavioral techniques",
						"Seeking professional counseling",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I do not use any strategy",
				},
				{
					type: "checkbox",
					name: "emotionalHealth.senseOfPurposeStrategies",
					title:
						"How do you foster a sense of purpose or meaning in your life?",
					isRequired: true,
					choices: [
						"Pursuing personal goals",
						"Volunteering or helping others",
						"Engaging in spiritual or religious practices",
						"Finding meaning in work or relationships",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I feel like I have no purpose in my life",
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.satisfactionWithWorkLifeBalance",
					title: "How satisfied are you with your current work-life balance?",
					isRequired: true,
					choices: [
						"Very satisfied",
						"Moderately satisfied",
						"Not very satisfied",
						"Dissatisfied",
					],
				},
			],
			title: "Emotional health",
		},
		{
			name: "sleepHabits",
			elements: [
				{
					type: "radiogroup",
					name: "sleepHabits.hoursOfSleep",
					title:
						"How many hours of sleep do you typically get on a regular night?",
					isRequired: true,
					choices: [
						"Less than 6 hours",
						"6-7 hours",
						"7-8 hours",
						"More than 8 hours",
					],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepQuality",
					title: "How would you rate the quality of your sleep?",
					isRequired: true,
					choices: ["Excellent", "Good", "Fair", "Poor"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.consistentSleepSchedule",
					title:
						"Do you have a consistent sleep schedule, going to bed and waking up at the same time each day?",
					isRequired: true,
					choices: [
						"Yes, consistently",
						"Most days",
						"Occasionally",
						"Rarely or never",
					],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepDifficulty",
					title:
						"How often do you experience difficulty falling asleep or staying asleep?",
					isRequired: true,
					choices: ["Rarely or never", "Occasionally", "Frequently", "Always"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.deviceUsageBeforeBed",
					title:
						"Do you use any electronic devices (phone, computer, etc.) before bedtime?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.daytimeNappingFrequency",
					title: "Do you nap during the day, and if so, how frequently?",
					isRequired: true,
					choices: ["Never", "Occasionally", "Frequently"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.nighttimeBreathingDifficulty",
					title:
						"Do you experience difficulty breathing, coughing, or snoring at night (reported by partner)?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepAidsUsage",
					title: "Do you use medications and supplements to help you sleep?",
					isRequired: true,
					choices: ["Never", "Occasionally", "Frequently"],
				},
			],
			title: "Sleep Habits",
		},
		{
			name: "additionalHabits",
			elements: [
				{
					type: "radiogroup",
					name: "additionalHabits.smokingHistory",
					title: "Do you smoke, or have you smoked in the past?",
					isRequired: true,
					choices: ["Yes, currently", "Yes, in the past", "No, never"],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.secondHandSmokeExposure",
					title: "Are you exposed to second-hand smoke?",
					isRequired: true,
					choices: ["Yes, currently", "Yes, in the past", "No, never"],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.alcoholConsumption",
					title: "How would you rate your current alcohol consumption?",
					isRequired: true,
					choices: [
						"None",
						"Moderate (1-2 drinks per day)",
						"Occasional (3-4 drinks per week)",
						"Excessive (more than 3 drinks per day)",
					],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.recreationalDrugUse",
					title: "Do you engage in recreational drug use?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely or never"],
				},
			],
			title: "Additional Habits",
		},
	],
	showTitle: false,
	showQuestionNumbers: "off",
	questionErrorLocation: "bottom",
	showProgressBar: "top",
	maxTextLength: 1000,
	allowResizeComment: false,
	completeText: "Submit",
	questionTitlePattern: "numRequireTitle",
	widthMode: "static",
	width: "1000px",
};

export const singlePageJson = {
	logoFit: "cover",
	pages: [
		{
			name: "Test",
			elements: [
				{
					type: "checkbox",
					name: "goal",
					title: "What is your motivation to purchase this service?",
					isRequired: true,
					choices: [
						{
							value: "Weight management",
							text: "Weight management",
						},
						{
							value: "General health optimization (or specific symptoms)",
							text: "General health optimization (or specific symptoms)",
						},
						{
							value: "Find your personalized exercise routine",
							text: "Find your personalized exercise routine",
						},
						{
							value: "Regulating hormone balance",
							text: "Regulating hormone balance",
						},
						{
							value: "Improve dietary habits",
							text: "Improve dietary habits",
						},
					],
					showOtherItem: true,
					showSelectAllItem: true,
				},
				{
					type: "panel",
					name: "physicalCredentialsPanel",
					elements: [
						{
							type: "expression",
							name: "question1",
							title:
								"All fields with an asterisk (*) are required fields and must be filled out in order to process the information in strict confidentiality.",
						},
						{
							type: "boolean",
							name: "gender",
							title: "Sex",
							defaultValue: "indeterminate",
							isRequired: true,
							labelTrue: "Female",
							labelFalse: "Male",
						},
						{
							type: "text",
							name: "birthDate",
							startWithNewLine: false,
							title: "Date of birth",
							isRequired: true,
							inputType: "date",
							min: "1900-01-01",
							maxLength: 25,
						},
						{
							type: "text",
							name: "height",
							title: "Height in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "weight",
							startWithNewLine: false,
							title: "Weight in kg",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "circNeck",
							title: "Circumference of neck in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "circAbdomen",
							startWithNewLine: false,
							title: "Circumference of abdomen in cm",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
					],
				},

				{
					type: "panel",
					name: "currentHealthPanel",
					elements: [
						{
							type: "expression",
							name: "question2",
							title:
								"Please describe your current health status if you have any chronic illnesses, acute or chronic pain, allergies, and any medications you currently take.",
						},
						{
							type: "comment",
							name: "diagnosedIllness",
							title: "Diagnosed illness",
						},
						{
							type: "comment",
							name: "allergiesDetails",
							title: "Allergy",
							allowResize: false,
						},
						{
							type: "comment",
							name: "painDetails",
							title: "Pain and location",
							allowResize: false,
						},
						{
							type: "comment",
							name: "medications",
							title: "Medications",
							allowResize: false,
						},
						{
							type: "comment",
							name: "supplementsAndVitamins",
							title: "Supplements and vitamins",
						},
					],
				},

				{
					type: "panel",
					name: "panel3",
					elements: [
						{
							type: "comment",
							name: "hospitalization",
							title: "Hospitalization",
						},
						{
							type: "comment",
							name: "operations",
							title: "Operations",
						},
					],
					title:
						"Please describe past hospitalizations, operations, and medications.",
				},

				{
					type: "panel",
					name: "familyHistoryPanel",
					elements: [
						{
							type: "boolean",
							name: "family.mother.alive",
							title: "Mother - Alive",
							defaultValue: "true",
							isRequired: true,
							labelTrue: "Yes",
							labelFalse: "No",
						},
						{
							type: "text",
							name: "family.mother.age",
							startWithNewLine: false,
							title: "Mother Age",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "tagbox",
							name: "family.mother.disorders",
							title: "Mother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "boolean",
							name: "family.father.alive",
							title: "Father - alive",
							defaultValue: "true",
							isRequired: true,
							labelTrue: "Yes",
							labelFalse: "No",
						},
						{
							type: "text",
							name: "family.father.age",
							startWithNewLine: false,
							title: "Father Age",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "tagbox",
							name: "family.father.disorders",
							title: "Father Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.maternalGrandmother.disorders",
							title: "Maternal Grandmother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.maternalGrandfather.disorders",
							title: "Maternal Grandfather Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.paternalGrandmother.disorders",
							title: "Paternal Grandmother Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
						{
							type: "tagbox",
							name: "family.paternalGrandfather.disorders",
							title: "Paternal Grandfather Disorders",
							defaultValue: ["none"],
							isRequired: true,
							choices: [
								{
									value: "cardiovascular",
									text: "Cardiovascular diseases",
								},
								{
									value: "pulmonary",
									text: "Pulmonary disorders",
								},
								{
									value: "gastrointestinal",
									text: "Gastrointestinal disorders",
								},
								{
									value: "liverkidney",
									text: "Liver and kidney diagnosis",
								},
								{
									value: "psychiatric ",
									text: "Psychiatric disorders",
								},
								{
									value: "cancer",
									text: "Cancer",
								},
								{
									value: "operations",
									text: "Operations",
								},
								{
									value: "autoimmune",
									text: "Autoimmune disorders",
								},
								{
									value: "physical",
									text: "Physical trauma and fractures",
								},
								{
									value: "genetic",
									text: "Genetic diseases",
								},
							],
							showOtherItem: true,
							showNoneItem: true,
						},
					],
					description:
						"All fields with an asterisk (*) are required fields and must be filled out in order to process the information in strict confidentiality.",
				},

				{
					type: "radiogroup",
					name: "physicalHealth.activityLevel",
					title:
						"How would you describe your current level of physical activity?",
					isRequired: true,
					choices: [
						"Sedentary (little to no physical activity)",
						"Lightly active (minimal exercise or occasional walks)",
						"Moderately active (regular exercise a few times a week)",
						"Very active (intensive exercise most days of the week)",
					],
				},
				{
					type: "checkbox",
					name: "physicalHealth.exercise.types",
					title: "What types of exercise do you currently engage in?",
					isRequired: true,
					choices: [
						"Aerobic exercises (e.g., running, cycling, swimming)",
						"Strength training (e.g., weightlifting, resistance exercises, bodyweight exercises)",
						"Flexibility and mobility exercises (e.g., yoga, stretching, Pilates)",
						"High-intensity interval training (HIIT)",
						"Balance exercises (e.g., tai chi, stability exercises)",
						"Endurance activities (e.g., hiking, long walks)",
					],
					showOtherItem: true,
					showNoneItem: true,
				},
				{
					type: "text",
					name: "physicalHealth.exercise.time",
					title: "Average hours of exercise per week",
					isRequired: true,
					inputType: "number",
					min: 0,
					max: 168,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.resistanceTrainingFrequency",
					title:
						"How often do you incorporate resistance training into your exercise routine?",
					isRequired: true,
					choices: [
						"Rarely or never",
						"Occasionally",
						"Regularly, at least once a week",
						"Regularly, multiple times a week",
					],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.activitiesCardiovascularHealth",
					title:
						"Do you engage in activities that promote cardiovascular health?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "comment",
					name: "physicalHealth.exercise.intensityManagement",
					title:
						"How do you manage your overall exercise intensity? (please specify)",
					isRequired: false,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.recoveryAndRestApproach",
					title: "What is your approach to recovery and rest days?",
					isRequired: true,
					choices: [
						"I rarely take rest days",
						"I take rest days occasionally",
						"I prioritize regular rest and recovery",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "physicalHealth.exercise.considerationOfLongevitySpecific",
					title:
						"Have you ever considered incorporating longevity-specific exercises into your routine?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "radiogroup",
					name: "physicalHealth.exercise.routineEnjoymentSustainability",
					title:
						"How would you rate your exercise routine in terms of enjoyment and sustainability?",
					isRequired: true,
					choices: [
						"Very enjoyable and sustainable",
						"Enjoyable but not sustainable",
						"Sustainable but not enjoyable",
						"Neither enjoyable nor sustainable",
					],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.trackingFitnessDevices",
					title:
						"Do you track your physical activity or use any fitness devices?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "No"],
				},
				{
					type: "radiogroup",
					name: "physicalHealth.roleOfPhysicalActivityInHealthLongevity",
					title:
						"What role does physical activity play in your overall approach to health and longevity?",
					isRequired: true,
					choices: [
						"Essential component",
						"Important but not central",
						"Minor role",
						"No role",
					],
				},

				{
					type: "radiogroup",
					name: "nutrition.approach",
					title: "How would you describe your current approach to nutrition?",
					isRequired: true,
					choices: [
						"Balanced and varied",
						"Plant-based",
						"Mediterranean",
						"Low-carb",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.primaryProteinSource",
					title: "What is your primary source of protein in your diet?",
					isRequired: true,
					choices: [
						"Animal sources (meat, fish, poultry)",
						"Plant-based sources (legumes, tofu, tempeh)",
						"Combination of both",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.processedRefinedFoodsConsumption",
					title: "How frequently do you consume processed or refined foods?",
					isRequired: true,
					choices: [
						"Rarely or never",
						"Occasionally",
						"Regularly, but in moderation",
						"Frequently",
					],
				},
				{
					type: "radiogroup",
					name: "nutrition.typicalDietaryFatsSource",
					title: "What is your typical source of dietary fats?",
					isRequired: true,
					choices: [
						"Healthy fats (avocado, nuts, olive oil)",
						"Saturated fats (butter, fatty meats)",
						"Combination of both",
					],
					showOtherItem: true,
				},
				{
					type: "radiogroup",
					name: "nutrition.foodAllergies",
					title: "Do you have any food allergies?",
					isRequired: true,
					choices: ["No"],
					showOtherItem: true,
					otherText: "Yes (Please specify)",
				},
				{
					type: "radiogroup",
					name: "nutrition.numberOfFeedingsPerDay",
					title: "What is the typical number of feedings per day?",
					isRequired: true,
					choices: ["Two or less", "Three or more"],
				},
				{
					type: "panel",
					name: "nutrientsPerWeekDescription",
					elements: [
						{
							type: "text",
							name: "nutrition.portionsOfFruitPerWeek",
							title: "Number of portions of fruit per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfVegetablesPerWeek",
							title: "Number of portions of vegetables per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfRedMeatPerWeek",
							title: "Number of portions of red meat per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfFishPerWeek",
							title: "Number of portions of fish per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.portionsOfSnacksAndSweetsPerWeek",
							title: "Number of portions of snacks and sweets per week:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.caffeinatedDrinksPerDay",
							title: "Number of caffeinated drinks per day:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
						{
							type: "text",
							name: "nutrition.herbalTeasPerDay",
							title: "Number of herbal teas per day:",
							isRequired: true,
							inputType: "number",
							min: 0,
						},
					],
					title: "Please describe the number of nutrients per week:",
				},

				{
					type: "radiogroup",
					name: "cognitiveHealth.readSomethingAndNeedToReadAgain",
					title:
						"How often do you find that you read something and realize you haven't thought about it and need to read it again?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.confuseRightAndLeftWhenGivingDirections",
					title:
						"Do you find you confuse right and left when giving directions?",
					isRequired: true,
					choices: ["Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.failToListenToPeoplesNames",
					title:
						"Do you fail to listen to people’s names when you are meeting them?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetWhichWayToTurnOnRoad",
					title:
						"Do you find you forget which way to turn on a road you know well but rarely use?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetAppointments",
					title: "Do you find you forget appointments?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetOnTipOfYourTongue",
					title:
						"Do you find you can’t quite remember something, although it’s “on the tip of your tongue”?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.forgetWhatYouCameToTheShopsToBuy",
					title: "Do you find you forget what you came to the shops to buy?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.cantThinkOfAnythingToSay",
					title: "Do you find you can’t think of anything to say?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "cognitiveHealth.startDoingOneThingAndGetDistracted",
					title:
						"Do you start doing one thing at home and get distracted into doing something else (unintentionally)?",
					isRequired: true,
					choices: ["Very often", "Often", "Sometimes", "Rarely", "Never"],
				},

				{
					type: "checkbox",
					name: "emotionalHealth.stressManagementStrategies",
					title: "How do you typically manage stress in your life?",
					isRequired: true,
					choices: [
						"Regular exercise",
						"Mindfulness or meditation",
						"Seeking support from friends or family",
						"Engaging in hobbies or activities",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I have a hard time managing stress",
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.handlingChallengingSituations",
					title:
						"How well do you feel you handle challenging situations or setbacks?",
					isRequired: true,
					choices: ["Very well", "Moderately well", "Not very well", "Poorly"],
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.prioritizingTimeForJoyRelaxation",
					title:
						"Do you prioritize and allocate time for activities that bring you joy and relaxation?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely or never"],
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.frequencyOfConnectingForEmotionalSupport",
					title:
						"How often do you connect with friends or loved ones for emotional support?",
					isRequired: true,
					choices: ["Daily", "Weekly", "Monthly", "Rarely or never"],
				},
				{
					type: "checkbox",
					name: "emotionalHealth.selfTalkAndSelfEsteemStrategies",
					title:
						"What strategies do you employ to promote positive self-talk and self-esteem?",
					isRequired: true,
					choices: [
						"Affirmations",
						"Journaling",
						"Cognitive-behavioral techniques",
						"Seeking professional counseling",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I do not use any strategy",
				},
				{
					type: "checkbox",
					name: "emotionalHealth.senseOfPurposeStrategies",
					title:
						"How do you foster a sense of purpose or meaning in your life?",
					isRequired: true,
					choices: [
						"Pursuing personal goals",
						"Volunteering or helping others",
						"Engaging in spiritual or religious practices",
						"Finding meaning in work or relationships",
					],
					showOtherItem: true,
					showNoneItem: true,
					noneText: "I feel like I have no purpose in my life",
				},
				{
					type: "radiogroup",
					name: "emotionalHealth.satisfactionWithWorkLifeBalance",
					title: "How satisfied are you with your current work-life balance?",
					isRequired: true,
					choices: [
						"Very satisfied",
						"Moderately satisfied",
						"Not very satisfied",
						"Dissatisfied",
					],
				},

				{
					type: "radiogroup",
					name: "sleepHabits.hoursOfSleep",
					title:
						"How many hours of sleep do you typically get on a regular night?",
					isRequired: true,
					choices: [
						"Less than 6 hours",
						"6-7 hours",
						"7-8 hours",
						"More than 8 hours",
					],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepQuality",
					title: "How would you rate the quality of your sleep?",
					isRequired: true,
					choices: ["Excellent", "Good", "Fair", "Poor"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.consistentSleepSchedule",
					title:
						"Do you have a consistent sleep schedule, going to bed and waking up at the same time each day?",
					isRequired: true,
					choices: [
						"Yes, consistently",
						"Most days",
						"Occasionally",
						"Rarely or never",
					],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepDifficulty",
					title:
						"How often do you experience difficulty falling asleep or staying asleep?",
					isRequired: true,
					choices: ["Rarely or never", "Occasionally", "Frequently", "Always"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.deviceUsageBeforeBed",
					title:
						"Do you use any electronic devices (phone, computer, etc.) before bedtime?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.daytimeNappingFrequency",
					title: "Do you nap during the day, and if so, how frequently?",
					isRequired: true,
					choices: ["Never", "Occasionally", "Frequently"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.nighttimeBreathingDifficulty",
					title:
						"Do you experience difficulty breathing, coughing, or snoring at night (reported by partner)?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely", "Never"],
				},
				{
					type: "radiogroup",
					name: "sleepHabits.sleepAidsUsage",
					title: "Do you use medications and supplements to help you sleep?",
					isRequired: true,
					choices: ["Never", "Occasionally", "Frequently"],
				},

				{
					type: "radiogroup",
					name: "additionalHabits.smokingHistory",
					title: "Do you smoke, or have you smoked in the past?",
					isRequired: true,
					choices: ["Yes, currently", "Yes, in the past", "No, never"],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.secondHandSmokeExposure",
					title: "Are you exposed to second-hand smoke?",
					isRequired: true,
					choices: ["Yes, currently", "Yes, in the past", "No, never"],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.alcoholConsumption",
					title: "How would you rate your current alcohol consumption?",
					isRequired: true,
					choices: [
						"None",
						"Moderate (1-2 drinks per day)",
						"Occasional (3-4 drinks per week)",
						"Excessive (more than 3 drinks per day)",
					],
				},
				{
					type: "radiogroup",
					name: "additionalHabits.recreationalDrugUse",
					title: "Do you engage in recreational drug use?",
					isRequired: true,
					choices: ["Yes, regularly", "Occasionally", "Rarely or never"],
				},
			],
			title: "Additional Habits",
		},
	],
	showTitle: false,
	showQuestionNumbers: "off",
	questionErrorLocation: "bottom",
	maxTextLength: 1000,
	allowResizeComment: false,
	completeText: "Submit",
	questionTitlePattern: "numRequireTitle",
	widthMode: "static",
	width: "1000px",
}