import styled from "styled-components";
import dots from "@/assets/images/dots.svg";
import { media } from "../../GlobalStyles";

export const Title = styled.h1`
	line-height: 66px;
	font-size: 60px;
	font-weight: 700;

	${media.md`
		line-height: 39px;
		font-size: 36px;
		margin: 0;
		`}
`;

export const ContentContainer = styled.section`
	max-width: 1200px;
	margin: 0 auto;

	${media.md`
		max-width: 343px;
		`}
`;

export const SectionSeparator = styled.h6`
	display: flex;
	height: 31px;
	opacity: 0.75;
	font-size: 26px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 31px;
	margin-bottom: 30px;

	&:before {
		content: "";
		opacity: 1;
		background: #82f3c1;
		display: block;
		width: 30px;
		height: 6px;
		border-radius: 3px;
		margin: auto 10px auto 0;
	}
	${media.md`
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;
		height: 20px;
		margin-bottom: 16px;
	`}
`;

export const SectionHeader = styled.h2`
	font-weight: 600;
	font-size: 36px;
	letter-spacing: 0;
	line-height: 39px;

	${media.md`
		font-size: 22.25px;
		line-height: 25px;
		margin-bottom: 16px;
	`}
`;

// Used for the gray paragraphs
export const SectionParagraph = styled.p`
	transition: all 0.2s ease-in-out;
	max-height: ${(props) => (props.expanded ? "1000px" : "0")};
	overflow: hidden;
	margin: 0;
	opacity: 0.75;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 28px;
	${media.md`
		font-size: 12px;
		letter-spacing: 0;
		line-height: 13px;
		`}
`;

export const DotsBackground = styled.div`
	position: absolute;
	left: ${({ left }) => (left ? `${left}%` : "0")};
	width: ${({ width }) => (width ? `${width}px` : "230px")};
	margin-top: ${({ top }) => (top ? `${top}px` : "0")};
	background: no-repeat url(${dots});
	padding-bottom: 118px;

	${media.md`
		scale: 0.6;
	`}
`;

export const SlideUp = styled.div`
	@keyframes slideInUp {
		0% {
			transform: translateY(20%);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
	animation: ${({ seen }) => (seen ? `1s ease-out 0s 1 slideInUp` : "")};
	animation-delay: ${({ delay }) => (delay ? `${delay}s` : "s")};
	animation-fill-mode: backwards;
	opacity: ${({ seen }) => (seen ? "1" : "0")};
`;

export const SlideRight = styled.div`
	@keyframes slideInRight {
		0% {
			transform: translateX(20%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}
	animation: ${({ seen }) => (seen ? `1s ease-out 0s 1 slideInRight` : "")};
	animation-delay: ${({ delay }) => (delay ? `${delay}s` : "s")};
	animation-fill-mode: backwards;
	opacity: ${({ seen }) => (seen ? "1" : "0")};
`;

export const SlideLeft = styled.div`
	@keyframes slideInLeft {
		0% {
			transform: translateX(-40%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}
	animation: ${({ seen }) => (seen ? `1s ease-out 0s 1 slideInLeft` : "")};
	animation-delay: ${({ delay }) => (delay ? `${delay}s` : "s")};
	animation-fill-mode: backwards;
	opacity: ${({ seen }) => (seen ? "1" : "0")};
`;

export const HoverBottomAnimation = styled.div`
	&:after {
		position: absolute;
		top: 100%;
		left: 0%;
		width: 0;
		height: 2px;
		background: #222;
		display: block;
		content: "";
		transition: width 0.3s ease-in-out;
	}
	&:hover:after {
		width: 100%;
	}
`;

// export const Container = styled.section`
// 	display: flex;
// 	flex: 1;
// 	flex-direction: column;
// 	background-color: rgb(252, 252, 255);
// 	overflow: auto;
// 	justify-content: flex-start;
// 	align-items: center;

// 	.first {
// 		@media ${device.mobileL} {
// 			padding-top: 64px;
// 		}

// 		@media ${device.mobileM} {
// 			padding-top: 44px;
// 		}

// 		&__heading {
// 			@media ${device.mobileL} {
// 				padding: 0 32px;
// 			}
// 		}

// 		&__button {
// 			z-index: 5;
// 		}

// 		&__text {
// 			@media ${device.tablet} {
// 				max-width: 700px;
// 			}

// 			@media ${device.mobileL} {
// 				max-width: 100%;
// 				padding: 0 22px;
// 			}

// 			@media ${device.mobileM} {
// 				padding: 0;
// 			}
// 		}

// 		&__figure {
// 			@media ${device.mobileL} {
// 				margin: 0 auto;
// 				left: 0;
// 				right: 0;
// 			}
// 		}

// 		&__content {
// 			margin: 0 16px;
// 		}
// 	}

// 	.second {
// 		padding-top: 368px;
// 		padding-left: 132px;

// 		@media ${device.laptop} {
// 			padding-top: 232px;
// 		}

// 		@media ${device.mobileL} {
// 			padding-top: 30px;
// 		}

// 		&__image-container {
// 			@media ${device.laptop} {
// 				margin-right: -28px;
// 			}

// 			@media ${device.tablet} {
// 				margin-right: -84px;
// 			}

// 			@media ${device.mobileL} {
// 				margin-right: 0;
// 				padding-top: 30px;
// 			}
// 		}

// 		&__content {
// 			max-width: 360px;
// 		}
// 	}

// 	.third {
// 		padding-right: 132px;

// 		&__image-container {
// 			@media ${device.laptop} {
// 				margin-left: -41px;
// 			}

// 			@media ${device.tablet} {
// 				margin-left: -89px;
// 				padding-right: 30px;
// 			}

// 			@media ${device.mobileL} {
// 				margin-left: 0;
// 				padding-right: 0;
// 				margin-top: 16px;
// 				margin-bottom: 30px;
// 			}
// 		}

// 		&__content {
// 			max-width: 422px;

// 			@media ${device.tablet} {
// 				max-width: 362px;
// 			}

// 			@media ${device.mobileL} {
// 				margin-bottom: 24px;
// 			}
// 		}
// 	}

// 	.fourth {
// 		padding-right: 132px;
// 		padding-left: 132px;
// 	}

// 	.fifth {
// 		padding-left: 132px;

// 		&__image-container {
// 			@media ${device.laptop} {
// 				margin-right: -41px;
// 			}

// 			@media ${device.tablet} {
// 				margin-right: -89px;
// 			}

// 			@media ${device.mobileL} {
// 				margin-right: 0;
// 			}
// 		}

// 		&__image {
// 			margin-top: -128px;

// 			@media ${device.tablet} {
// 				max-width: 563px;
// 			}

// 			@media ${device.mobileL} {
// 				margin-top: 0;
// 				width: 100%;
// 			}
// 		}

// 		&__text {
// 			@media ${device.mobileL} {
// 				padding: 0 22px;
// 			}

// 			@media ${device.mobileM} {
// 				padding: 0;
// 			}
// 		}
// 	}

// 	.fifth-inner {
// 		overflow: visible;
// 	}

// 	.sixth,
// 	.seventh {
// 		padding-left: 132px;
// 		padding-right: 132px;
// 	}

// 	.second,
// 	.fourth,
// 	.fifth,
// 	.sixth,
// 	.seventh {
// 		@media ${device.laptop} {
// 			padding-left: 64px;
// 		}

// 		@media ${device.tablet} {
// 			padding-left: 16px;
// 		}
// 	}

// 	.third,
// 	.fourth,
// 	.sixth,
// 	.seventh {
// 		@media ${device.laptop} {
// 			padding-right: 64px;
// 		}
// 	}

// 	.second,
// 	.third,
// 	.fourth,
// 	.fifth,
// 	.sixth,
// 	.seventh {
// 		@media ${device.tablet} {
// 			padding-left: 16px;
// 			padding-right: 16px;
// 		}
// 	}
// `;

// export const Header = styled.div`
// 	display: flex;
// 	flex: 1;
// 	width: 100%;
// 	align-items: center;
// 	position: fixed;
// 	align-self: center;
// 	z-index: 100;
// 	padding: 25px 132px;
// 	max-width: 1500px;
// 	background-color: rgb(252, 252, 255);
// 	top: 0;

// 	.logo {
// 		width: 177px;

// 		@media ${device.mobileM} {
// 			width: 125px;
// 			height: 22px;
// 		}
// 	}

// 	@media ${device.laptop} {
// 		padding-left: 64px;
// 		padding-right: 64px;
// 	}

// 	@media ${device.tablet} {
// 		padding-left: 16px;
// 		padding-right: 16px;
// 	}

// 	@media ${device.mobileL} {
// 		padding-left: 54px;
// 		justify-content: space-between;
// 		height: 70px;
// 	}
// `;

// export const NavContainer = styled.div`
// 	display: flex;
// 	flex: 1;
// 	padding-left: 46px;
// 	align-items: center;
// `;

// export const RegButton = styled.button`
// 	font-weight: 500;
// 	font-size: 16px;
// `;

// export const MenuContainer = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	position: absolute;
// 	top: 70px;
// 	transform: translateX(-50%);
// 	border-radius: 4px;
// 	overflow: hidden;
// 	background-color: white;
// 	-webkit-box-shadow: 0px 3px 9px 4px rgba(0, 0, 0, 0.03);
// 	box-shadow: 0px 3px 9px 4px rgba(0, 0, 0, 0.03);
// 	padding: 12px 16px 0 16px;
// `;

// export const PersonalTestMenuHeading = styled.h5`
// 	margin-bottom: 6px;
// 	font-weight: 500;
// `;

// export const PersonalTestMenuSubtitle = styled.h6`
// 	color: var(--text-color);
// 	opacity: 0.5;
// `;

// export const Separator = styled.hr`
// 	background-color: var(--text-color);
// 	opacity: 0.1;
// 	height: 1px;
// 	width: ${(props) => (props.width ? `${props.width}%` : "100%")};
// 	border: 0;
// 	margin: ${(props) => (props.margin ? props.margin : "10px 0")};
// `;

// export const MenuElement = styled.h6`
// 	color: var(--text-color);

// 	@media ${device.mobileL} {
// 		margin-bottom: 12px;
// 	}

// 	&:hover {
// 		opacity: 0.6;
// 	}
// `;

// export const ColoredMenuElement = styled.h6`
// 	color: var(--blue);
// 	font-weight: 500;

// 	@media ${device.mobileL} {
// 		margin-bottom: 12px;
// 	}

// 	&:hover {
// 		color: var(--blue-dark);
// 	}
// `;

// export const MainContentContainer = styled.div`
// 	display: flex;
// 	flex-direction: ${(props) => props.flexDirection};
// 	padding-bottom: ${(props) => `${props.pb}px` || 0};
// 	padding-top: ${(props) => `${props.pt}px` || 0};
// 	background-color: ${(props) => props.bgColor || "transparent"};
// 	width: 100%;
// 	max-width: 1500px;
// 	overflow: hidden;

// 	@media ${device.tablet} {
// 		padding-bottom: ${(props) => (props.pb ? `${props.pb / 2}px` : 0)};
// 		padding-top: ${(props) => (props.pt ? `${props.pt / 2}px` : 0)};
// 	}

// 	@media ${device.mobileL} {
// 		flex-direction: ${(props) => props.flexDirectionMobile};
// 		padding-bottom: ${(props) => (props.pbMobile ? `${props.pbMobile}px` : 0)};
// 		padding-top: ${(props) => (props.ptMobile ? `${props.ptMobile}px` : 0)};
// 		align-items: ${(props) => props.alignMobile};
// 	}
// `;

// export const ContentContainer = styled.div`
// 	display: flex;
// 	align-items: ${(props) => props.align || "center"};
// 	height: 100%;
// 	flex: 1;
// 	padding-bottom: ${(props) => `${props.pb}px` || 0};
// 	padding-top: ${(props) => `${props.pt}px` || 0};
// 	justify-content: ${(props) => props.justContent || "flex-start"};
// 	position: relative;

// 	@media ${device.mobileL} {
// 		justify-content: center;
// 		align-items: ${(props) => props.alignMobile};
// 		padding-bottom: ${(props) => (props.pbMobile ? `${props.pbMobile}px` : 0)};
// 		padding-top: ${(props) => (props.ptMobile ? `${props.ptMobile}px` : 0)};
// 	}

// 	.email_form {
// 		z-index: 10;
// 		display: flex;
// 		flex-direction: column;
// 		gap: 20px;
// 		justify-content: center;
// 		align-items: center;
// 		background-color: white;
// 		border-radius: 10px;
// 		padding: 30px 100px;
// 		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

// 		& form {
// 			display: flex;
// 			flex-direction: column;
// 			align-items: center;
// 			gap: 20px;

// 			& .form_heading {
// 				font-size: 24px;
// 				font-weight: 500;
// 			}
// 		}
// 	}

// 	.content-wrapper {
// 		margin-bottom: ${(props) => `${props.mb}px`};
// 		padding-left: ${(props) => `${props.pl}px`};
// 		padding-right: ${(props) => `${props.pr}px`};
// 		max-width: ${(props) => `${props.maxWidth}px`};
// 		display: flex;
// 		flex-direction: column;
// 		align-items: ${(props) => props.alignWrapper || "flex-start"};

// 		@media ${device.tablet} {
// 			margin-bottom: 0;
// 			padding-left: 0;
// 			padding-right: 0;
// 		}

// 		@media ${device.mobileL} {
// 			margin-bottom: ${(props) => `${props.mbMobile}px`};
// 			padding-left: ${(props) => `${props.plMobile}px`};
// 			padding-right: ${(props) => `${props.prMobile}px`};
// 			max-width: 100%;
// 			align-items: center;
// 		}

// 		@media ${device.mobileM} {
// 			padding-left: 0;
// 			padding-right: 0;
// 		}
// 	}

// 	.Collapsible {
// 		margin-bottom: 28px;
// 		max-width: 580px;

// 		@media ${device.laptopL} {
// 			margin-bottom: 20px;
// 		}

// 		@media ${device.laptop} {
// 			margin-bottom: 16px;
// 		}

// 		@media ${device.tablet} {
// 			margin-bottom: 12px;
// 			max-width: 450px;
// 		}

// 		@media ${device.mobileL} {
// 			max-width: 100%;
// 			padding: 0 22px;
// 		}

// 		@media ${device.mobileM} {
// 			max-width: 100%;
// 			padding: 0;
// 		}
// 	}

// 	.Collapsible__title {
// 		font-size: 20px;

// 		@media ${device.tablet} {
// 			font-size: 16px;
// 		}
// 	}
// `;

// export const FloatingImageWrapper = styled.div`
// 	max-height: 359px;
// 	padding-top: 32px;
// 	overflow: visible;
// 	z-index: 10;

// 	@media ${device.laptop} {
// 		max-height: 320px;
// 	}

// 	@media ${device.mobileL} {
// 		max-height: fit-content;
// 		padding-top: 30px;
// 	}
// `;

// export const ImageContainer = styled.div`
// 	display: flex;
// 	position: relative;
// 	padding-bottom: ${(props) => `${props.pb}px` || 0};
// 	padding-top: ${(props) => `${props.pt}px` || 0};
// 	padding-right: ${(props) => `${props.pr}px` || 0};

// 	@media ${device.tablet} {
// 		padding-bottom: ${(props) => (props.pb ? `${props.pb / 2}px` : 0)};
// 		padding-top: ${(props) => (props.pt ? `${props.pt / 2}px` : 0)};
// 	}

// 	@media ${device.mobileL} {
// 		margin-bottom: ${(props) => `${props.mbMobile}px`};
// 		padding-left: ${(props) => `${props.plMobile}px`};
// 		padding-right: ${(props) => `${props.prMobile}px`};
// 		justify-content: center;
// 		padding-bottom: ${(props) => (props.pbMobile ? `${props.pbMobile}px` : 0)};
// 		padding-top: ${(props) => (props.ptMobile ? `${props.ptMobile}px` : 0)};
// 		overflow: hidden;
// 	}
// `;

// export const BgFigure = styled.img`
// 	height: ${(props) => `${props.height}px`};
// 	width: ${(props) => `${props.width}px`};
// 	transform: ${(props) => props.transform};
// 	position: absolute;
// 	right: ${(props) => props.right};
// 	top: ${(props) => props.top};
// 	bottom: ${(props) => props.bottom};
// 	z-index: 0;

// 	@media ${device.laptop} {
// 		width: ${(props) => `${props.width * 0.8}px`};
// 	}

// 	@media ${device.mobileL} {
// 		width: 100%;
// 		padding: 0 32px;
// 	}

// 	@media ${device.mobileM} {
// 		width: 100%;
// 		padding: 0 2px;
// 	}
// `;

// export const Image = styled.img`
// 	object-fit: cover;
// 	margin-left: ${(props) => props.ml};
// 	margin-right: ${(props) => props.mr};
// 	height: ${(props) => `${props.height}px`};
// 	width: ${(props) => `${props.width}px`};

// 	@media ${device.laptop} {
// 		width: ${(props) => `${props.width * 0.8}px`};
// 	}

// 	@media ${device.mobileL} {
// 		width: ${(props) => `${props.width * 0.7}px`};
// 	}
// `;

// export const Heading = styled.h1`
// 	white-space: pre-wrap;
// 	margin-top: ${(props) => `${props.mt}px`};
// 	margin-bottom: ${(props) => `${props.mb}px`};
// 	flex: ${(props) => props.flex || null};
// 	text-align: ${(props) => props.align};
// 	max-width: ${(props) => `${props.maxWidth}px`};

// 	.paddingLeft {
// 		padding-left: 60px;

// 		@media ${device.laptop} {
// 			padding-left: 0;
// 		}
// 	}

// 	@media ${device.laptop} {
// 		font-size: 34px;
// 		line-height: 44px;
// 	}

// 	@media ${device.tablet} {
// 		font-size: 30px;
// 		line-height: 38px;
// 	}

// 	@media ${device.mobileL} {
// 		font-size: 28px;
// 		line-height: 32px;
// 		margin-top: 0;
// 		margin-bottom: 14px;
// 		text-align: ${(props) => props.textAlignMobile};
// 	}

// 	@media ${device.mobileM} {
// 		font-size: 21px;
// 		line-height: 26px;
// 	}
// `;

// export const SecondaryText = styled.h4`
// 	white-space: pre-wrap;
// 	font-weight: 400;
// 	font-size: 20px;
// 	margin-bottom: ${(props) => `${props.mb}px`};
// 	max-width: ${(props) => `${props.maxWidth}px`};

// 	@media ${device.laptopL} {
// 		font-size: 18px;
// 	}

// 	@media ${device.mobileL} {
// 		margin-bottom: 14px;
// 		text-align: ${(props) => props.textAlignMobile};
// 	}

// 	@media ${device.mobileM} {
// 		font-size: 16px;
// 		line-height: 24px;
// 	}
// `;

// export const TertiaryText = styled.h5`
// 	white-space: pre-wrap;
// 	flex: ${(props) => props.flex || null};
// 	text-align: ${(props) => props.align};
// 	margin-bottom: ${(props) => `${props.mb}px`};
// 	max-width: ${(props) => `${props.maxWidth}px`};

// 	@media ${device.mobileL} {
// 		margin-bottom: ${(props) => `${props.mbMobile}px`};
// 		text-align: ${(props) => props.textAlignMobile};
// 	}

// 	@media ${device.mobileM} {
// 		font-size: 12px;
// 		line-height: 20px;
// 	}
// `;

// export const FlexGrid = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin-right: -18px;

// 	@media ${device.mobileL} {
// 		align-items: center;
// 		flex-direction: column;
// 		padding: 0 22px;
// 	}

// 	@media ${device.mobileM} {
// 		padding: 0;
// 	}
// `;

// export const GridItem = styled.div`
// 	display: flex;
// 	flex: 1;
// 	min-width: 288px;
// 	max-width: 450px;
// 	margin-bottom: 56px;
// 	margin-right: 18px;

// 	@media ${device.mobileL} {
// 		margin-bottom: 30px;
// 		max-width: 100%;
// 	}
// `;

// export const GridCountWrapper = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	padding-right: 24px;

// 	@media ${device.tablet} {
// 		padding-right: 12px;
// 	}
// `;

// export const GridItemCount = styled.h4`
// 	font-weight: 600;
// 	font-size: 20px;
// 	margin-bottom: 6px;
// 	color: var(--blue);
// `;

// export const GridItemLabel = styled.div`
// 	width: 20px;
// 	height: 10px;
// 	clip-path: polygon(0 0, 100% 0, 75% 100%, 25% 100%);
// 	opacity: 0.3;
// 	background-color: var(--blue);
// `;

// export const TestWrapper = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	padding: 4px 10px;
// 	border-width: 2px;
// 	border-color: rgba(50, 93, 221, 0.2);
// 	border-style: solid;
// 	border-radius: 4px;
// 	margin-right: 5px;
// 	margin-bottom: 5px;
// 	cursor: pointer;

// 	&:hover {
// 		border-color: var(--blue);
// 	}

// 	@media ${device.tablet} {
// 		padding: 2px 6px;
// 	}
// `;

// export const TestText = styled.h6`
// 	font-weight: 600;
// 	text-transform: uppercase;

// 	@media ${device.tablet} {
// 		font-size: 10px;
// 		line-height: 14px;
// 	}
// `;

// export const StoryImageWrapper = styled.div`
// 	width: 100%;
// 	position: relative;
// 	border-radius: 4px;
// 	margin-bottom: 26px;
// `;

// export const StoryImage = styled.img`
// 	margin: auto;
// 	width: 100%;
// 	min-width: 292px;
// 	@media ${device.mobileL} {
// 		min-width: 260px;
// 	}
// `;

// export const StoriesContainer = styled.div`
// 	@media ${device.mobileL} {
// 		padding: 0 22px;
// 	}

// 	@media ${device.mobileM} {
// 		padding: 0;
// 	}

// 	.slick-list {
// 		margin: 0 -24px;
// 		@media (max-width: 1000px) {
// 			margin: 0 -16px;
// 		}

// 		@media ${device.mobileL} {
// 			margin: 0 auto;
// 		}
// 	}

// 	.slick-track {
// 		display: flex;
// 		margin-left: 0;
// 	}

// 	.slick-slide {
// 		padding: 24px;
// 		transition: opacity 1s;

// 		@media (max-width: 1000px) {
// 			padding: 16px;
// 		}

// 		@media ${device.mobileL} {
// 			padding: 16px 0;
// 		}

// 		*:focus {
// 			outline: none;
// 		}

// 		& > div {
// 			height: 100%;
// 			outline: none;
// 		}
// 	}

// 	.slick-arrow {
// 		position: absolute;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		z-index: 1;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		width: 40px;
// 		height: 40px;
// 		padding: 0;
// 		border: 0;
// 		margin-top: -30px;
// 		outline: 0;
// 		background-color: transparent;
// 		font-size: 0;
// 		color: #fff;
// 		opacity: 1;
// 		transition: var(--transition);

// 		@media ${device.mobileL} {
// 			visibility: hidden;
// 		}

// 		&:after {
// 			content: "";
// 			display: inline-block;
// 			width: 10px;
// 			height: 20px;
// 			background-size: 100% 100%;
// 			background-repeat: no-repeat;
// 			background-position: 50% 50%;
// 		}
// 	}

// 	.slick-prev {
// 		left: -62px;

// 		@media ${device.laptop} {
// 			left: -48px;
// 		}

// 		&:after {
// 			background-color: var(--blue);
// 			-webkit-mask: url(${ArrowUp}) no-repeat;
// 			mask: url(${ArrowUp}) no-repeat;
// 			width: 19px;
// 			transform: rotate(-90deg);
// 		}
// 	}

// 	.slick-next {
// 		right: -62px;

// 		@media ${device.laptop} {
// 			right: -48px;
// 		}

// 		&:after {
// 			background-color: var(--blue);
// 			-webkit-mask: url(${ArrowUp}) no-repeat;
// 			mask: url(${ArrowUp}) no-repeat;
// 			width: 19px;
// 			transform: rotate(90deg);
// 		}
// 	}

// 	.slick-disabled {
// 		pointer-events: none;
// 		&:after {
// 			background-color: var(--text-color);
// 			opacity: 0.2;
// 		}
// 	}

// 	.slick-dots {
// 		list-style-type: none;
// 		display: flex !important;
// 		justify-content: center;
// 		align-items: center;
// 		padding: 0;
// 		margin: 10px 0 0;
// 		font-size: 0;

// 		li {
// 			padding: 0 6px;
// 			opacity: 0.4;
// 			transition: opacity 0.3s;
// 		}

// 		.slick-active {
// 			opacity: 1;

// 			button {
// 				color: var(--blue);
// 				width: 12px;
// 				height: 12px;
// 				padding: 0 0;
// 			}
// 		}

// 		button {
// 			display: inline-block;
// 			vertical-align: middle;
// 			width: 6px;
// 			height: 6px;
// 			padding: 0 0;
// 			border: 0;
// 			border-radius: 50%;
// 			outline: 0;
// 			background: var(--blue);
// 			appearance: none;
// 		}
// 	}
// `;

// export const FooterNavContainer = styled(NavContainer)`
// 	align-items: space-between;
// 	justfiy-content: space-between;
// 	margin-bottom: 20px;
// `;
