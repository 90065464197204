import styled from 'styled-components';

export const Modal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 30px;
	background: rgba(255,255,255, .3);

	.modal__dialog {
		position: relative;
		overflow: inherit;
		width: 536px;
		min-height: 300px;
		border-radius: 4px;
		background: #fff;
		box-shadow: 6px 6px 54px 0 rgba(0,0,0, .3);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 5px;
			background: ${props => props.color || 'var(--danger)'};
		}
	}

	.modal__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 10px;
		border: 0;
		outline: 0;
		background: transparent;
		font-size: 0;
		cursor: pointer;
		appearance: none;
	}
`;
