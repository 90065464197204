import styled from 'styled-components';

export const Form = styled.form`
	flex-shrink: 0;
	width: 100%;
	max-width: 534px;
	margin: 0 auto;
`;

export const FormTitle = styled.h1`
	margin: 0 0 39px;
`;

export const FormRow = styled.div`
	margin-bottom: 30px;
`;

export const FormHint = styled.div`
	margin: 8px 0;
	font-size: 14px;
	text-align: right;
`;

export const FormMessage = styled.div`
	min-height: 32px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
	color: ${props => props.color || 'var(--text-color)'};
`;

export const FormActions = styled.div``;

export const FormFooter = styled.div`
	margin-top: 32px;
	font-size: 14px;
`;
