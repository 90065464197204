import { createGlobalStyle, css } from "styled-components";

const size = {
	mobileS: "320px",
	mobileM: "460px",
	mobileL: "800px",
	tablet: "968px",
	laptop: "1215px",
	laptopL: "1280px",
	desktop: "1500px",
};

export const media = {
	xs: (...args) => css`
		@media (max-width: ${size.mobileS}) {
			${css(...args)};
		}
	`,
	sm: (...args) => css`
		@media (max-width: ${size.mobileM}) {
			${css(...args)};
		}
	`,
	md: (...args) => css`
		@media (max-width: ${size.mobileL}) {
			${css(...args)};
		}
	`,
	lg: (...args) => css`
		@media (max-width: ${size.tablet}) {
			${css(...args)};
		}
	`,
	xl: (...args) => css`
		@media (max-width: ${size.laptop}) {
			${css(...args)};
		}
	`,
	xxl: (...args) => css`
		@media (max-width: ${size.laptopL}) {
			${css(...args)};
		}
	`,
	xxxl: (...args) => css`
		@media (max-width: ${size.desktop}) {
			${css(...args)};
		}
	`,
};

export default createGlobalStyle`
	:root {
		--blue: #325CDD;
		--blue-dark: #1F3B8F;
		--cyan: #69C9C9;
		--yellow: #F5BC01;
		--danger: #FF678C;
		--danger-dark: #FC0C47;
		--success: #7DE0E0;
		--success-dark: #69C9C9;
		--text-color: #383838; //rgb(56, 56, 56)
		--border-color: rgba(56, 56, 56, .1);
		--shadow: 0 2px 24px rgba(0, 0, 0, .04);
		--transition: .4s;
	}

	*, *:before, *:after {
		box-sizing: border-box;
	}

	.mg-bottom-4 {margin-bottom: 4px}
	.mg-bottom-8 {margin-bottom: 8px}
	.mg-bottom-12 {margin-bottom: 12px}
	.mg-bottom-16 {margin-bottom: 16px}
	.mg-bottom-24 {margin-bottom: 24px}
	.mg-bottom-32 {margin-bottom: 32px}
	.mg-bottom-40 {margin-bottom: 40px}

	.mg-left-4 {margin-left: 4px}
	.mg-left-8 {margin-left: 8px}
	.mg-left-12 {margin-left: 12px}
	.mg-left-16 {margin-left: 16px}
	.mg-left-24 {margin-left: 24px}
	.mg-left-32 {margin-left: 32px}
	.mg-left-40 {margin-left: 40px}

	.pd-bottom-4 {padding-bottom: 4px}
	.pd-bottom-8 {padding-bottom: 8px}
	.pd-bottom-12 {padding-bottom: 12px}
	.pd-bottom-16 {padding-bottom: 16px}
	.pd-bottom-24 {padding-bottom: 24px}
	.pd-bottom-32 {padding-bottom: 32px}
	.pd-bottom-40 {padding-bottom: 40px}

	.pd-top-4 {padding-top: 4px}
	.pd-top-8 {padding-top: 8px}
	.pd-top-12 {padding-top: 12px}
	.pd-top-16 {padding-top: 16px}
	.pd-top-24 {padding-top: 24px}
	.pd-top-32 {padding-top: 32px}
	.pd-top-40 {padding-top: 40px}

	.pd-left-4 {padding-left: 4px}
	.pd-left-8 {padding-left: 8px}
	.pd-left-12 {padding-left: 12px}
	.pd-left-16 {padding-left: 16px}
	.pd-left-24 {padding-left: 24px}
	.pd-left-32 {padding-left: 32px}
	.pd-left-40 {padding-left: 40px}
	.pd-left-50 {padding-left: 50px}
	.pd-left-60 {padding-left: 60px}

	.pd-right-4 {padding-right: 4px}
	.pd-right-8 {padding-right: 8px}
	.pd-right-12 {padding-right: 12px}
	.pd-right-16 {padding-right: 16px}
	.pd-right-24 {padding-right: 24px}
	.pd-right-28 {padding-right: 28px}
	.pd-right-32 {padding-right: 32px}
	.pd-right-40 {padding-right: 40px}
	.pd-right-60 {padding-right: 60px}


	.flex-row {display: flex;}
	.flex-column {display: flex; flex-direction: column;}
	.align-flex-start {align-items: flex-start}
	.align-flex-end {align-items: flex-end}
	.just-flex-start {justify-content: flex-start}
	.just-flex-end {justify-content: flex-end}
	.align-middle {align-items: center}
	.just-middle {justify-content: center}
	.min-content-height {min-height: min-content}

	.flex-45 {flex: 45}
	.flex-50 {flex: 45}
	.flex-65 {flex: 65}
	.flex-100 {flex: 1}

	.absolute {position: absolute}
	.relative {position: relative}

	

	body {
		font-family: 'Fira Sans', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.375;
		color: var(--text-color);
	}

	h1 {
		margin-bottom: 24px;
		font-size: 40px;
		font-weight: 500;
		line-height: 50px;
	}

	h2 {
		font-size: 30px;
		font-weight: 500;
		margin: 0;
	}

	h3 {
		margin-bottom: 16px;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.35;
		margin: 0;
		color: var(--text-color);
	}

	h4 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
		color: var(--text-color);
		white-space: 'pre-wrap';
	}

	h5 {
		font-size: 14px;
		font-weight: 400;
		margin: 0;
		color: var(--text-color);
		white-space: 'pre-wrap';
	}

	h6 {
		font-size: 12px;
		font-weight: 400;
		margin: 0;
		color: var(--text-color);
		white-space: 'pre-wrap';
	}

	strong {
		font-weight: 500;
	}

	img {
		max-width: 100%;
	}

	a,
	button {
		cursor: pointer;
	}

	.link {
		display: inline-flex;
		align-items: center;
		border: 0;
		background: transparent;
		color: var(--blue);
		text-decoration: none;
		transition: color var(--transition);

		&:hover {
			color: var(--blue-dark);
		}
	}

	.link-default {
		font-size: 14px;
		font-weight: 600;
		color: var(--text-color);
		text-decoration: underline;
		transition: color var(--transition);

		&:hover {
			color: var(--blue);
		}

		svg {
			display: inline-block;
			vertical-align: middle;
			margin-left: 8px;
		}
	}

	.menu-link {
		border: 0;
		outline: 0;
		background: transparent;
		font-size: 14px;
		color: var(--text-color);
		text-decoration: underline;
		text-decoration: none;
		transition: color var(--transition);
		width: 100%;
		margin-bottom: 12px;
	}

	.link-back {
		border: 0;
		outline: 0;
		background: transparent;
		font-size: 14px;
		color: var(--text-color);
		text-decoration: underline;
		text-decoration: none;
		transition: color var(--transition);

		&:hover {
			border: 0;
			color: var(--blue);
		}

		strong {
			font-weight: 600;
		}

		svg {
			display: inline-block;
			vertical-align: middle;
			margin-top: -2px;
			margin-right: 8px;
		}
	}

	.btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 7.5px 16px;
		border: 1px solid var(--blue);
		border-radius: 7px;
		outline: 0;
		background: var(--blue);
		font-weight: 500;
		color: #fff;
		text-decoration: none;
		transition: background var(--transition), color var(--transition);
		appearance: none;

		@media ${size.mobileM} { 
			font-size: 12px;
  		}

		&:hover {
			background: var(--blue-dark);
			border-color: var(--blue-dark);
			color: #fff;
		}

		&:focus {
			outline: 0;
		}

		svg {
			display: inline-block;
			vertical-align: middle;
		}

		&--block {
			display: flex;
			width: 100%;
			padding-top: 16px;
			padding-bottom: 16px;
			border-radius: 10px;
		}

		&--outline {
			background: transparent;
			border-width: 2px;
			color: var(--blue);

			&:hover {
				color: #fff;
			}
		}

		&--danger {
			border-color: var(--danger);
			background: var(--danger);
			color: #fff;

			&:hover {
				border-color: var(--danger-dark);
				background: var(--danger-dark);
			}
		}
	}

	.select {
		select {
			display: block;
			width: 100%;
			background-color: transparent;
			background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTggMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+U3Ryb2tlIDI8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iNS4tRGV0YWlsZWQtdmlldyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMzguMDAwMDAwLCAtMTYzLjAwMDAwMCkiIHN0cm9rZT0iIzM4MzgzOCIgc3Ryb2tlLXdpZHRoPSIxLjA5MDkwOTA5Ij4KICAgICAgICAgICAgPGcgaWQ9InBhY2stdGl0bGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwNS4wMDAwMDAsIDE0NC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzb3J0LWJ5LXR5cGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcyNi4wMDAwMDAsIDEwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJTdHJva2UtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjE2LjAwMDAwMCwgMTQuMDAwMDAwKSByb3RhdGUoOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTIxNi4wMDAwMDAsIC0xNC4wMDAwMDApIHRyYW5zbGF0ZSgyMTIuMDAwMDAwLCA2LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlN0cm9rZS0xIiBwb2ludHM9Ii0yLjk3MTM2MDUzZS0xNCAwIDcuODc4NzYzNDUgOC4wMDAyMzAzMyAtMi45NzEzNjA1M2UtMTQgMTYiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
		  background-repeat: no-repeat, repeat;
		  background-position: right 0 top 50%, 0 0;
		  background-size: 1em .5em, 100%;
		  padding: 4px 18px 4px 0;
			border: 0;
			border-bottom: 1px solid var(--border-color);
			font-size: 16px;
			font-weight: 500;
			box-shadow: none;
			color: currentColor;
			cursor: pointer;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			transition: color .3s;

			&::-ms-expand {
				display: none;
			}

			&:hover {
				color: var(--blue);
			}

			&:focus {
				outline: none;
			}

			option {
				font-weight: normal;
			}
		}
	}

	.label {
		display: inline-block;
		vertical-align: middle;
		padding: 6px 15px;
		border: 1px solid currentColor;
		border-radius: 8px;
		font-size: 16px;
		font-weight: 400;
		color: var(--blue);
		text-align: center;
	}
`;
