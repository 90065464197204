import iconDiamondSrc from '@/assets/images/ico-diamond.svg';
import iconMagnifierSrc from '@/assets/images/ico-magnifier.svg';

// Return icon url based on package type
export const setIconByPackageType = type => {
	switch (type) {
		case 'common':
			return iconMagnifierSrc;
		case 'specialized':
			return iconDiamondSrc;
		default:
			return iconMagnifierSrc;
	}
}
