import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SIGN_OUT } from '@/ducks/auth';

import reducers from '@/ducks';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'sharedResults']
}

// Clear persist, redux store on SING_OUT
const rootReducer = (state, action) => {
	if (action.type === SIGN_OUT) {
		localStorage.removeItem('persist:root')

		state = undefined;
	}

	return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
