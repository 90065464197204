import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "@/components/history";
import PrivateRoute from "@/components/PrivateRoute";
import { LandingPage, AuthPage, SurveyPage, DashboardPage, SharedResultPage, /*NotFound*/ } from "../pages";
import { QuestionnaireProvider } from "./questionnaire/QuestionnaireContext";
import { Wrapper } from "./styles";
const App = () => {
	return (
		<Router history={history}>
			<Wrapper className="wrapper">
				<Switch>
					<Route path="/" exact component={LandingPage} />
					<Route path="/shared-result/:id" component={SharedResultPage} />
					<Route path="/auth" component={AuthPage} />
					<QuestionnaireProvider>
						<PrivateRoute path="/survey" component={SurveyPage} />
						<PrivateRoute path="/dashboard" component={DashboardPage} />
					</QuestionnaireProvider>
					{/* <Route path="*" component={NotFound} /> */}
				</Switch>
			</Wrapper>
		</Router>
	);
};

export default App;
