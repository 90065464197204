// Biomarkers Ducks
import { api } from '@/apis';

// Actions Types
const FETCH_LABORATORIES = 'laboratories/FETCH_LABORATORIES';

// Action Creators
export const fetchLaboratories = () => async dispatch => {
	const response = await api.get('/laboratories');

	dispatch({
		type: FETCH_LABORATORIES,
		payload: response.data
	});
}

const initState = {
	list: []
};

// Reducers
export default function reducer (state = initState, action = {}) {
	switch (action.type) {
		case FETCH_LABORATORIES:
			return { ...state, list: action.payload }

		default:
			return state;
	}
}
