import React from 'react';

import {
	Content,
	ContentInner,
	Container,
} from './styles';

const DashboardContent = ({children}) => {
	return (
		<Content>
			<ContentInner>
				<Container>
					{children}
				</Container>
			</ContentInner>
		</Content>
	);
}

export default DashboardContent;
