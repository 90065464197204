import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserInfo } from "@/ducks/user";
import { fetchUserPackages } from "@/ducks/userPackages";
import { fetchCurrentUserDetails } from "@/ducks/currentUserDetails";
import { fetchFamily } from "@/ducks/family";

import DashboardSidebar from "@/components/dashboard/DashboardSidebar";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import DashboardContent from "@/components/dashboard/DashboardContent";

import DashboardOverview from "@/components/dashboard/DashboardOverview";
import Examinations from "@/components/examinations/examinations";
import Protocols from "@/components/protocols/protocols";
import UserPackagesDetail from "@/components/packages/UserPackagesDetail/UserPackagesDetail";
import Profile from "@/components/profile/Profile/Profile";
import Questionnaire from "@/components/questionnaire/Questionnaire";
import Admin from "@/components/admin/admin";
import AdminUserProfile from "@/components/admin/users/UserProfile";
import PrivateAdmin from "@/components/PrivateAdmin";
import GDPRFooter from "@/components/GDPRFooter/GDPRFooter";

const DashboardPage = (props) => {
	const { fetchUserInfo, fetchUserPackages, fetchFamily } = props;
	let { path } = useRouteMatch();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		let promises = [];

		// Load necessary data for all elements inside Dashboard
		promises.push(fetchUserInfo());
		promises.push(fetchUserPackages());
		promises.push(fetchCurrentUserDetails());
		promises.push(fetchFamily());

		Promise.all(promises).then((r) => {
			setIsLoading(false);
		});

		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<DashboardHeader />
			<DashboardSidebar />

			{!isLoading && (
				<DashboardContent>
					<Switch>
						<Route path={path} exact component={DashboardOverview} />
						<Route
							path={`${path}/examinations`}
							exact
							component={Examinations}
						/>
						<Route path={`${path}/protocols`} exact component={Protocols} />
						<Route
							path={`${path}/user-packages/:id`}
							exact
							component={UserPackagesDetail}
						/>
						<Route path={`${path}/profile`} exact component={Profile} />
						<Route
							path={`${path}/questionnaire`}
							exact
							component={Questionnaire}
						/>
						{/* <PrivateFamily path={`${path}/family`} component={Family} /> */}
						<PrivateAdmin path={`${path}/admin`} exact component={Admin} />
						<PrivateAdmin
							path={`${path}/admin/:id`}
							exact
							component={AdminUserProfile}
						/>
					</Switch>
					<GDPRFooter />
				</DashboardContent>
			)}
		</React.Fragment>
	);
};

export default connect(null, {
	fetchUserInfo,
	fetchUserPackages,
	fetchCurrentUserDetails,
	fetchFamily,
})(DashboardPage);
