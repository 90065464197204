import styled from 'styled-components';

export const ChartContainer = styled.div`
  position: relative;

  .chart-biomarker__inner {
    position: relative;
  }

  .chart-biomarker__bar-ref {
    position: absolute;
    top: 46px;
    left: 40px;
    bottom: 40px;
    width: 1.5px;
    background: #e2e2e6;
  }
`;