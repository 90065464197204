const processStepsData = [
	{
		name: "Initial consultation",
		description: "Set your goals with a specialist",
	},
	{
		name: "Questionnaire",
		description: "Fill out a questionnaire about your lifestyle",
	},
	{ name: "Genetic & blood tests", description: "Upload your results" },
	{
		name: "Analyses & protocols",
		description: "Get personalized science - based advice",
	},
	{
		name: "Progress",
		description: "Track and update your protocols with our longevity team",
	},
];

export default processStepsData;
