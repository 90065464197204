import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from "react";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { api } from "@/apis";
import parseData from "./dataParser";
import { themeJson } from "./theme";
import {
	QuestionnaireIntroContainer,
	IntroHeading,
} from "./questionnaireStyles.js";
import QuestionnaireContext from "./QuestionnaireContext.js";
import { useHistory, useLocation } from "react-router-dom";
settings.lazyRowsRendering = true;
const SurveyComponent = ({ json }) => {
	const [loaded, setLoaded] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const { isExistingQuestionnaire } = useContext(QuestionnaireContext);
	const location = useLocation();
	const history = useHistory();
	const survey = useMemo(() => new Model(json), []);
	survey.applyTheme(themeJson);
	// --> restore data if there is an existing survey
	const restoreSurveyData = useCallback(
		(data) => {
			if (!data) return;
			//  --> female data ajustment if data avaiable
			let femaleHistoryDateAdjustment =
				data?.femaleHistory?.dayOfLastCycle.slice(0, 10);

			//  --> flatten to primitive object
			function flattenObject(ob) {
				let toReturn = {};

				for (let i in ob) {
					if (!ob.hasOwnProperty(i)) continue;

					if (
						typeof ob[i] === "object" &&
						!Array.isArray(ob[i]) &&
						ob[i] !== null
					) {
						let flatObject = flattenObject(ob[i]);
						for (let x in flatObject) {
							if (!flatObject.hasOwnProperty(x)) continue;
							toReturn[i + "." + x] = flatObject[x];
						}
					} else {
						toReturn[i] = ob[i];
					}
				}
				return toReturn;
			}
			const flatten = flattenObject(data);

			// --> set survey data
			survey.data = flatten;
			survey.getQuestionByName("gender").readOnly = true;
			survey.getQuestionByName("birthDate").readOnly = true;

			// --> handle display and edit of disorders
			function extractFamilyDisorders(survey, familyMember) {
				console.log('here');
				const disorders = [];
				const familyKey = `family.${familyMember}.disorders.`;
				const familyCustomKey = `family.${familyMember}.disorders.other`;

				for (const key in survey.data) {
					if (
						survey.data.hasOwnProperty(key) &&
						key.startsWith(familyKey) &&
						survey.data[key] === true
					) {
						const disorderName = key.replace(familyKey, "");
						disorders.push(disorderName);
					} else if (
						survey.data.hasOwnProperty(key) &&
						key.startsWith(familyCustomKey) &&
						survey.data[key]?.length > 0
					) {
						survey.setValue(
							`family.${familyMember}.disorders-Comment`,
							survey.data[key]
						);
						disorders.push("other");
					}
				}
				if (disorders.length === 0) {
					disorders.push("none");
				}
				survey.setValue(`family.${familyMember}.disorders`, disorders);
			}

			const familyMembers = [
				"mother",
				"father",
				"paternalGrandfather",
				"paternalGrandmother",
				"maternalGrandmother",
				"maternalGrandfather",
			];

			for (const member of familyMembers)
				extractFamilyDisorders(survey, member);

			// --> Gender check
			const genderFromAPI = data.gender === "female" ? true : false;
			survey.setValue("gender", genderFromAPI);
			if (genderFromAPI) {
				survey.setValue(
					"femaleHistory.dayOfLastCycle",
					femaleHistoryDateAdjustment
				);
			}

			// --> Exercise check
			const ExerciseTypes = [
				"aerobicExercise",
				"strengthExercise",
				"stabilityExercise",
				"highIntensityExercise",
				"stretchingExercise",
			];

			function setExerciseDetailsToEmptyIfFalse(survey, exerciseType) {
				if (survey.data[`physicalActivity.${exerciseType}.has`] === false) {
					survey.setValue(`physicalActivity.${exerciseType}.details`, " ");
				}
			}
			if (survey.data["physicalActivity.exercisePerWeek.has"] === false) {
				ExerciseTypes.forEach((exerciseType) => {
					survey.setValue(`physicalActivity.${exerciseType}.has`, false);
					survey.setValue(`physicalActivity.${exerciseType}.details`, " ");
				});
				survey.setValue("physicalActivity.exercisePerWeek.details", " ");
			}
			ExerciseTypes.forEach((exerciseType) => {
				setExerciseDetailsToEmptyIfFalse(survey, exerciseType);
			});
			setLoaded(true);
		},
		[]
	);

	const sendResult = async (data) => {
		try {
			if (isExistingQuestionnaire) {
				await api.patch("/questionnaire", data);
			} else {
				await api.post("/questionnaire", data);
			}

			if (location.pathname === "/survey") {
				history.replace("/dashboard");
			}

			window.location.reload();
		} catch (error) {
			console.error("Error sending data to the API:", error);
		}
	};

	useEffect(() => {
		api.get("/questionnaire")
			.then(resp => restoreSurveyData(resp.data))
			.catch((error) => {
				console.error("Error getting data: ", error);
			})

		// ---> Addictional Fields check
		survey.onValueChanged.add(function (sender, options) {
			if (options.name === "medications" && options.value === "") {
				options.question.value = "None";
			}
			if (options.name === "historyIllness.other" && options.value === "") {
				options.question.value = "None";
			}
			if (options.name === "diagnosedIllness.other" && options.value === "") {
				options.question.value = "None";
			}
		});

		// --> unflatten Check if exists and send to the api
		survey.onComplete.add(async (sender, options) => {
			const skipPrefixes = [
				"family.mother.disorders.",
				"family.father.disorders.",
				"family.maternalGrandfather.disorders.",
				"family.maternalGrandmother.disorders.",
				"family.paternalGrandmother.disorders.",
				"family.paternalGrandfather.disorders.",
			];

			function unflatten(data, skipPrefixes = []) {
				let result = {};

				for (let i in data) {
					// eslint-disable-next-line no-loop-func
					let shouldSkip = skipPrefixes.some((prefix) => i.startsWith(prefix));

					if (!shouldSkip) {
						let keys = i.split(".");
						// eslint-disable-next-line no-loop-func
						keys.reduce(function (r, e, j) {
							return (
								r[e] ||
								(r[e] = isNaN(Number(keys[j + 1]))
									? keys.length - 1 === j
										? data[i]
										: {}
									: [])
							);
						}, result);
					}
				}
				return result;
			}
			const readyAPIData = unflatten(sender.data, skipPrefixes);

			const parsedData = parseData(readyAPIData);

			await sendResult(parsedData);
		});
	}, []);

	return (
		<>
			{isExistingQuestionnaire ? (
				!loaded ? <>Loading</> :
					<Survey model={survey} />
			) : (
				<div>
					<QuestionnaireIntroContainer active={isShown ? true : false}>
						<IntroHeading>One more step ...</IntroHeading>
						<IntroHeading>Please fill the Questionnaire</IntroHeading>
						<button onClick={() => setIsShown(true)} className="btn">
							Show the questionnaire
						</button>
					</QuestionnaireIntroContainer>
					<Survey model={survey} />
				</div>
			)}
		</>
	);
};

export default SurveyComponent;
