// Auth Ducks
import dayjs from "@/components/configureDayjs";

// Biomarkers Ducks
import { api } from "@/apis";

// Actions Types
const FETCH_SHARED_RESULT = "sharedResults/FETCH_SHARED_RESULT";

export const fetchSharedResult = (id) => async (dispatch) => {
	const response = await api.get(`/shared-result/${id}`);
	dispatch({
		type: FETCH_SHARED_RESULT,
		payload: response.data,
		id,
	});
};

const initState =
	{list: []};

// Reducers
export default function reducer(state = initState, action = {}) {
	switch (action.type) {
		case FETCH_SHARED_RESULT:
			//update state if there's no info about this package or the back-end cookie has expired
			let isExpired = false;
			
			if(state.list[action.id])
				isExpired = dayjs().diff(dayjs(state.list[action.id].expires)) > 0

			if (!state.list[action.id] || isExpired) {
				return {
					...state,
					list: {
						...(state?.list ? state.list : {}),
						[action.id]: {
							results: action.payload,
							expires: dayjs().add(5, "d").valueOf(),
						},
					},
				};
			} else {
				return state;
			}
		default:
			return state;
	}
}
