import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "@/apis";

const QuestionnaireContext = createContext();

export const QuestionnaireProvider = ({ children }) => {
	const history = useHistory();
	const [isExistingQuestionnaire, setIsExistingQuestionnaire] = useState(false);
	const [hasRedirected, setHasRedirected] = useState(false);

	useEffect(() => {
		const checkExistingQuestionnaire = async () => {
			try {
				const response = await api.get("/questionnaire");
				if (response.status === 200) {
					setIsExistingQuestionnaire(true);
				}
			} catch (error) {
				if (error.response && error.response.status === 404) {
					setIsExistingQuestionnaire(false);
					if (!hasRedirected) {
						history.replace("/survey");
						setHasRedirected(true);
					}
				} else {
					console.log("Failed to get existing questionnaire", error.response);
				}
			}
		};

		checkExistingQuestionnaire();
	}, [hasRedirected, history]);

	return (
		<QuestionnaireContext.Provider
			value={{ isExistingQuestionnaire, setIsExistingQuestionnaire }}
		>
			{children}
		</QuestionnaireContext.Provider>
	);
};

export default QuestionnaireContext;
