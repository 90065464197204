import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

function PrivateAdmin({ user, component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				user.role === "admin" ? (
					<Component {...props} />
				) : (
					<p>Нямате достъп</p>
				)
			}
		/>
	);
}

export default connect((state) => ({
	user: state.user,
}))(PrivateAdmin);
