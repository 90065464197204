import styled from 'styled-components';

export const Wrapper = styled.div`
	overflow: hidden;
	min-height: 100vh;

	.modal-opened & {
		filter: blur(10px);
	}
`
