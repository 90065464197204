import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addFamilyMember } from "@/ducks/family";

import FormInput from "@/components/FormElements/FormInput";
import Datepicker from "@/components/FormElements/Datepicker";

import { ModalContent, FormMessage } from "./styles";

function AddMember({ closeModal }) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [birthDate, setBirthDate] = useState(new Date());
	const [gender, setGender] = useState("");
	const [errors, setErrors] = useState("");
	
	const dispatch = useDispatch();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (new Date(birthDate) > new Date()) {
			setErrors({ message: "Невалидна дата" });
			return;
		}
		if (
			firstName === "" ||
			lastName === "" ||
			gender === "" ||
			birthDate === ""
		) {
			setErrors({ message: "Моля попълнете всички полета" });
			return;
		}

		dispatch(
			addFamilyMember({
				firstName,
				lastName,
				medicalProfile: {
					birthDate,
					gender,
				},
			})
		);

		closeModal();
	};

	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return <p key={i}>{error}</p>;
			});
		}
	};

	return (
		<ModalContent>
			<div className="modal__content">
				<h2 className="modal__title">Добави член на семейството</h2>
				<form className="modal__form" onSubmit={handleSubmit}>
					<div className="form__names">
						<div className="form__single">
							<FormInput
								label="Име"
								name="firstname"
								type="firstname"
								placeholder="Иван"
								value={firstName}
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
							/>
						</div>
						<div className="form__single">
							<FormInput
								label="Фамилия"
								name="lastname"
								type="lastname"
								placeholder="Иванов"
								value={lastName}
								onChange={(e) => {
									setLastName(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="form__names">
						<div className="form__single">
							<Datepicker
								label="Рождена дата"
								name="birthDate"
								type="text"
								placeholder="20/10/1990"
								value={birthDate}
								onChange={(date) => {
									setBirthDate(date);
								}}
								tooltipMessage="Рождената дата е нужна за правилното определяне на референтните стойности на биомаркерите."
							/>
						</div>
						<div className="form__single">
							<FormInput
								label="Пол"
								name="gender"
								placeholder="Пол"
								value={gender}
								options={[
									{ value: "", label: "Избери пол" },
									{ value: "male", label: "Мъж" },
									{ value: "female", label: "Жена" },
								]}
								onChange={(e) => {
									setGender(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="form__actions">
						<button className="btn" type="submit">
							Създай
						</button>
						<FormMessage color="var(--danger)">{renderErrors()}</FormMessage>
					</div>
				</form>
			</div>
		</ModalContent>
	);
}

export default AddMember;
