import React from "react";
import Questionnaire from "@/components/questionnaire/Questionnaire";
import { SurveyPageContainer } from "./SurveyPageStyles";
import { json } from "../../components/questionnaire/questionnaireData";
const SurveyPage = () => {
	return (
		<SurveyPageContainer>
			<Questionnaire json={json} />
		</SurveyPageContainer>
	);
};

export default SurveyPage;
