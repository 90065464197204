import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Header = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
	display: flex;
	width: 100%;
	height: 76px;
	background: #fff;
	box-shadow: var(--shadow);

	@media (max-width: 1024px) {
		justify-content: center;
	}
`;
export const HeaderLogo = styled.div`
	flex-shrink: 0;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
	padding: 10px 20px;
	border-right: 1px solid var(--border-color);

	@media (max-width: 1024px) {
		border-right: none;
	}
`;
export const HeaderContent = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 10px 32px;
	text-align: right;

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const Sidebar = styled.div`
	flex-shrink: 0;
	position: fixed;
	top: 76px;
	left: 0;
	z-index: 10;
	width: 180px;
	height: calc(100vh - 78px);
	background: #fff;
	box-shadow: var(--shadow);

	@media (max-width: 1024px) {
		transform: ${({ isOpen }) =>
			isOpen ? "translateX(0)" : "translateX(-100%)"};
		top: 0;
		z-index: 9999;
		height: 100vh;
		transition: transform 0.3s ease-in-out;
	}
`;
export const SidebarToggle = styled.button`
	display: none;
	position: fixed;
	top: 20px;
	right: 20px;
	width: 40px;
	height: 40px;
	background: #0000;
	border: none;
	z-index: 11;
	font-size: 20px;
	padding-left: 20px;

	@media (max-width: 1024px) {
		display: block;
		transform: ${({ isOpen }) =>
			isOpen ? "translateX(20%)" : "translateX(150%)"};
		transition: transform 0.3s ease-in-out;
	}
`;
export const SidebarProfile = styled.div`
	padding: 30px 15px;
	border-bottom: 1px solid var(--border-color);
`;
export const SidebarNav = styled.ul`
	list-style-type: none;
	padding: 30px 0;
	margin: 0;
	a {
		text-decoration: none;
	}

	@media (max-width: 1024px) {
		padding: 20px 0 30px 0;
	}
`;
export const SidebarNavItem = styled.li`
	margin-bottom: 16px;
`;
export const SidebarNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 5px 20px;
	font-size: 14px;
	font-weight: 500;
	color: rgba(56, 56, 56, 0.5);
	transition: color 0.3s;
	width: 90%;
	margin-left: 0.5rem;

	&:hover {
		color: rgba(56, 56, 56, 1);
	}

	&.active {
		background-color: var(--blue);
		border-radius: 7px;
		color: #ffffff;
	}
`;
export const SidebarNavLinkIcon = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-right: 15px;
	font-size: 0;
`;
export const SidebarAddResults = styled.div`
	padding: 5px 20px;
	margin-top: 20px;

	@media (min-width: 1024px) {
		display: none;
	}
`;
export const SidebarBottom = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px 20px;
	border-top: 1px solid var(--border-color);
	text-align: center;
	color: var(--danger);

	.btn-backtomain {
		background-color: var(--danger);
		border-color: var(--danger);

		&:hover {
			background-color: var(--danger-dark);
			border-color: var(--danger-dark);
		}
	}
`;

export const Content = styled.div`
	height: 100vh;
	padding: 78px 0 0 180px;
	background: #fcfcfc;

	@media (max-width: 1024px) {
		padding: 78px 0 0 0;
	}
`;
export const ContentInner = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 78px);
	padding: 24px;
`;

export const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;

export const NoResults = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	gap: 40px;

	.noresults__img {
		width: 70%;
	}

	.noresults__title {
		text-align: center;
	}
`;

export const Biomarkers = styled.section`
	.package__table__head {
		padding: 0 62px 8px 24px;
	}

	.package__table__row {
		display: flex;
	}

	.package__table__col {
		width: 22%;

		&.small {
			width: 14%;
		}

		&.medium {
			width: 24%;
		}

		&.large {
			width: 26%;
		}
	}

	.package__table__label {
		font-size: 12px;
	}

	.filters {
		margin: 0;
	}

	@media screen and (max-width: 768px) {
		.package__table {
			display: none;
		}
	}
`;
