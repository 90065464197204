import React from "react";
import styled from "styled-components";

import AestheticScienceLogo from "@/assets/images/AestheticScienceLogo.png";
import BodimedLogo from "@/assets/images/BodimedLogo.png";
import NordiclabLogo from "@/assets/images/nordic-lab-logo.png";
import { ContentContainer, SlideUp } from "./styles";
import useIntoView from "../../hooks/useIntoView";
import { useRef } from "react";
import { media } from "../../GlobalStyles";

const CustomersBarContainer = styled(ContentContainer)`
	margin-top: 120px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
	${media.md`
		margin-top:32px;
	`}
`;

const Logo = styled.img`
	opacity: 0.5;
	height: ${({ height }) => (height ? `${height}px` : "60px")};

	&:hover {
		opacity: 1;
	}
	${media.md`
		scale: 0.7;
	`}
`;

const Customers = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);

	return (
		<SlideUp seen={seen} ref={observeEl}>
			<CustomersBarContainer>
				<Logo height={90} src={NordiclabLogo} alt="NordicLab" />
				<Logo src={BodimedLogo} alt="BODIMED" />
				<Logo src={AestheticScienceLogo} alt="Aesthetic Science" />
			</CustomersBarContainer>
		</SlideUp>
	);
};

export default Customers;
