import React from "react";
import { Link } from "react-router-dom";
import Pin from "@/components/shareResults/Pin/Pin";
import { LogoPng } from "@/assets";
import { ContentHeader, ContentBody, PinBody } from "./styles";

const SharedResultPage = () => {
	return (
		<PinBody>
			<ContentHeader>
				<Link to="/">
					<img src={LogoPng} alt="NeoLife лого" />
				</Link>
			</ContentHeader>

			<ContentBody>
				<Pin />
			</ContentBody>
		</PinBody>
	);
};

export default SharedResultPage;
