import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '@/apis';
import FormInput from '@/components/FormElements/FormInput';

import {
	Form,
	FormTitle,
	FormRow,
	FormActions,
	FormFooter,
	FormMessage,
} from './styles';

const RequestResetPasswordForm = props => {
	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState('');
	const [hasError, setHasError] = useState(false);
	const [isEmailSent, setIsEmailSent] = useState(false);

	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return (
					<p key={i}>{error}</p>
				);
			});
		}
	}

	const onSubmit = (event) => {
		event.preventDefault();

		api.post('/users/password/request-reset', {
			email,
		})
		.then(response => {
			setHasError(false);
			setIsEmailSent(true);
		})
		.catch((e) => {
			setHasError(true);
			setErrors(e.response.data.errors);
		})
	}

	return (
		<Form onSubmit={onSubmit}>
			<FormTitle>Забравена парола</FormTitle>

			<FormRow>
				<FormInput
					label="Въведи имейл адрес"
					name="email"
					type="email"
					placeholder="you@gmail.com"
					value={email}
					required
					onChange={e => {setEmail(e.target.value)}}
				/>
			</FormRow>

			{ hasError &&
				<FormMessage color="var(--danger)">
					{renderErrors()}
				</FormMessage>
			}

			{ isEmailSent &&
				<FormMessage color="var(--success-dark)">
					<p>Изпратихме ви имейл с инструкции за презаписване на паролата!</p>
				</FormMessage>
			}

			<FormActions>
				<button className="btn btn--block" type="submit">
					Изпрати имейл
				</button>
			</FormActions>

			<FormFooter>
				<p>
					<strong>Нямаш акаунт? <Link className="link" to="/auth/register">Регистрирай се.</Link></strong>
				</p>
			</FormFooter>
		</Form>
	);
}

export default RequestResetPasswordForm;
