import React from "react";
import { useHistory } from "react-router-dom";
import IconText from "@/components/ui/IconText/IconText";
import iconCalendarSrc from "@/assets/images/ico-calendar.svg";
import iconGenderSrc from "@/assets/images/ico-gender.svg";
import Accordion from "@/components/Accordion/Accordion";
import profileSrc from "@/assets/images/ico-profile.svg";

import { Package } from "./styles";

const UserList = ({ users }) => {
	const history = useHistory();

	const handleRedirect = (userId) => {
		history.push(`/dashboard/admin/${userId}`);
	};

	const formatGender = (gender) => {
		switch (gender) {
			case "male":
				return "Мъж";
			case "female":
				return "Жена";
			default:
				return gender;
		}
	};

	const months = [
		"януари",
		"февруари",
		"март",
		"април",
		"май",
		"юни",
		"юли",
		"август",
		"септември",
		"октомври",
		"ноември",
		"декември",
	];

	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		const day = date.getUTCDate();
		const month = months[date.getUTCMonth()];
		const year = date.getUTCFullYear();
		return (
			<>
				<strong>{`${month} ${day},`}</strong> {year}
			</>
		);
	};

	if (!users || users.length === 0) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					fontSize: "1.5rem",
					textAlign: "center",
				}}
			>
				<h3>Няма намерени потребители!</h3>
			</div>
		);
	}

	return (
		<div>
			{users.map((user) => (
				<Accordion key={user._id} id={user._id} showAnalyzeButtonInit={false}>
					<Accordion.Head>
						<Package>
							<div className="package__container">
								<div className="package__row">
									<div className="package__col large">
										<h4 className="package__title">
											<span className="package__title__icon">
												<img src={profileSrc} alt="Икона на профил" />
											</span>
											<span className="package__title__text">
												{user.firstName} {user.lastName}
											</span>
										</h4>
									</div>
									<div className="package__col" title="Рожденна дата">
										<IconText
											icon={iconCalendarSrc}
											iconAlt="Икона на календар"
										>
											{user.medicalProfile?.birthDate ? (
												formatDate(user.medicalProfile?.birthDate)
											) : (
												<b>Няма информация</b>
											)}
										</IconText>
									</div>
									<div className="package__col">
										<IconText icon={iconGenderSrc} iconAlt="Икона за пол">
											<strong>
												{user.medicalProfile?.gender
													? formatGender(user.medicalProfile?.gender)
													: "Няма информация"}
											</strong>
										</IconText>
									</div>
									<button
										className="btn"
										type="submit"
										onClick={() => handleRedirect(user._id)}
										style={{ marginRight: "5px" }}
									>
										Преглед
									</button>
								</div>
							</div>
						</Package>
					</Accordion.Head>
					<Accordion.Body>
						<p>Тук ще има информация за потребителя</p>
					</Accordion.Body>
				</Accordion>
			))}
		</div>
	);
};

export default UserList;
