// Auth Ducks
import dayjs from '@/components/configureDayjs';

// Actions Types
const SIGN_IN = 'auth/SIGN_IN';
export const SIGN_OUT = 'auth/SIGN_OUT';

// Action Creators
export const signIn = () => ({
	type: SIGN_IN
});

export const signOut = () => ({
	type: SIGN_OUT
});

const initialStore = {
	isSignedIn: false,
	expTimestamp: null
}

// Reducers
export default function reducer (state = initialStore, action = {}) {
	switch (action.type) {
		case SIGN_IN:
			return {...state, isSignedIn: true, expTimestamp: dayjs().add(1, 'd').valueOf()};

		case SIGN_OUT:
			return {...state, isSignedIn: false, expTimestamp: null};

		default:
			return state;
	}
}
