import React from "react";
import { deleteFamilyMember } from '@/ducks/family';
import { useDispatch } from 'react-redux';

import {
	DeleteComponent,
} from './styles';  

const DeleteMember = ({ id, closeModal, firstName, lastName }) => {
	const dispatch = useDispatch();

    const handleDeleteClick = () => {
		dispatch(deleteFamilyMember(id));
		closeModal();
	};

	return (
		<DeleteComponent>
            <h2 className="delete__title">Изтриване на член на семейството</h2>
            <p className="delete__desc">Сигурни ли сте, че искате да изтриете членa на семейството?</p>
            <p className="delete__names">
				{firstName} {lastName}
			</p>
            <div className="delete__buttons">
                <button className="btn btn__delete" type="button" onClick={handleDeleteClick}>Изтрий</button>
            </div>
        </DeleteComponent>
	);
};

export default DeleteMember;
