import React, { useState, useEffect, useContext } from "react";

import IconText from "@/components/ui/IconText/IconText";
import ShareResults from "@/components/shareResults/ShareResults/ShareResults";
import Modal from "@/components/Modal/Modal";
import { api } from "@/apis";
import { accordionContext } from "../../Accordion/Accordion";

import { Package } from "./styles";

import iconCalendarSrc from "@/assets/images/ico-calendar.svg";
import iconLocationSrc from "@/assets/images/ico-pin.svg";
import iconPaperclipSrc from "@/assets/images/ico-paperclip.svg";
import loadingGif from "@/assets/images/loadingicon.gif";
import sharePng from "@/assets/images/shareicon.png";

const PackageSimple = ({
	icon,
	// title,
	date,
	// dateResults,
	laboratory,
	downloadLink,
	id,
	isAdminView,
}) => {
	const [result, setResult] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	// gptResponseCallback, showAnalyzeButton removed from below
	const { disabledButton } = useContext(accordionContext);

	const handleUrlAndPin = () => {
		api
			.post(`/shared-result/${id}/share`)
			.then((data) => setResult(data.data))
			.catch((e) => {
				console.log(e);
			});
		setIsModalVisible(true);
	};

	useEffect(() => {
		const body = document.querySelector("body");

		body.classList.toggle("modal-opened", isModalVisible);
	}, [isModalVisible]);

	return (
		<Package>
			<div className="package__container">
				<div className="package__row">
					<div className="package__col large">
						<h4 className="package__title">
							<span className="package__title__icon">
								<img src={icon} alt="Икона на здравния пакет" />
							</span>

							<span className="package__title__text">Изследване</span>
						</h4>
					</div>

					<div className="package__col small">
						<IconText icon={iconCalendarSrc} iconAlt="Икона на календар">
							<strong>
								{date.month} {date.day},
							</strong>{" "}
							{date.year}
						</IconText>
					</div>

					<div className="package__col">
						<IconText icon={iconLocationSrc} iconAlt="Икона на пин за карта">
							<strong>{laboratory.fullName}</strong> {/* {laboratory.city} */}
						</IconText>
					</div>

					<div className="package__col">
						{downloadLink && (
							<IconText icon={iconPaperclipSrc} iconAlt="Икона на кламер">
								<a
									href={downloadLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<strong>Results.pdf</strong>
								</a>
							</IconText>
						)}
					</div>
					{!isAdminView && (
						<div className="package__col col-actions">
							{disabledButton && (
								<div className="gpt-loading">
									<img
										src={loadingGif}
										alt="Loading индикатор"
										style={{ marginRight: "15px", marginTop: "5px" }}
									/>
								</div>
							)}
							{/* {showAnalyzeButton && (
								<button
									className="btn"
									type="submit"
									onClick={gptResponseCallback}
									style={{ marginRight: "5px" }}
									disabled={disabledButton}
								>
									Анализирай
								</button>
							)} */}
							<img
								src={sharePng}
								alt="Share icon"
								style={{
									height: "25px",
									cursor: "pointer",
									float: "right",
									marginRight: "5px",
									marginLeft: "5px",
								}}
								onClick={handleUrlAndPin}
							/>
						</div>
					)}
				</div>
			</div>
			{isModalVisible && result && (
				<Modal
					onModalClose={() => setIsModalVisible(false)}
					color="var(--blue)"
				>
					<ShareResults id={result.id} pin={result.pin} />
				</Modal>
			)}
		</Package>
	);
};

export default PackageSimple;
