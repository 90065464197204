import React from "react";

import { AdditionalHabitsContainer } from "../styles";
import { translateValues } from "./translation";

const AdditionalHabits = ({ additional }) => {
	const translated = translateValues(additional);
	return (
		<AdditionalHabitsContainer>
			<h5 className="habits__title">Допълнителни навици</h5>

			<div className="habit__description">
				<p className="habit__description__text">
					Пушене: {translated.smokingHistory}
				</p>
				<p className="habit__description__text">
					Излагане на вторичен дим - {translated.secondHandSmokeExposure}
				</p>
				<p className="habit__description__text">
					Консумация на алкохол - {translated.alcoholConsumption}
				</p>
				<p className="habit__description__text">
					Употреба на наркотици - {translated.recreationalDrugUse}
				</p>
			</div>
		</AdditionalHabitsContainer>
	);
};

export default AdditionalHabits;
