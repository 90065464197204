import styled from "styled-components";
import background from "@/assets/images/3d-illustration-rotating-dna-glowing-molecule.png";
import { SlideUp } from "./styles";
import { useRef } from "react";
import useIntoView from "../../hooks/useIntoView";
import JoinForm from "./joinForm";
import { media } from "../../GlobalStyles";

const JoinSection = styled.section`
	height: 316px;
	overflow: hidden;
	background: url(${background}) no-repeat;
	background-attachment: fixed;
	background-position: 50% 50%;
	background-size: 100% 100%;

	${media.xxxl`
		background-attachment: scroll;
	`}
	${media.md`
		height: 200px;
	`}
`;

const SectionHeader = styled.h2`
	color: #f3f3f3;
	font-weight: 600;
	font-size: 36px;
	letter-spacing: 0;
	padding: 0 10%;
	line-height: 39px;
	text-align: center;
	width: 100%;
	${media.md`
		padding: 0 10px;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;
		text-align: center;
	`}
`;

const JoinContainer = styled.form`
	background: rgba(6, 4, 100, 10%);
	width: 1430px;
	margin: 0 auto;
	& > div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		max-width: 1200px;
		margin: 0 auto;
		height: 100%;
		padding: 60px 0;
	}

	${media.md`
		& > div {
			padding: 40px 0;
		}
		width: 343px;
	`}
`;

const JoinBanner = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);

	return (
		<JoinSection ref={observeEl}>
			<JoinContainer>
				<SlideUp seen={seen}>
					<SectionHeader>
						Join the waiting list now to get exclusive access to the platform
						when we launch
					</SectionHeader>
					<JoinForm light />
				</SlideUp>
			</JoinContainer>
		</JoinSection>
	);
};

export default JoinBanner;
