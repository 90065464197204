import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PackageSimpleList from "@/components/packages/PackageSimple/PackageSimpleList";
import IconText from "@/components/ui/IconText/IconText";
import Tabs from "@/components/Tabs/Tabs";
import { api } from "@/apis";
import profileSrc from "@/assets/images/ico-profile.svg";
import Loader from "@/components/Loader/LoaderBig";
import iconCalendarSrc from "@/assets/images/ico-calendar.svg";
import iconGenderSrc from "@/assets/images/ico-gender.svg";
import PhysicalActivity from "./components/PhysicalActivity";
import NutritionalHabits from "./components/NutritionalHabits";
import SleepHabits from "./components/SleepHabits";
import AdditionalHabits from "./components/AdditionalHabits";
import AnalysisItem from "./components/AnalysisItem";
import FamilyInfo from "./components/FamilyInfo";

import { UserProfileBox } from "./styles";
import Goal from "./components/Goal";
import EmotionalHealth from "./components/EmotionalHealth";
import CognitiveHealth from "./components/cognitiveHealth";
import PersonalHealth from "./components/UserSpecific";

const UserProfile = () => {
	const { id } = useParams();
	const [userInfo, setUserInfo] = useState(null);
	const [healthBundle, setHealthBundle] = useState(null);
	const [medicalProfile, setMedicalProfile] = useState(null);
	const [analysis, setAnalysis] = useState(null);
	const [additionalInfo, setAdditionalInfo] = useState(null);
	const [analysisText, setAnalysisText] = useState("");
	const [uploadedFile, setUploadedFile] = useState(null);
	const [error, setError] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const [category, setCategory] = useState("detailed");
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showSuccessMessageFile, setShowSuccessMessageFile] = useState(false);
	const [showErrorMessageFile, setShowErrorMessageFile] = useState(false);

	const filters = { name: "*", type: "*" };

	const handleAnalysisSubmit = async () => {
		try {
			await api.post(`/admin/users/${id}/${category}`, { text: analysisText });
			setShowSuccessMessage(true);
			setShowErrorMessage(false);
			setAnalysisText("");
		} catch (error) {
			setShowSuccessMessage(false);
			setShowErrorMessage(true);
		}
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		setUploadedFile(file);
	};

	const handleSendFile = async () => {
		if (!uploadedFile) return;

		const formData = new FormData();
		formData.append("file", uploadedFile);

		try {
			await api.post(`/admin/users/${id}/upload`, formData);
			setUploadedFile(null);
			setShowSuccessMessageFile(true);
			setShowErrorMessageFile(false);
		} catch (error) {
			setShowSuccessMessageFile(false);
			setShowErrorMessageFile(true);
		}
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await api.get(`/admin/users/${id}`);
				setUserInfo(response.data.user);
				setHealthBundle(response.data.userHealthBundles);
				setMedicalProfile(response.data.user.medicalProfile);
				setAnalysis(response.data.user.analyses);
				setAdditionalInfo(response.data.user.questionnaire);
				setError(false);
			} catch (error) {
				setError(true);
			}
		};

		fetchUserData();
	}, [id]);

	const formatGender = (gender) => {
		switch (gender) {
			case "male":
				return "Мъж";
			case "female":
				return "Жена";
			default:
				return gender;
		}
	};

	const months = [
		"януари",
		"февруари",
		"март",
		"април",
		"май",
		"юни",
		"юли",
		"август",
		"септември",
		"октомври",
		"ноември",
		"декември",
	];

	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		const day = date.getUTCDate();
		const month = months[date.getUTCMonth()];
		const year = date.getUTCFullYear();
		return (
			<>
				<strong>{`${month} ${day},`}</strong> {year}
			</>
		);
	};

	const renderAnalysisItems = (analysisCategory) => {
		if (analysisCategory && analysisCategory.length > 0) {
			return (
				<>
					{analysisCategory
						.slice()
						.reverse()
						.slice(0, isExpanded ? analysisCategory.length : 3)
						.map((item, index) => (
							<AnalysisItem
								key={index}
								text={item.text}
								date={formatDate(item.date)}
							/>
						))}
					{analysisCategory.length > 3 && !isExpanded ? (
						<button
							className="btn analysis__show_all"
							onClick={() => setIsExpanded(true)}
						>
							Покажи всички
						</button>
					) : null}
				</>
			);
		} else {
			return <p>Няма предишни анализи в тази категория.</p>;
		}
	};

	if (error) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					fontSize: "1.5rem",
					textAlign: "center",
				}}
			>
				<h3>
					Възникна грешка!
					<br />
					Потребителя не съществува.
				</h3>
			</div>
		);
	}

	if (!userInfo && !medicalProfile) {
		return <Loader />;
	}

	return (
		<UserProfileBox>
			<Link to="/dashboard/admin" className="link-back">
				<svg
					width="8"
					height="14"
					viewBox="0 0 8 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.91 1L1 7l5.91 6"
						stroke="currentColor"
						strokeWidth=".818"
						fill="none"
						fillRule="evenodd"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				Обратно към <strong>Админ панел</strong>
			</Link>

			<h3>Профил</h3>

			<div className="top__boxes">
				<div className="general__info__box">
					{userInfo && medicalProfile ? (
						<>
							<span className="profile__user__icon">
								<img
									className="profile__user__icon__img"
									src={profileSrc}
									alt={`${userInfo.firstName} ${userInfo.lastName}`}
								/>
							</span>
							<div className="profile__user__info">
								<h3 className="profile__user__name">
									{userInfo.firstName} {userInfo.lastName}
								</h3>
								<IconText icon={iconCalendarSrc} iconAlt="Икона на календар">
									{formatDate(medicalProfile.birthDate)}
								</IconText>
								<IconText icon={iconGenderSrc} iconAlt="Икона за пол">
									<strong>{formatGender(medicalProfile.gender)}</strong>
								</IconText>
							</div>
						</>
					) : (
						<div className="warning__noinfo">Няма достатъчно информация</div>
					)}
				</div>

				{/* Right box */}
				<div className="medical__history__box">
					{additionalInfo ? (
						<>
							<p className="medical__history__text">
								{additionalInfo.allergies
									? `Алергии: ${additionalInfo.allergiesDetails}`
									: "Няма известни алергии"}
							</p>
							<p className="medical__history__text">
								{additionalInfo.pain
									? `Болка: ${additionalInfo.painDetails}`
									: "Няма болка"}
							</p>
							<p className="medical__history__text">
								{additionalInfo.operations
									? `Операции: ${additionalInfo.operationsDetails}`
									: "Няма правени операции"}
							</p>
						</>
					) : (
						<div className="warning__noinfo">Няма достатъчно информация</div>
					)}
				</div>
			</div>

			{analysis && (
				<>
					<h4 className="title__text__analysis">Предишни анализи</h4>
					<Tabs>
						<Tabs.Tab linkName="Подробни">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.detailed)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Обобщени">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.summary)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Хранителни">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.nutrition)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Добавки">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.supplements)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Физически">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.physical)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Сън">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.sleep)}
							</div>
						</Tabs.Tab>
						<Tabs.Tab linkName="Стрес">
							<div className={`analysis__list ${isExpanded ? "expanded" : ""}`}>
								{renderAnalysisItems(analysis.stress)}
							</div>
						</Tabs.Tab>
					</Tabs>
				</>
			)}

			<div className="send__analysis">
				<div className="text__analysis">
					<div className="title__text__analysis">Направи анализ</div>
					<select
						className="analysis__category__dropdown"
						value={category}
						onChange={(e) => setCategory(e.target.value)}
					>
						<option value="detailed">Детайлен анализ</option>
						<option value="summary">Обобщен анализ</option>
						<option value="nutrition">Хранителен анализ</option>
						<option value="supplements">Анализ за добавки</option>
						<option value="physical">Физически анализ</option>
						<option value="sleep">Анализ на съня</option>
						<option value="stress">Анализ за стрес</option>
					</select>
					<textarea
						className="input__text__analysis"
						placeholder="Поле за анализ"
						value={analysisText}
						onChange={(e) => setAnalysisText(e.target.value)}
					/>
					<div
						className={`success-message-container ${showSuccessMessage || showErrorMessage ? "show" : ""
							}`}
					>
						{showErrorMessage && (
							<>
								<span className="error__message">Неуспешно изпращане!</span>
							</>
						)}
						{showSuccessMessage && (
							<span className="success_message">Успешно изпратен анализ!</span>
						)}
					</div>

					<button className="btn" type="submit" onClick={handleAnalysisSubmit}>
						Изпрати
					</button>
				</div>
				<div className="file__analysis">
					<div className="title__text__analysis">Прикачи документ</div>
					<div className="file__box">
						<input type="file" onChange={handleFileUpload} />
						{uploadedFile ? (
							<span className="placeholder-text">{uploadedFile.name}</span>
						) : (
							<span className="placeholder-text">
								Натиснете или плъзнете и пуснете файл тук
							</span>
						)}
					</div>
					<div
						className={`success-message-container ${showSuccessMessageFile || showErrorMessageFile ? "show" : ""
							}`}
					>
						{showErrorMessageFile && (
							<>
								<span className="error__message">Неуспешно изпращане!</span>
							</>
						)}
						{showSuccessMessageFile && (
							<span className="success_message">
								Успешно изпратен документ!
							</span>
						)}
					</div>
					{uploadedFile && (
						<button className="btn" onClick={handleSendFile}>
							Изпрати
						</button>
					)}
				</div>
			</div>

			<div className="detailed__info__box">
				<Tabs>
					<Tabs.Tab linkName="Демография">
						{additionalInfo ? (
							<PersonalHealth {...{ ...medicalProfile, ...additionalInfo }} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Семейство">
						{additionalInfo ? (
							<FamilyInfo familyData={additionalInfo.family} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Цел">
						{additionalInfo ? (
							<Goal data={additionalInfo.goal} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Навици за сън">
						{additionalInfo ? (
							<SleepHabits sleepHabits={additionalInfo.sleepHabits} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Физическа Дейност">
						{additionalInfo ? (
							<PhysicalActivity
								physicalHealth={additionalInfo.physicalHealth}
							/>
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>

					<Tabs.Tab linkName="Хранителни навици">
						{additionalInfo ? (
							<NutritionalHabits habits={additionalInfo.nutrition} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Емоционално здраве">
						{additionalInfo ? (
							<EmotionalHealth data={additionalInfo.emotionalHealth} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Когнитивно здраве">
						{additionalInfo ? (
							<CognitiveHealth data={additionalInfo.cognitiveHealth} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
					<Tabs.Tab linkName="Допълнителни навици">
						{additionalInfo ? (
							<AdditionalHabits additional={additionalInfo.additionalHabits} />
						) : (
							<div className="warning__noinfo">Няма достатъчно информация</div>
						)}
					</Tabs.Tab>
				</Tabs>
			</div>

			<PackageSimpleList
				filters={filters}
				userPackages={healthBundle}
				userId={userInfo._id}
				isAdminView
			/>
		</UserProfileBox>
	);
};

export default UserProfile;
