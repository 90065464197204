import styled from "styled-components";

export const NoResults = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	gap: 40px;

	.noresults__img {
		width: 70%;
	}

	.noresults__title {
		text-align: center;
	}
`;

export const Analys = styled.section`
	.analysis__list {
		gap: 20px;
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		align-items: flex-start;
	}
`;
