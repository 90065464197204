import styled from "styled-components";
import {
	DotsBackground,
	SectionHeader,
	SectionSeparator,
	SlideUp,
} from "./styles";
import processStepsData from "./utils/ProcessStepsData";
import step1 from "@/assets/images/leaderStrat.svg";
import step2 from "@/assets/images/primaryResearch.svg";
import step3 from "@/assets/images/dna.svg";
import step4 from "@/assets/images/marketAssessment.svg";
import step5 from "@/assets/images/mail.svg";
import { useRef } from "react";
import useIntoView from "../../hooks/useIntoView";
import { media } from "../../GlobalStyles";

const steps = [step1, step2, step3, step4, step5];

const ProcessSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: 90px;
	background: #f2f7fb;
	${media.md`
		padding: 40px 0px;`}
`;

// const FormHolder = styled.form`
// 	max-width: 1200px;
// 	display: flex;
// 	height: 414px;
// 	text-align: center;
// 	align-items: baseline;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	align-items: center;
// 	padding: 90px 230px;
// 	margin-bottom: 90px;
// 	background: #fff;
// `;

// const InputHolder = styled.div`
// 	display: flex;
// 	align-items: flex-end;
// `;
// const SubmitButton = styled.button`
// 	outline: none;
// 	border: none;
// 	height: 48px;
// 	width: 208px;
// 	border-radius: 6px;
// 	background-color: #060464;
// 	color: #ffffff;
// 	font-size: 18px;
// 	letter-spacing: 0;
// 	line-height: 24px;
// `;

// const EmailInput = styled.input`
// 	border: none;
// 	height: 34.5px;
// 	width: 262px;
// 	opacity: 0.75;
// 	margin: 0 10px;

// 	border-bottom: 1px solid #33333380;
// 	box-sizing: border-box;

// 	&::placeholder {
// 		font-size: 14px;
// 		letter-spacing: 0;
// 		line-height: 22px;
// 	}
// `;

const Holder = styled(SlideUp)`
	max-width: 1200px;
	display: flex;
	justify-content: center;
	align-items: center;
	${media.md`
		max-width: 343px;
		flex-direction: column;
		align-items: flex-start;
		`}
`;

const SectionHolder = styled.div`
	width: 35%;

	${media.md`
		margin-bottom: 12px;
		width: 100%;
	`}
`;

const SectionSteps = styled.div`
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: flex-end;

	& > div:last-child {
		width: 393px;
	}
	${media.md`
		width: 100%;
		gap: 16px;
	`}
`;

const StepBox = styled(SlideUp)`
	z-index: 1;
	padding: 30px;

	height: 240px;
	width: 274px;
	border-radius: 3px;
	background-color: #ffffff;
	${media.md`
		width: 100%;
		height: 100%;
		`}
`;

const StepBoxCount = styled.div`
	opacity: 0.75;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 19px;
`;

const StepBoxHeader = styled.h3`
	margin: 5px 0;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.6px;
	line-height: 21px;
`;

const StepBoxIcon = styled.img`
	margin: 7px 0;
	height: 59px;
	width: 60px;
	${media.md`
		height: 40px;
		width: 41px;
	`}
`;

const StepDescription = styled.span`
	width: 100%;
	font-weight: 600;
	opacity: 0.75;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 28px;
	${media.md`
		font-size: 12px;
		letter-spacing: 0;
		line-height: 13px;
	`}
`;

const Process = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// };
	return (
		<ProcessSection id="process" ref={observeEl}>
			{/* <FormHolder onSubmit={handleSubmit}>
				<SectionHeader>Explore a sample report</SectionHeader>
				<SectionParagraph>
					Sign up below and receive a sample report showcasing the personalized
					insights and recommendations you can expect from NeoLife.
				</SectionParagraph>
				<InputHolder>
					<EmailInput type="text" placeholder="Enter your email address" />
					<SubmitButton>Get sample report</SubmitButton>
				</InputHolder>
			</FormHolder> */}
			<Holder seen={seen}>
				<SectionHolder>
					<SectionSeparator>How it works</SectionSeparator>
					{window.innerWidth >= 500 && <DotsBackground top={4} left={41.5} />}
					<SectionHeader>The process</SectionHeader>
					<StepDescription>
						The shortest path to a longer life. Start your journey
					</StepDescription>
				</SectionHolder>
				<SectionSteps>
					{processStepsData.map(({ name, description }, index) => (
						<StepBox seen={seen} delay={index / 4 + 0.3} key={index}>
							<StepBoxCount>STEP {index + 1}</StepBoxCount>
							<StepBoxIcon src={steps[index]} />
							<StepBoxHeader>{name}</StepBoxHeader>
							<StepDescription>{description}</StepDescription>
						</StepBox>
					))}
				</SectionSteps>
			</Holder>
		</ProcessSection>
	);
};

export default Process;
