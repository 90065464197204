import styled from "styled-components";

export const Container = styled.section`
	overflow-y: auto;
	display: flex;
	height: 100vh;
`;
export const Aside = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44%;
	padding-top: 60px;
	background: #fcfcff;

	@media (max-width: 1024px) {
		width: 35%;
		padding: 50px;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 56%;
	box-shadow: var(--shadow);

	@media (max-width: 1024px) {
		width: 65%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const ContentHeader = styled.div`
	padding: 24px 55px;

	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
	}
`;
export const ContentBody = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	padding: 0 55px;

	@media (max-width: 768px) {
		padding: 0 20px;
	}
`;

export const PinBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
