import React from 'react';
import DatePicker from 'react-date-picker';

import {
	Field,
	FieldControls,
	FieldError,
	Label
} from './styles';

const DatepickerComponent = (props) => {
	const {
		name,
		label,
		required,
		value,
		tooltipMessage,
		hasError,
		errorMessage,
		onChange
	} = props;
	return (
		<Field>
			<Label className="field__label" htmlFor={name}>
				{label} {required && <span>*</span>}

				{ tooltipMessage &&
					<div className="field__tooltip">
						<div className="field__tooltip__icon">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 440">
								<circle cx="220" cy="220" r="200" stroke="#000" stroke-width="40" fill-rule="evenodd" fill="transparent"/>
								<path d="M196.963 300.274l49.531-.102V261.69c0-10.438 4.866-20.3 17.886-28.841 13.019-8.537 49.364-25.861 49.364-71.409 0-45.55-38.167-76.858-70.25-83.5-32.078-6.642-66.835-2.272-91.5 24.75-22.087 24.197-26.741 43.337-26.741 85.565h49.491v-9.815c0-22.501 2.603-46.254 34.75-52.75 17.546-3.546 33.994 1.958 43.75 11.5 11.16 10.912 11.25 35.5-6.533 47.743l-27.896 18.979c-16.272 10.438-21.852 22.059-21.852 39.139v57.223zm-.325 70.418v-51.005h50.212v51.005h-50.212z"/>
							</svg>
						</div>

						<div className="field__tooltip__text">{tooltipMessage}</div>
					</div>
				}
			</Label>

			<FieldControls className="field__controls">
				<DatePicker
					onChange={onChange}
					value={value}
					format="dd/MM/yyyy"
					locale="bg-BG"
				/>

				{ hasError && <FieldError>{errorMessage}</FieldError>}
			</FieldControls>
		</Field>
	);
}

export default DatepickerComponent;
