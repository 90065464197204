import React from 'react';

import {
	IconTextContainer,
	Icon,
	Text
} from './styles';

const IconText = props => {
	return (
		<IconTextContainer className="icon-text">
			<Icon>
				<img src={props.icon} alt={props.iconAlt} />
			</Icon>

			<Text>
				{ !props.children &&
					<span className="icon-text__placeholder">{props.placeholder}</span>
				}

				{props.children}
			</Text>
		</IconTextContainer>
	);
}

export default IconText;
