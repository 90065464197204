import React from "react";
import { api } from "@/apis";
import { connect } from "react-redux";

import { signOut } from "@/ducks/auth";

import logoutSrc from "@/assets/images/ico-logout.svg";

import { Profile, ProfileImage, ProfileName } from "./styles";

const ProfileSmall = (props) => {
	const { name, initials, gender, image } = props;

	const handleLogout = () => {
		api
			.post("/auth/logout")
			.then((response) => {
				props.signOut();
				localStorage.clear();
			})
			.catch((e) => {
				console.log("Profile LOGOUT error", e.response);
			});
	};

	return (
		<Profile>
			<ProfileImage gender={gender} image={image}>
				{initials}
			</ProfileImage>

			<ProfileName>{name}</ProfileName>

			<button
				type="button"
				className="link-back section__back btn__logout"
				aria-label="Изход от профила"
				onClick={handleLogout}
			>
				<img src={logoutSrc} alt="Иконка за излизане от профила" />
				Изход
			</button>
		</Profile>
	);
};

export default connect(
	(state) => ({
		user: state.user,
	}),
	{
		signOut,
	}
)(ProfileSmall);
