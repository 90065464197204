import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import Section from "@/components/section/Section/Section";
import PackageSimpleList from "@/components/packages/PackageSimple/PackageSimpleList";
import Modal from "@/components/Modal/Modal";
import FormAddResults from "@/components/forms/FormAddResults/FormAddResults";
import Loader from "@/components/Loader/LoaderBig";

import imagePromoPackageSrc from "@/assets/images/image-promo-package.svg";

import { NoResults } from "./styles";

const Examinations = (props) => {
	const { userPackages, user } = props; // Removed packages and user from props
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [filteredPackages, setFilteredPackages] = useState([]);
	const filters = { name: "*", type: "*" }; // Removed setFilters from useState
	const history = useHistory();

	useEffect(() => {
		if (user.role === "admin") {
			history.push("/dashboard/admin");
		}
	}, [user, history]);

	useEffect(() => {
		const body = document.querySelector("body");

		body.classList.toggle("modal-opened", isModalVisible);
	}, [isModalVisible]);

	useEffect(() => {
		const filteredArray = userPackages.filter(
			(obj) => obj.hasOwnProperty("results") && obj.results.length > 0
		);
		setFilteredPackages(filteredArray);
		setIsLoading(false);
	}, [userPackages]);

	return (
		<React.Fragment>
			{isModalVisible && (
				<Modal onModalClose={() => setIsModalVisible(false)}>
					<FormAddResults onModalClose={() => setIsModalVisible(false)} />
				</Modal>
			)}
			{isLoading ? (
				<Loader />
			) : isEmpty(filteredPackages) ? (
				<NoResults>
					<img
						className="noresults__img"
						src={imagePromoPackageSrc}
						alt="Картинка за липса на резултати"
					></img>
					<h3 className="noresults__title">
						Няма налични резултати. Моля, въведете резултатите си чрез бутона
						„Въведи резултати“.
					</h3>
					<button className="btn" onClick={() => setIsModalVisible(true)}>
						Въведи резултати
					</button>
					{isModalVisible && (
						<Modal onModalClose={() => setIsModalVisible(false)}>
							<FormAddResults onModalClose={() => setIsModalVisible(false)} />
						</Modal>
					)}
				</NoResults>
			) : (
				<Section>
					<Section.Head flex>
						<h3 style={{ marginBottom: "20px" }}>Всички изследвания</h3>
						<button style={{ height: '35px' }} className="btn" onClick={() => setIsModalVisible(true)}>
							Въведи резултати
						</button>
					</Section.Head>

					<Section.Body>
						<PackageSimpleList filters={filters} />
					</Section.Body>
				</Section>
			)}
		</React.Fragment>
	);
};

export default connect((state) => ({
	user: state.user,
	userPackages: state.userPackages.list,
}))(Examinations);
