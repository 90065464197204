import styled from "styled-components";

export const ModalContent = styled.div`
	padding: 20px;

	.modal__title {
		text-align: center;
	}

	.form__names {
		display: flex;
		align-items: center;
        gap: 20px;
        margin-bottom: 25px;
    }

    .form__single {
        width: 50%;
    }

    label {
		font-weight: bold;
	}

	.form__actions {
		display: flex;
		align-items: center;
    	gap: 20px;
	}
`;

export const FormMessage = styled.div`
	min-height: 32px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	color: ${props => props.color || 'var(--text-color)'};
`;