import React from "react";
import { Link } from "react-router-dom";

import logoSrc from "@/assets/images/logo.png";

import { Header, HeaderLogo } from "./styles";

const DashboardHeader = (props) => {
	return (
		<Header>
			<HeaderLogo>
				<Link to="/dashboard">
					<img src={logoSrc} alt="NeoLife лого" />
				</Link>
			</HeaderLogo>
		</Header>
	);
};

export default DashboardHeader;
