import { useState } from "react";
import { api } from "../../apis";
import styled from "styled-components";
import { media } from "../../GlobalStyles";

const Email = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	flex-wrap: wrap;
	margin-top: 20px;
	overflow: hidden;
`;

const EmailInput = styled.input`
	margin-right: 20px;
	height: 34.5px;
	width: 262px;
	box-sizing: border-box;
	background: none;
	border: none;
	opacity: 0.75;
	${media.md`
		width: 162px;
		font-size: 12px;
		line-height: 13.24px;
	`}

	&.light {
		color: #fff;
		border-bottom: 1px solid #ffffffaa;
		opacity: 0.75;
	}

	&.dark {
		color: #333;
		border-bottom: 1px solid #ccc;
		opacity: 0.75;
	}

	&.light::placeholder {
		color: #fff;
	}
	&.dark::placeholder {
		color: #333;
		opacity: 0.75;
	}

	&.dark:focus {
		outline: none;
		border-color: #333333aa;
	}

	&.light:focus {
		outline: none;
		border-color: #ffffffaa;
	}
`;

const FormError = styled.div`
	margin-top: 15px;
`;

const SaveButton = styled.button`
	height: 48px;
	width: 180px;
	border: none;
	padding: auto;
	border-radius: 6px;
	cursor: pointer;
	font-weight: 600;
	font-size: 12.44px;
	${media.md`
		letter-spacing: 0;
		height: 32px;
		width: 112px;
		border-radius: 3.61px;
	`}
	&.light {
		background-color: #fff;
		color: #060464;
	}
	&.dark {
		background-color: #060464;
		color: white;
	}
`;

const JoinForm = ({ className, light = false }) => {
	const [formStatus, setFormStatus] = useState(null);
	const [formError, setFormError] = useState(null);
	const [email, setEmail] = useState("");

	const handleSubmitEmail = async (e) => {
		e.preventDefault();

		if (!email) {
			setFormStatus("Please enter your email.");
			setFormError(true);
			return;
		}

		if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email)) {
			setFormStatus("Please enter a valid email.");
			setFormError(true);
			return;
		}

		try {
			api
				.post(`/users/join-list`, { email: email })
				.then(() => {
					setFormStatus("Success!");
					setFormError(false);
				})
				.catch((e) => {
					setFormStatus(e.response.data.message || "Error! Please try again.");
					setFormError(true);
				});
		} catch (error) {
			setFormStatus("Error! Please try again.");
		}
	};
	return (
		<Email className={className}>
			<EmailInput
				className={light ? "light" : "dark"}
				onChange={(e) => setEmail(e.target.value)}
				type="text"
				required
				pattern="[^@\s]+@[^@\s]+"
				placeholder="Enter your email address"
			/>
			<SaveButton
				className={light ? "light" : "dark"}
				onClick={handleSubmitEmail}
			>
				Save your spot
			</SaveButton>
			<FormError style={{ color: formError ? "#ff7d9c" : "#28d1b5" }}>
				{formStatus && formStatus}
			</FormError>
		</Email>
	);
};

export default JoinForm;
