import { useEffect, useState } from "react";
import { api } from "@/apis";
import FormInput from "@/components/FormElements/FormInput";
import styled from "styled-components";

const Form = styled.form`
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    height: 350px;
    & input {
        margin: 7px 0;
    }
`

const FormTitle = styled.h2`
    font-size: 24px;
    margin: 14px;
`

const ErrorMsg = styled.span`
    color: coral;
    height: 15px;
`

const Button = styled.button`
    margin: 10px 0;
    width: 40%;

    &:focus {
        background: var(--blue-dark);
        border-color: var(--blue-dark);
        color: rgb(255, 255, 255);
    }
`

const ChangePasswordForm = ({ user, close }) => {
    const minPassLength = 8;
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [formError, setFormError] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (submitting) return;

        if (formError !== '') return;
        if (!(password && oldPassword && repeatPassword)) return setFormError('Моля полълнете всички полета!')
        setSubmitting(true);

        api.put(`/users/me`, {
            id: user.uid,
            password,
            currentPassword: oldPassword
        }).then(() => setSuccess(true)).catch(err => {
            switch (err?.response?.status) {
                case 400:
                    return setFormError('Грешна парола, моля опитайте пак.');
                default:
                    return setFormError('Грешка, моля опитайте по-късно.');
            }

        });
    }

    useEffect(() => {
        setFormError('');
        if (!password) return
        switch (true) {
            case password.length < minPassLength:
                return setFormError(`Паролата трябва да бъде поне ${minPassLength} символа`);
            case repeatPassword && password !== repeatPassword:
                return setFormError('Паролите не съвпадат')
            case oldPassword === password:
                return setFormError('Новата парола не може да съвпада със сегашната.');
            default: break;
        }

    }, [password, repeatPassword, oldPassword])

    return <Form onSubmit={handleSubmit}>
        {!success ?
            <>
                <FormTitle>Промяна на паролата</FormTitle>
                {user?.email && <FormInput type="email" readonly value={user.email} />}
                <FormInput autoFocus autoComplete="current-password" type="password" placeholder="Парола" onChange={({ target: inp }) => setOldPassword(inp.value)} />
                <FormInput autoComplete="new-password" type="password" placeholder="Нова Парола" onChange={({ target: inp }) => setPassword(inp.value)} />
                <FormInput autoComplete="new-password" type="password" placeholder="Потвърди новата парола" onChange={({ target: inp }) => setRepeatPassword(inp.value)} />
                <Button disabled={submitting} className="btn">Запази</Button>
                <ErrorMsg>{formError && formError}</ErrorMsg>
            </> :
            <>
                <FormTitle>Успешно сменена парола!</FormTitle>
                <Button onClick={close} className="btn">Close</Button>
            </>
        }
    </Form>;
}

export default ChangePasswordForm;