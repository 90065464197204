import React, { useState } from "react";

import iconCopy from '@/assets/images/ico-copy.svg';

import { Share } from "./styles";

const ShareResults = ({ id, pin }) => {
	const [CopyMessage, setCopyMessage] = useState("Копирай");
	const [copyTimeoutId, setCopyTimeoutId] = useState(null);

	const mainUrl = `${window.location.origin}/shared-result`;
	const digits = pin.toString().split(""); // convert pin to separated digits

	const handleCopy = (value) => {
		if(navigator.clipboard){
			navigator.clipboard.writeText(value);
			setCopyMessage("Копирано");
		} else {
			setCopyMessage("Не може да се копира в момента");
		}
		clearTimeout(copyTimeoutId);
		const newTimeoutId = setTimeout(() => setCopyMessage("Копирай"), 1000);
		setCopyTimeoutId(newTimeoutId);
	};

	const copyMessageToClipboard = (url, PIN) => {
		const message = `Споделяне на изследване.\nЗдравейте, линкът към изследването ми е:\n${url}\nЗа отключването му е необходимо този ПИН код:\n${PIN}`;
		if(navigator.clipboard){
			navigator.clipboard.writeText(message);
			setCopyMessage("Копирано");
		} else {
			setCopyMessage("Не може да се копира в момента");
		}
		clearTimeout(copyTimeoutId);
		const newTimeoutId = setTimeout(() => setCopyMessage("Копирай"), 1000);
		setCopyTimeoutId(newTimeoutId);
	};

	return (
		<Share>
			<div className="share-test">
				<h2 className="share-test__title">Споделяне на тест</h2>
				<p className="share-test__desc">
					Моля, предоставете следната връзка и ПИН код на лицето, с което искате
					да споделите теста:
				</p>
				<div className="share-test__props">
					<label className="share-test__label" htmlFor="url">
						Линк:
					</label>
					<div className="share-test__field" onClick={() => handleCopy(`${mainUrl}/${id}`)}>
						<p className="share-test__prop">
							<span className="tooltiptext" id="myTooltip">{CopyMessage}</span>
							{`${mainUrl}/${id}`}
						</p>
					</div>
					<label className="share-test__label bottom" htmlFor="pin">
						ПИН:
					</label>
					<div className="share-test__field" onClick={() => handleCopy(pin)}>
						<p className="share-test__prop">
							<span className="tooltiptext" id="myTooltip">{CopyMessage}</span>
							{digits.map((digit, index) => (
								<span className="digit__box" key={index}>{digit}</span>
							))}
						</p>
					</div>
					<div className="share-test__field share-test__copyall" onClick={() => copyMessageToClipboard(`${mainUrl}/${id}`, pin)}>
						<span className="tooltiptext" id="myTooltip">{CopyMessage}</span>
						<p className="share-test__copyall__text">Копирай линк и пин</p>
						<img width="25px" height="25px" src={iconCopy} alt="Лого" />
					</div>
				</div>
			</div>
		</Share>
	);
};

export default ShareResults;
