import styled from "styled-components";

export const Form = styled.div`
	padding-top: 25px;
	margin-bottom: 3rem;

	.form__cols {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
	}

	.form__col {
		width: 33.333%;
		padding: 0 12px;
	}

	.form__row {
		margin-bottom: 25px;
	}

	.form__error {
		color: var(--danger);
	}

	.form__success {
		color: var(--success);
	}

	.form__actions {
		margin-top: 10px;
	}

	@media (max-width: 768px) {
		.form__col {
			width: 100%;
		}
	}
`;
