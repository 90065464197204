import { api } from "@/apis";

// Actions Types
const FETCH_CURRENT_USER_DETAILS =
	"currentUserDetails/FETCH_CURRENT_USER_DETAILS";

// Action Creators
export const fetchCurrentUserDetails = () => async (dispatch) => {
	const response = await api.get("/user-health-bundles/overview");

	dispatch({
		type: FETCH_CURRENT_USER_DETAILS,
		payload: response.data.results,
	});
};

const initState = {
	results: [],
};

// Reducers
export default function reducer(state = initState, action = {}) {
	switch (action.type) {
		case FETCH_CURRENT_USER_DETAILS:
			return {
				...state,
				results: action.payload,
			};

		default:
			return state;
	}
}
