import React, { useState, useEffect } from "react";
import { api } from "@/apis";

import Section from "@/components/section/Section/Section";
import UserList from "@/components/admin/users/UserList";
import Loader from "@/components/Loader/LoaderBig";

const Admin = () => {
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);

		api
			.get("admin/users/")
			.then((response) => {
				setUsers(response.data);
			})
			.catch((error) => {
				console.error("Error fetching users:", error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<React.Fragment>
			<Section>
				<Section.Head flex>
					<h3 style={{ marginBottom: "20px" }}>Админ панел</h3>
				</Section.Head>

				<Section.Body>
					{isLoading ? <Loader /> : <UserList users={users} />}
				</Section.Body>
			</Section>
		</React.Fragment>
	);
};

export default Admin;
