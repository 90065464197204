import React from "react";

import { Field, FieldControls, FieldError, Label, Input } from "./styles";

const FormInput = ({
	name,
	type,
	placeholder,
	onChange,
	value,
	label,
	hasError,
	errorMessage,
	required,
	autocomplete,
	readonly,
	defaultValue,
	options,
	maxLength,
	alignCenter,
	autoFocus,
	...props
}) => {
	return (
		<Field>
			<Label className="field__label" htmlFor={name}>
				{label} {required && <span>*</span>}
			</Label>

			<FieldControls className="field__controls">
				{options ? (
					<div className="select">
						<select name={name} onChange={onChange} value={value}>
							{options.map((option, index) => {
								return (
									<option key={`${name}-${index}`} value={option.value}>
										{option.label}
									</option>
								);
							})}
						</select>
					</div>
				) : (
					<Input
						className="field__input"
						autoFocus={autoFocus}
						id={name}
						name={name}
						type={type}
						placeholder={placeholder}
						onChange={onChange}
						value={value}
						required={required}
						autoComplete={autocomplete}
						defaultValue={defaultValue}
						readOnly={readonly}
						maxLength={maxLength}
						style={{ textAlign: alignCenter ? "center" : "left" }}
					/>
				)}

				{hasError && <FieldError>{errorMessage}</FieldError>}
			</FieldControls>
		</Field>
	);
};

export default FormInput;
