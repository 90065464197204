import styled from "styled-components";

export const Tabs = styled.div`
	padding: 22px 24px;
	border-radius: 4px;
	background: #fff;
	box-shadow: var(--shadow);
	.tabs__head {
		border-bottom: 2px solid var(--border-color);
	}

	.tabs__nav {
		list-style-type: none;
		display: flex;
		padding: 0;
		margin: 0 -20px;
		overflow-x: auto;
		white-space: nowrap;
		overflow-y: hidden;
		padding: 0 0 10px 0;

		.tab {
			padding: 0 27px;
		}
	}

	.tabs__nav::-webkit-scrollbar {
		height: 8px;
		background-color: #f5f5f5;
	}

	.tabs__nav::-webkit-scrollbar-thumb {
		background-color: #c4c4c4;
		border-radius: 4px;
	}

	.tabs__nav::-webkit-scrollbar-thumb:hover {
		background-color: #a1a1a1;
	}

	.tabs__nav::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}

	.tab__link {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		padding: 5px 0;
		border: 0;
		border-radius: 0;
		outline: 0;
		background: transparent;
		font-size: 16px;
		font-weight: 500;
		color: var(--text-color);
		text-decoration: none;
		cursor: pointer;
		transition: color 0.3s;

		&:after {
			content: "";
			position: absolute;
			bottom: -2px;
			left: 50%;
			width: 0;
			height: 2px;
			background: currentColor;
			transition: left 0.3s, width 0.3s;
		}

		&:hover {
			color: var(--blue);
		}

		&.active {
			color: var(--blue);

			&:after {
				left: 0;
				width: 100%;
			}
		}
	}

	.tab__entry {
		margin: 15px 0 10px;
		font-size: 14px;
		line-height: 1.3;

		p {
			margin: 0 0 15px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
`;
