import styled from 'styled-components';

export const Package = styled.div`
	.active & {
		.package__title__icon {
			background: #fff;
		}
	}

	.package__row {
		display: flex;
		align-items: center;
		margin: 0 -10px;

		@media (max-width: 768px) {
			align-items: flex-start;
			flex-direction: column;
			gap: 20px;
		}
	}

	.package__col {
		width: 25%;
		padding: 0 10px;
		display: flex;
		align-items: center;

		&.small {
			width: 19%;
		}

		&.large {
			width: 27%;
		}

		@media (max-width: 768px) {
			width: 100%;
			justify-content: space-between;

			&.small {
				width: 100%;
			}
	
			&.large {
				width: 100%;
			}
		}
	}

	.package__col.col-actions {
		justify-content: flex-end;
		@media (max-width: 768px) {
			justify-content: space-between;
		}
	} 

	.package__title {
		display: flex;
		align-items: center;
		margin: 0;
	}

	.package__title__icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin-right: 15px;
		background: #ECF1F8;
		transition: background .3s;
	}

	.package__cards {
		display: flex;
		margin: 0 -12px;
	}

	.package__actions {
		padding: 16px 0;
	}

	.gpt-loading {
		display: inline;
	}

	@media (max-width: 768px) {
		.package__row {
			align-items: center;
		}
		.package__title__text {
			width: 100%;
			text-align: center;
		}
		.btn__share {
			margin-left: 39px;
		}

		.gpt-loading {
			display: inline;
		}
	}
`;

export const ListPackages = styled.div`
	.btn__enter__results {
		margin: 20px 0;
		display: none;
	}

	@media (max-width: 1024px) {
		.btn__enter__results {
			display: block;
			text-align: center;
		}
	}
`;
export const Cards = styled.div`
	padding-bottom: 15px;
`;
export const CardsContainer = styled.div`
	display: flex;
	margin: 0 -12px;

	@media (max-width: 768px) {
		gap: 10px;
    	flex-direction: column;
	}
`;
export const CardsActions = styled.div`
	padding-top: 15px;
`;
