import styled from "styled-components";

export const Share = styled.div`
	.share-test {
		padding: 20px;
	}
	.share-test__title {
		text-align: center;
	}
	.share-test__desc {
		text-align: center;
	}
	.share-test__props {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.share-test__label {
		font-size: 20px;
		font-weight: 600;
	}
	.share-test__label.bottom {
		margin-top: 20px;
	}
	.share-test__prop {
		text-align: center;
		font-size: 18px;
		color: black;
		cursor: pointer;
	}

	.digit__box {
		display: inline-block;
		width: 30px;
		height: 40px;
		border: 1px solid var(--blue);
		text-align: center;
		line-height: 40px;
		margin-right: 5px;
	}

	.share-test__field {
		position: relative;
		display: inline-block;
	}
	.share-test__field .tooltiptext {
		visibility: hidden;
		width: 140px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px;
		position: absolute;
		z-index: 1;
		bottom: 100%;
		left: 52%;
		margin-left: -75px;
		opacity: 0;
		-webkit-transition: opacity 0.3s;
		transition: opacity 0.3s;
	}

	.share-test__field .tooltiptext::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	.share-test__field:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}

	.share-test__copyall {
		display: flex;
		padding-top: 15px;
		gap: 10px;
	}
	.share-test__copyall__text {
		margin-bottom: 0;
		margin-top: 0;
		font-size: 17px;
		cursor: pointer;
	}
	.share-test__copyall:hover {
		opacity: 0.7;
	}
`;
