import styled from 'styled-components';

export const IconTextContainer = styled.div`
	display: flex;
	align-items: center;

	.icon-text__placeholder {
		opacity: .5;
	}
`;
export const Icon = styled.div`
	display: inline-block;
	margin-right: 10px;
	font-size: 0;
`;
export const Text = styled.div`
	font-size: 14px;

	strong {
		font-weight: 700;
	}

	a {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		color: var(--text-color);
		text-decoration: none;
		transition: color .3s;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			height: 1px;
			background: currentColor;
			transition: left .3s, width .3s, background .3s;
			will-change: left, width, background;
		}

		&:hover {
			color: var(--blue);

			&:after {
				width: 100%;
				left: 0;
			}
		}
	}
`;
