import { AdditionalHabitsContainer } from "../styles";
import Field from "./Field";
import { translateArray, translateValues } from "./translation";

const EmotionalHealth = ({ data }) => {
	const translated = translateValues(data);
	return (
		<AdditionalHabitsContainer>
			<Field
				header={"1. Стратегии за управление на стрес"}
				paragraph={`${translateArray(data.stressManagementStrategies).join(
					", "
				)}`}
			/>

			<Field
				header={"2. Справяне с трудни ситуации"}
				paragraph={`${translated.handlingChallengingSituations}`}
			/>

			<Field
				header={"3. Приоритет на време за радост и релаксация"}
				paragraph={`${translated.prioritizingTimeForJoyRelaxation}`}
			/>

			<Field
				header={"4. Честота на свързване за емоционална подкрепа"}
				paragraph={`${translated.frequencyOfConnectingForEmotionalSupport}`}
			/>

			<Field
				header={"5. Стратегии за саморазговор и самоуважение"}
				paragraph={`${translateArray(data.selfTalkAndSelfEsteemStrategies).join(
					", "
				)}`}
			/>

			<Field
				header={"6. Стратегии за намиране на смисъл в живота"}
				paragraph={`${translateArray(data.senseOfPurposeStrategies).join(
					", "
				)}`}
			/>

			<Field
				header={"7. Задоволство от баланса между работа и живот"}
				paragraph={`${translated.satisfactionWithWorkLifeBalance}`}
			/>
		</AdditionalHabitsContainer>
	);
};

export default EmotionalHealth;
