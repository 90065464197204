// Header.js
import React from "react";
import styled from "styled-components";
import HeaderImage from "@/assets/images/top-img.png";
import {
	ContentContainer,
	DotsBackground,
	SlideLeft,
	SlideRight,
	Title,
} from "./styles";
import { useRef } from "react";
import useIntoView from "../../hooks/useIntoView";
import JoinForm from "./joinForm";
import { media } from "../../GlobalStyles";

const HeaderContainer = styled(ContentContainer)`
	margin: 50px auto;
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	gap: 100px;

	${media.md`
		gap: 50px;
		flex-direction: column;
		align-items: center;
		margin-top: 0;`}
`;

const ContentWrapper = styled(SlideLeft)`
	margin-top: 40px;
	width: 100%;

	${media.md`	
		margin-top: 10px;
	`}
`;

const Subtitle = styled.h2`
	font-size: 24px;
	margin-top: 30px;
	line-height: 28px;
	opacity: 0.75;
	${media.md`
		margin-top: 10px;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 20px;
	`}
`;

const Description = styled.p`
	margin-top: 34px;
	opacity: 0.75;
	line-height: 28px;
	font-size: 16px;

	${media.md`
		margin-top: 16px;
		font-size: 12px;
		line-height: 13px;
	`}
`;

const ImageWrapper = styled(SlideRight)`
	position: relative;
	z-index: 1;
	max-width: 47%;
	&::before {
		content: "";
		position: absolute;
		top: -50px;
		right: -60px;
		width: 100%;
		height: 100%;
		background-color: #65e3b4;
		opacity: 0.3;
		z-index: -1;
	}

	img {
		z-index: 1;
		display: block;
		height: auto;
		width: 100%;
	}

	${media.md`
		max-width: 80%;
		&::before {
			top: -25px;
			right: -30px;
		}`}
`;

const Form = styled(JoinForm)`
	& button {
		width: 35%;
	}
	& input {
		width: 54%;
	}
`;

const Dots = styled(DotsBackground)`
	${media.md`
		position: absolute;
		left: ${({ leftResp }) => `${leftResp}%`} ;
		margin-top: ${({ topResp }) => `${topResp}px`};
	`}
`;

const Header = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);

	return (
		<HeaderContainer as={"header"} ref={observeEl}>
			<ContentWrapper seen={seen}>
				<Title>Your Longevity Assistant</Title>
				<Subtitle>Healthy aging is not a luxury but a necessity</Subtitle>
				<Description>
					Join the waiting list now to get exclusive access to the platform when
					we launch
				</Description>
				<Form />
			</ContentWrapper>
			<Dots top={435} left={48} topResp={460} leftResp={-8.5} />
			{/* <DotsBackground top={435} left={48} /> */}
			<ImageWrapper seen={seen}>
				<img src={HeaderImage} alt="Body Network Visualization" />
			</ImageWrapper>
		</HeaderContainer>
	);
};

export default Header;
