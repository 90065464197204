import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { updateUserInfo } from "@/ducks/user";

import FormInput from "@/components/FormElements/FormInput";

import { Form } from "./styles";

const FormPersonalDetail = (props) => {
	const { user, updateUserInfo } = props;

	const [weight, setWeight] = useState("");
	const [height, setHeight] = useState("");
	const [bloodType, setBloodType] = useState("");
	const [errors, setErrors] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);

	const genderOptions = {
		female: "Жена",
		male: "Мъж",
	};

	const bloodTypeOptions = [
		{ value: "0+", label: "0+" },
		{ value: "0-", label: "0-" },
		{ value: "A+", label: "A+" },
		{ value: "A-", label: "A-" },
		{ value: "B+", label: "B+" },
		{ value: "B-", label: "B-" },
		{ value: "AB+", label: "AB+" },
		{ value: "AB-", label: "AB-" },
	];

	useEffect(() => {
		setHeight(user.medicalProfile.height ? user.medicalProfile.height : "");
		setWeight(user.medicalProfile.weight ? user.medicalProfile.weight : "");
		setBloodType(
			user.medicalProfile.bloodType ? user.medicalProfile.bloodType : ""
		);
	}, [user]);

	const onSubmit = (event) => {
		event.preventDefault();

		updateUserInfo({
			medicalProfile: {
				weight,
				height,
				bloodType,
			},
		})
			.then((r) => {
				setIsSuccess(true);
			})
			.catch((error) => {
				setErrors(error.response.data.errors);
			});
	};

	const renderErrors = () => {
		return Object.values(errors).map((error, i) => {
			return <p key={i}>{error}</p>;
		});
	};

	if (isEmpty(user)) {
		return <div>Зареждаме на формата. :-)</div>;
	} else {
		return (
			<Form>
				<form onSubmit={onSubmit}>
					<div className="form__cols">
						<div className="form__col">
							<div className="form__row">
								<FormInput
									label="Име"
									name="firstName"
									type="text"
									placeholder="Иван"
									value={user.firstName}
									readonly
									autocomplete="new-password"
								/>
							</div>
						</div>

						<div className="form__col">
							<div className="form__row">
								<FormInput
									label="Фамилия"
									name="lastName"
									type="text"
									placeholder="Иванов"
									value={user.lastName}
									readonly
									autocomplete="new-password"
								/>
							</div>
						</div>

						<div className="form__col">
							<div className="form__row">
								<FormInput
									label="Години"
									name="years"
									type="text"
									placeholder="20"
									value={user.medicalProfile.years}
									readonly
									autocomplete="new-password"
								/>
							</div>
						</div>

						<div className="form__col">
							<div className="form__row">
								<FormInput
									label="Пол"
									name="gender"
									type="text"
									placeholder="Жена"
									value={genderOptions[user.medicalProfile.gender] || ""}
									readonly
									autocomplete="new-password"
								/>
							</div>
						</div>

						{user.role === "patient" && (
							<>
								<div className="form__col">
									<div className="form__row">
										<FormInput
											label="Ръст"
											name="height"
											type="text"
											placeholder="173см."
											value={height}
											onChange={(e) => {
												setHeight(e.target.value);
											}}
											autocomplete="new-password"
											readonly={
												user.medicalProfile.height || user.role === "family"
											}
										/>
									</div>
								</div>

								<div className="form__col">
									<div className="form__row">
										<FormInput
											label="Тегло"
											name="weight"
											type="number"
											placeholder="57кг."
											value={weight}
											onChange={(e) => {
												setWeight(e.target.value);
											}}
											autocomplete="new-password"
											readonly={user.role === "family"}
										/>
									</div>
								</div>

								<div className="form__col">
									<div className="form__row">
										{user.medicalProfile.bloodType ? (
											<FormInput
												label="Кръвна група"
												name="bloodType"
												type="text"
												placeholder="Кръвна група"
												value={bloodType}
												onChange={(e) => {
													setBloodType(e.target.value);
												}}
												autocomplete="new-password"
												readonly
											/>
										) : (
											<FormInput
												label="Кръвна група"
												name="bloodType"
												type="text"
												placeholder="Кръвна група"
												options={bloodTypeOptions}
												value={bloodType}
												onChange={(e) => {
													setBloodType(e.target.value);
												}}
												autocomplete="new-password"
											/>
										)}
									</div>
								</div>
							</>
						)}
					</div>

					{isSuccess && (
						<div className="form__success">
							<p>Успешно обновихте данните си!</p>
						</div>
					)}

					<div className="form__error">{renderErrors()}</div>

					{user.role === "patient" && (
						<div className="form__actions">
							<button className="btn" type="submit">
								Запази промените
							</button>
						</div>
					)}
				</form>
			</Form>
		);
	}
};

export default connect(
	(state) => ({
		user: state.user,
	}),
	{
		updateUserInfo,
	}
)(FormPersonalDetail);
