import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { api } from "@/apis";
import { signIn } from "@/ducks/auth";
import FormInput from "@/components/FormElements/FormInput";
import { H } from "highlight.run";

import {
	Form,
	FormTitle,
	FormRow,
	FormHint,
	FormMessage,
	FormActions,
	FormFooter,
} from "./styles";
import Loader from "../Loader/Loader";

const LoginForm = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return <p key={i}>{error}</p>;
			});
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);

		api
			.post("/auth/login", {
				email,
				password,
			})
			.then((response) => {
				H.identify(`${email}`);
				if (response.status === 200) {
					props.signIn();
				}
			})
			.catch((e) => {
				setErrors(e.response.data.errors);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Form onSubmit={onSubmit}>
			<FormTitle>Вход</FormTitle>

			<FormRow>
				<FormInput
					label="Имейл адрес"
					name="email"
					type="email"
					placeholder="you@gmail.com"
					value={email}
					required
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>
			</FormRow>

			<FormRow>
				<FormInput
					label="Парола"
					name="password"
					type="password"
					placeholder="Въведи парола"
					value={password}
					required
					onChange={(e) => {
						setPassword(e.target.value);
					}}
				/>

				<FormHint>
					<Link className="link" to="/auth/request-reset-password">
						Забравена парола?
					</Link>
				</FormHint>
			</FormRow>

			<FormMessage color="var(--danger)">{renderErrors()}</FormMessage>

			<FormActions>
				<button className="btn btn--block" type="submit">
					{!isLoading ? "Вход" : <Loader />}
				</button>
			</FormActions>

			<FormFooter>
				<p>
					<strong>
						Нямаш акаунт?{" "}
						<Link className="link" to={`auth/register`}>
							Регистрирай се.
						</Link>
					</strong>
				</p>
			</FormFooter>
		</Form>
	);
};

export default connect(null, {
	signIn,
})(LoginForm);
