import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { fetchUserPackage } from "@/ducks/userPackages";
import { setIconByPackageType } from "@/components/utils";
import { api } from "@/apis";

import Accordion from "@/components/Accordion/Accordion";
import BiomarkerSimple from "@/components/biomarkers/BiomarkerSimple/BiomarkerSimple";
import BiomarkerDetail from "@/components/biomarkers/BiomarkerDetail/BiomarkerDetail";
import Filters from "@/components/filters/Filters";
import IconText from "@/components/ui/IconText/IconText";
import Modal from "@/components/Modal/Modal";
import ShareResults from "@/components/shareResults/ShareResults/ShareResults";
import Loader from "@/components/Loader/LoaderBig";

import iconCalendarSrc from "@/assets/images/ico-calendar.svg";

import { PackageBought } from "./styles";

const PackagesBoughtDetail = (props) => {
	const { id } = props.match.params;
	const { selectedUserPackage, fetchUserPackage } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [result, setResult] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [filters, setFilters] = useState({
		label: "*",
	});

	const location = useLocation();
	const isAdmin = location.state?.isAdmin;
	const userProfileId = location.state?.userProfileId;

	const onFilterChange = (name, filter) => {
		setFilters({
			...filters,
			[name]: filter,
		});
	};

	const handleUrlAndPin = () => {
		api
			.post(`/shared-result/${id}/share`)
			.then((data) => setResult(data.data))
			.catch((e) => {
				console.log(e);
			});
		setIsModalVisible(true);
	};

	useEffect(() => {
		setFilters({
			label: props.location.hash.replace("#", "") || "*",
		});

		fetchUserPackage(id).then(() => {
			setIsLoading(false);
		});

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const body = document.querySelector("body");

		body.classList.toggle("modal-opened", isModalVisible);
	}, [isModalVisible]);

	const renderResults = (results) => {
		if (isEmpty(results)) return;

		return results
			.filter((result) => {
				if (filters["label"] === "*") {
					return true;
				}

				return result.label === filters.label;
			})
			.map((result) => {
				if (result.value === null) {
					return null;
				}
				return (
					<Accordion key={`biomarker-${result.biomarker._id}`}>
						<Accordion.Head>
							<BiomarkerSimple
								title={result.biomarker.label}
								result={result.value}
								unit={result.biomarker.metric}
								id={result.biomarker._id}
								referent={{
									min: `${
										result.referenceValues.min === null
											? "Няма"
											: result.referenceValues.min
									}`,
									max: `${
										result.referenceValues.max === null
											? "Няма"
											: result.referenceValues.max
									}`,
								}}
							/>
						</Accordion.Head>

						<Accordion.Body>
							<BiomarkerDetail
								id={result.biomarker._id}
								description={result.biomarker.description}
							/>
						</Accordion.Body>
					</Accordion>
				);
			});
	};

	const renderPackage = (selectedPackage) => {
		if (isEmpty(selectedPackage)) return;

		const { results, date, chatGptAnalysis } = selectedPackage;

		return (
			<PackageBought>
				<div className="package__head">
					{isAdmin && userProfileId ? (
						<Link
							to={`/dashboard/admin/${userProfileId}`}
							className="link-back"
						>
							<svg
								width="8"
								height="14"
								viewBox="0 0 8 14"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.91 1L1 7l5.91 6"
									stroke="currentColor"
									strokeWidth=".818"
									fill="none"
									fillRule="evenodd"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							Обратно към <strong>Потребителя</strong>
						</Link>
					) : (
						<Link to="/dashboard/examinations" className="link-back">
							<svg
								width="8"
								height="14"
								viewBox="0 0 8 14"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.91 1L1 7l5.91 6"
									stroke="currentColor"
									strokeWidth=".818"
									fill="none"
									fillRule="evenodd"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							Обратно към <strong>Всички изследвания</strong>
						</Link>
					)}

					<div className="package__head__inner">
						<h3 className="package__title">
							<span className="package__title__icon">
								<img
									src={setIconByPackageType("common")}
									alt="Икона на пакет"
								/>
							</span>

							<span className="package__title__text">Изследване</span>
						</h3>

						<Filters>
							<Filters.Filter
								value={filters.label}
								label="Филтрирай по резултат"
								name="label"
								onChange={(e) => onFilterChange("label", e.target.value)}
								options={[
									{ value: "*", label: "Всички" },
									{ value: "normal", label: "В норма" },
									{ value: "observation", label: "Под наблюдение" },
									{ value: "risk", label: "В риск" },
								]}
							/>
						</Filters>

						<div className="package__actions">
							<IconText icon={iconCalendarSrc} iconAlt="Икона на календар">
								<strong>
									{date.month} {date.day},
								</strong>{" "}
								{date.year}
							</IconText>
							{!isAdmin && userProfileId && (
								<div className="btn__share">
									<button
										className="btn"
										type="submit"
										onClick={handleUrlAndPin}
									>
										Сподели
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
				{chatGptAnalysis && (
					<div>
						<div className="package__table__label">
							<b>Анализ от ChatGPT</b>
						</div>
						<div
							style={{
								marginTop: "5px",
								marginBottom: "25px",
								whiteSpace: "pre-line",
							}}
						>
							{chatGptAnalysis}
						</div>
					</div>
				)}
				<div className="package__body">
					<div className="package__table">
						<div className="package__table__head">
							<div className="package__table__row">
								<div className="package__table__col large">
									<div className="package__table__label">
										Име на теста (изследване)
									</div>
								</div>

								<div className="package__table__col small">
									<div className="package__table__label">Резултат</div>
								</div>

								<div className="package__table__col small">
									<div className="package__table__label">Единици</div>
								</div>

								<div className="package__table__col">
									<div className="package__table__label">
										Референтни стойности
									</div>
								</div>

								<div className="package__table__col medium">
									<div className="package__table__label">Графика</div>
								</div>
							</div>
						</div>
					</div>

					{renderResults(results)}
				</div>

				{isModalVisible && result && (
					<Modal
						color="var(--blue)"
						onModalClose={() => setIsModalVisible(false)}
					>
						<ShareResults id={result.id} pin={result.pin} />
					</Modal>
				)}
			</PackageBought>
		);
	};

	return (
		<div>{isLoading ? <Loader /> : renderPackage(selectedUserPackage)}</div>
	);
};

export default connect(
	(state) => ({
		selectedUserPackage: state.userPackages.current,
	}),
	{
		fetchUserPackage,
	}
)(PackagesBoughtDetail);
