export const themeJson = {
	cssVariables: {
		"--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
		"--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
		"--sjs-general-backcolor-dim": "#FCFCFC",
		"--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
		"--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
		"--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
		"--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
		"--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
		"--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
		"--sjs-primary-backcolor": "rgba(50, 92, 221, 1)",
		"--sjs-primary-backcolor-light": "rgba(NaN, NaN, NaN, 0.1)",
		"--sjs-primary-backcolor-dark": "rgba(39, 114, 203, 1)",
		"--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
		"--sjs-base-unit": "8px",
		"--sjs-corner-radius": "10px",
		"--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
		"--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
		"--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
		"--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
		"--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
		"--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
		"--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
		"--sjs-shadow-inner": "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
		"--sjs-border-light": "rgba(0, 0, 0, 0.09)",
		"--sjs-border-default": "rgba(0, 0, 0, 0.16)",
		"--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
		"--sjs-special-red": "rgba(229, 10, 62, 1)",
		"--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
		"--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-special-green": "rgba(25, 179, 148, 1)",
		"--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
		"--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-special-blue": "rgba(67, 127, 217, 1)",
		"--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
		"--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-special-yellow": "rgba(255, 152, 20, 1)",
		"--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
		"--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
		"--sjs-question-background": "rgba(255, 255, 255, 1)",
		"--font-family": "Open Sans",
		"--sjs-font-surveytitle-family": "Open Sans",
		"--sjs-font-surveytitle-weight": "700",
		"--sjs-font-surveytitle-color": "rgba(22, 22, 22, 1)",
		"--sjs-font-surveytitle-size": "32px",
	},
	isPanelless: true,
	themeName: "default",
	colorPalette: "light",
};
