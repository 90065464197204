import styled from "styled-components";

export const EditComponent = styled.div`
	padding: 20px;

	.edit__title {
		text-align: center;
	}

	.modal__title {
		text-align: center;
	}

	.form__group {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 25px;
	}

	.form__single {
		width: 50%;
	}

	label {
		font-weight: bold;
	}

	.form__actions {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	
	.btn__edit {
		border: 1px solid var(--yellow);
		background-color: var(--yellow);
		transition: 0.2s;

		&:hover {
			background: var(--yellow);
    		border-color: var(--yellow);
			opacity: 0.7;
		}
	}
`;

export const FormMessage = styled.div`
	min-height: 32px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	color: ${(props) => props.color || "var(--text-color)"};
`;
