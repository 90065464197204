
import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import dayjs from "@/components/configureDayjs";

import {
	LineChart,
	Line,
	ResponsiveContainer,
	Cell,
	ScatterChart,
	Scatter,
	XAxis,
	YAxis,
	ReferenceLine,
	Legend,
	Tooltip,
} from "recharts";

import { ChartContainer } from "./styles";

const handleValues = (data) => {
	if (isEmpty(data)) return;

	let valuesArray = [];
	data.resultsAll.forEach((result) => {
		let danna = {};
		const refVal = result.referenceValues;
		if (result.createdAt) {
			danna = {
				date: result.createdAt,
				value: result.value,
				max: refVal.max,
				min: refVal.min,
				minObserve: +(refVal.min * 0.8).toFixed(3),
				maxObserve: +(refVal.max * 1.2).toFixed(3),
				maxGraph: +(refVal.max * 1.5).toFixed(3),
				minGraph: +(refVal.min * 0.5).toFixed(3),
			};
		} else {
			danna = {
				date: result.dateResults,
				value: result.value,
				max: refVal.max,
				min: refVal.min,
				minObserve: +(refVal.min * 0.8).toFixed(3),
				maxObserve: +(refVal.max * 1.2).toFixed(3),
				maxGraph: +(refVal.max * 1.5).toFixed(3),
				minGraph: +(refVal.min * 0.5).toFixed(3),
			};
		}

		valuesArray.sort((a, b) => new Date(a.date) - new Date(b.date));

		valuesArray.push(danna);
	});

	return valuesArray;
};

const findMinMaxValues = (data) => {
	let minVal = Number.MAX_VALUE;
	let maxVal = Number.MIN_VALUE;

	data.resultsAll.forEach((result) => {
		if (result.value < minVal) {
			minVal = result.value;
		}
		if (result.value > maxVal) {
			maxVal = result.value;
		}
	});

	if (minVal === maxVal) {
		minVal -= 0.4;
		maxVal += 0.4;
	}

	const range = maxVal - minVal;
	const min = parseFloat((minVal - range).toFixed(2));
	const max = parseFloat((maxVal + range).toFixed(2));
	return { min, max };
};

const handleRefValues = (data) => {
	if (isEmpty(data)) return;

	const { referenceValues } = data;

	const refMax =
		referenceValues.max === null ? Number.MAX_VALUE : referenceValues.max;
	const refMin =
		referenceValues.min === null ? Number.MIN_VALUE : referenceValues.min;

	return {
		min: refMin,
		max: refMax,
		minObserve: +(refMin * 0.8).toFixed(3),
		maxObserve: +(refMax * 1.2).toFixed(3),
		maxGraph: +(refMax * 1.5).toFixed(3),
		minGraph: +(refMin * 0.5).toFixed(3),
	};
};

const setDotColor = (value, data, index) => {
	const entry = data.resultsAll[index];
	const { referenceValues } = entry;
	const refMax =
		referenceValues.max === null ? Number.MAX_VALUE : referenceValues.max;
	const refMin =
		referenceValues.min === null ? Number.MIN_VALUE : referenceValues.min;
	const { min, max, minObserve, maxObserve } = {
		min: refMin,
		max: refMax,
		minObserve: +(refMin * 0.8).toFixed(3),
		maxObserve: +(refMax * 1.2).toFixed(3),
	};

	if (value >= min && value <= max) {
		return "var(--success)";
	} else if (
		(value > minObserve && value < min) ||
		(value > max && value < maxObserve)
	) {
		return "var(--yellow)";
	} else {
		return "var(--danger)";
	}
};

const axisStyle = {
	tickLine: false,
	axisLine: false,
	tick: { fontSize: 12, color: "var(--text-color)", opacity: 0.5, width: 40 },
};

const CustomizedDot = (props) => {
	const {
		cx,
		cy,
		payload: { value, date },
		data,
		index,
	} = props;

	if (data.packageCreatedAt === date) {
		return (
			<svg
				viewBox="0 0 22 22"
				xmlns="http://www.w3.org/2000/svg"
				width="22"
				height="22"
				x={cx - 11}
				y={cy - 11}
			>
				<circle
					cx={11}
					cy={11}
					r={10}
					fill={setDotColor(value, data, index)}
					stroke="#F4F4F9"
					strokeWidth="3"
					fillRule="evenodd"
				/>
			</svg>
		);
	}

	return (
		<svg
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			x={cx - 8}
			y={cy - 8}
		>
			<circle
				cx={8}
				cy={8}
				r={6}
				fill={setDotColor(value, data, index)}
				stroke="#F4F4F9"
				strokeWidth="3"
				fillRule="evenodd"
			/>
		</svg>
	);
};

export const ChartBiomarkerSimple = (props) => {
	let { data } = props;
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		if (data !== undefined) {
			setIsLoading(false);
		}
	}, [data]);

	if (!data.resultsAll) {
		const currentItem = data.find((item) => item.current === true);

		const refMax =
			currentItem.referenceValues.max === null
				? Number.MAX_VALUE
				: currentItem.referenceValues.max;
		const refMin =
			currentItem.referenceValues.min === null
				? Number.MIN_VALUE
				: currentItem.referenceValues.min;

		const newObj = {
			resultsAll: data,
			referenceValues: {
				min: refMin,
				max: refMax,
			},
		};
		data = newObj;
	}
	return (
		<div>
			{isLoading ? (
				<div>Зареждане...</div>
			) : (
				<ResponsiveContainer height={40}>
					<ScatterChart data={handleValues(data)}>
						<XAxis dataKey="date"
							hide />
						<YAxis
							dataKey="value"
							hide={true}
							domain={[
								handleRefValues(data).minGraph,
								handleRefValues(data).maxGraph,
							]}
						/>

						<Scatter
							shape="circle"
							line={{ stroke: "var(--border-color)", strokeWidth: 3 }}
						>
							{handleValues(data).map((entry, index) => {
								return (
									<Cell
										key={`cell-biomarker-${index}`}
										fill={setDotColor(entry.value, data, index)}
										stroke="#F4F4F9"
										strokeWidth="2"
									/>
								);
							})}
						</Scatter>
					</ScatterChart>
				</ResponsiveContainer>
			)}
		</div>
	);
};

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div
				style={{
					backgroundColor: "#fff",
					borderRadius: "8px",
					padding: "8px",
					border: "1px solid var(--border-color)",
					boxShadow: "var(--shadow)",
				}}
			>
				<p>{`Норма: ${payload[0].payload.min === null ? "Няма" : payload[0].payload.min
					} - ${payload[0].payload.max === null ? "Няма" : payload[0].payload.max
					}`}</p>
				<p>{`Стойност: ${payload[0].value}`}</p>
				<p>{`${dayjs(label).format("DD MMM YYYY")}`}</p>
			</div>
		);
	}

	return null;
};

const generateRoundedTicks = (min, max, numTicks = 4) => {
	const step = (max - min) / (numTicks - 1);
	const ticks = [min];
	for (let i = 1; i < numTicks - 1; i++) {
		ticks.push(Math.round((min + step * i) * 100) / 100);
	}
	ticks.push(max);
	return ticks;
};

export const ChartBiomarker = ({ data, startDate, endDate }) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (data !== undefined)
			setIsLoading(false);
	}, []);

	const minMaxValues = findMinMaxValues(data);

	const customTooltip = <CustomTooltip />;

	return (
		<React.Fragment>
			{isLoading ? (
				<div>Зареждане...</div>
			) : (
				<ChartContainer
					className="chart-biomarker"
					reference={handleRefValues(data)}
				>
					<div className="chart-biomarker__inner">
						<span className="chart-biomarker__bar-ref"></span>

						<ResponsiveContainer height={400}>
							<LineChart
								data={handleValues(data)}
								margin={{ top: 10, right: 10, bottom: 10, left: -20 }}
							>
								<XAxis
									dataKey="date"
									{...axisStyle}
									padding={{ left: 30, right: 30 }}
									tickFormatter={(tickItem) =>
										dayjs(tickItem).format("MMM YYYY")
									}
									domain={[startDate, endDate]}
								/>
								<YAxis
									type="number"
									dataKey="value"
									domain={[minMaxValues.min, minMaxValues.max]}
									{...axisStyle}
									ticks={generateRoundedTicks(
										minMaxValues.min < 0 ? 0 : minMaxValues.min,
										minMaxValues.max
									)}
								/>

								<ReferenceLine y={minMaxValues.min} stroke="#E2E2E6" />

								<Legend
									verticalAlign="top"
									height={36}
									iconSize={8}
									payload={[
										{
											value: "в норма",
											type: "square",
											color: "var(--success)",
										},
										{
											value: "под наблюдение",
											type: "square",
											color: "var(--yellow)",
										},
										{ value: "в риск", type: "square", color: "var(--danger)" },
									]}
								/>

								<Tooltip content={customTooltip} />

								<Line
									dataKey="value"
									stroke="#E2E2E6"
									strokeWidth="3"
									isAnimationActive={false}
									dot={<CustomizedDot data={data} />}
								/>
							</LineChart>
						</ResponsiveContainer>
					</div>
				</ChartContainer>
			)}
		</React.Fragment>
	);
};
