import { AdditionalHabitsContainer } from "../styles";
import Field from "./Field";
import { translateArray } from "./translation";

const Goal = ({ data }) => {
	console.log(data);
	return (
		<AdditionalHabitsContainer>
			<Field header={"Цел"} paragraph={translateArray(data).join(", ")} />
		</AdditionalHabitsContainer>
	);
};

export default Goal;
