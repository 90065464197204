// Import api instance
import { api } from "@/apis";

// Action types
const FETCH_FAMILY = "FETCH_FAMILY";
const ADD_FAMILY_MEMBER = 'ADD_FAMILY_MEMBER';
const EDIT_FAMILY_MEMBER = "EDIT_FAMILY_MEMBER";
const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER";

// Initial state
const initialState = {
	list: [],
};

// Action creators
export const fetchFamily = () => async (dispatch) => {
	try {
		const response = await api.get(`/users/family/list`);
		dispatch({
			type: FETCH_FAMILY,
			payload: response.data,
		});
	} catch (error) {}
};
export const addFamilyMember = (memberData) => async (dispatch) => {
  try {
    const response = await api.post('/users/family', memberData);
    dispatch({
      type: ADD_FAMILY_MEMBER,
      payload: response.data,
    });
  } catch (error) {}
};
export const editFamilyMember = (memberId, updates) => async (dispatch) => {
  try {
    const response = await api.patch(`/users/family/${memberId}`, updates);
    dispatch({
      type: EDIT_FAMILY_MEMBER,
      payload: response.data,
    });
  } catch (error) {}
};
export const deleteFamilyMember = (memberId) => async (dispatch) => {
	try {
		await api.delete(`/users/family/${memberId}`);
		dispatch({
			type: DELETE_FAMILY_MEMBER,
			payload: memberId,
		});
	} catch (error) {}
};

// Reducer
const familyReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_FAMILY:
			return { ...state, list: action.payload };
    	case ADD_FAMILY_MEMBER:
      		return { ...state, list: [...state.list, action.payload] };
    	case EDIT_FAMILY_MEMBER:
      		const updatedList = state.list.map((member) => member._id === action.payload._id ? action.payload : member);
      		return { ...state, list: updatedList };
		case DELETE_FAMILY_MEMBER:
			const updatedList2 = state.list.filter((member) => member._id !== action.payload);
			return { ...state, list: updatedList2 };
		default:
			return state;
	}
};

export default familyReducer;
