import React, { useState } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { updateUserInfo } from "@/ducks/user";
import Modal from "@/components/Modal/Modal";

import FormInput from "@/components/FormElements/FormInput";

import { Form } from "./styles";
import ChangePasswordForm from "../../auth/ChangePasswordForm";

const FormPasswordChange = ({ user, updateUserInfo }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	// const [phone, setPhone] = useState("");
	// const [errors, setErrors] = useState("");
	// const [isSuccess, setIsSuccess] = useState(false);

	// useEffect(() => {
	// 	setPhone(user.phone ? user.phone : "");
	// }, [user]);
	// const onSubmit = (event) => {
	// 	event.preventDefault();

	// 	updateUserInfo({
	// 		phone,
	// 	})
	// 		.then(() => {
	// 			setIsSuccess(true);
	// 		})
	// 		.catch((error) => {
	// 			setErrors(error.response.data.errors);
	// 		});
	// };

	// const renderErrors = () => {
	// 	return Object.values(errors).map((error, i) => {
	// 		return <p key={i}>{error}</p>;
	// 	});
	// };

	if (isEmpty(user)) return <div>Зареждаме на формата. :-)</div>;
	return (
		<>
			<Form>
				<form>
					<div className="form__cols">
						<div className="form__col">
							<div className="form__row">
								<FormInput
									label="Имейл"
									name="email"
									type="email"
									placeholder="you@gmail.com"
									value={user.email}
									readonly
								/>
							</div>
						</div>
					</div>
					{isModalVisible && (
						<Modal onModalClose={() => setIsModalVisible(false)} color={'#fff'}>
							<ChangePasswordForm user={user} close={() => setIsModalVisible(false)} />
						</Modal>
					)}
					{/* <div className="form__col">
					<div className="form__row">
						<FormInput
							label="Телефон"
							name="telephone"
							type="tel"
							placeholder="0888 123 456"
							value={phone}
							onChange={e => {setPhone(e.target.value.replace(' ', ''))}}
						/>
					</div>
				</div>

				{isSuccess && (
					<div className="form__success">
						<p>Успешно обновихте данните си!</p>
					</div>

				)}

				<div className="form__error">{renderErrors()}</div>

				<div className="form__actions">
					<button className="btn" type="submit">
						Запази промените
					</button>
				</div> */}
				</form>
				<button className="btn" onClick={() => setIsModalVisible(true)}>
					Промени парола
				</button>
			</Form>
		</>
	);
};

export default connect(
	(state) => ({
		user: state.user,
	}),
	{
		updateUserInfo,
	}
)(FormPasswordChange);
