import styled from "styled-components";

export const LoginComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .login__desc {
        font-size: 18px;
        margin: 0;
        padding: 0 30px;
        text-align: center;
    }

    .login__names {
        font-size: 18px;
        margin-top: 30px;
        font-weight: 500;
    }
`;