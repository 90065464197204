import React, { useState, useEffect } from "react";
import { api } from "@/apis";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";

import Loader from "@/components/Loader/LoaderBig";
import Section from "@/components/section/Section/Section";
import Accordion from "@/components/Accordion/Accordion";
import BiomarkerSimple from "@/components/biomarkers/BiomarkerSimple/BiomarkerSimple";
import BiomarkerDetail from "@/components/biomarkers/BiomarkerDetail/BiomarkerDetail";
import Filters from "@/components/filters/Filters";

import { NoResults, Biomarkers } from "./styles";

const DashboardOverview = (props) => {
	const { user } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [filteredPackages, setFilteredPackages] = useState([]);
	const [filters, setFilters] = useState({
		label: "*",
	});

	const history = useHistory();

	const onFilterChange = (name, filter) => {
		setFilters({
			...filters,
			[name]: filter,
		});
	};

	useEffect(() => {
		// Redirect if user role is admin
		if (user.role === "admin") {
			history.push("/dashboard/admin");
		}
	}, [user, history]);

	// Fetch user health bundles overview
	useEffect(() => {
		setFilters({
			label: props.location.hash.replace("#", "") || "*",
		});

		const fetchUserHealthBundles = async () => {
			try {
				const response = await api.get("/user-health-bundles/overview");
				setFilteredPackages(response.data.results);
			} catch (error) {
				console.error("Error fetching user health bundles:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchUserHealthBundles();
	}, [props.location.hash]);

	const renderResults = (results) => {
		if (isEmpty(results)) return;

		return results
			.filter((result) => {
				if (filters["label"] === "*") {
					return true;
				}

				return result.label === filters.label;
			})
			.map((result) => {
				if (result.value === null) {
					return null;
				}
				return (
					<Accordion key={`biomarker-${result._id}`}>
						<Accordion.Head>
							<BiomarkerSimple
								title={result.label}
								result={result.results[0].value}
								unit={result.metric}
								id={result._id}
								referent={{
									min:
										result.results[0].referenceValues.min === null
											? "Няма"
											: result.results[0].referenceValues.min
									,
									max:
										result.results[0].referenceValues.max === null
											? "Няма"
											: result.results[0].referenceValues.max
								}}
								biomarkerHistory={{ id: result._id, results: result.results }}
							/>
						</Accordion.Head>

						<Accordion.Body>
							<BiomarkerDetail
								id={result._id}
								description={result.description}
								biomarkerHistory={{ id: result._id, minDate: result.minDate, maxDate: result.maxDate, results: result.results }}
							/>
						</Accordion.Body>
					</Accordion>
				);
			});
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Loader />
			) : isEmpty(filteredPackages) ? (
				<NoResults>
					<h3 className="noresults__title">Няма налични резултати.</h3>
				</NoResults>
			) : (
				<Section>
					<Section.Head flex>
						<h3 style={{ marginBottom: "20px" }}>Моите резултати</h3>
						<Filters>
							<Filters.Filter
								value={filters.label}
								label="Сортирай по резултат"
								name="label"
								onChange={(e) => onFilterChange("label", e.target.value)}
								options={[
									{ value: "*", label: "Всички" },
									{ value: "normal", label: "В норма" },
									{ value: "observation", label: "Под наблюдение" },
									{ value: "risk", label: "В риск" },
								]}
							/>
						</Filters>
					</Section.Head>

					<Section.Body>
						<Biomarkers>
							<div className="package__table">
								<div className="package__table__head">
									<div className="package__table__row">
										<div className="package__table__col large">
											<div className="package__table__label">
												Име на теста (изследване)
											</div>
										</div>

										<div className="package__table__col small">
											<div className="package__table__label">Резултат</div>
										</div>

										<div className="package__table__col small">
											<div className="package__table__label">Единици</div>
										</div>

										<div className="package__table__col">
											<div className="package__table__label">
												Референтни стойности
											</div>
										</div>

										<div className="package__table__col medium">
											<div className="package__table__label">Графика</div>
										</div>
									</div>
								</div>
							</div>
							{renderResults(filteredPackages)}
						</Biomarkers>
					</Section.Body>
				</Section>
			)}
		</React.Fragment>
	);
};

export default connect((state) => ({
	currentUserDetails: state.currentUserDetails,
	user: state.user,
}))(DashboardOverview);
