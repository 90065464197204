// User Ducks
import dayjs from '@/components/configureDayjs';
import { api } from '@/apis';

// Actions Types
const FETCH_USER = 'user/FETCH_USER';
const UPDATE_USER_INFO = 'user/UPDATE_USER_INFO';

// Action Creators
export const fetchUserInfo = () => async dispatch => {
	const response = await api.get('/users/me')

	dispatch({
		type: FETCH_USER,
		payload: response.data
	});
}

export const updateUserInfo = payload => async dispatch => {
	try {
		const response = await api.put('/users/me', payload);

		dispatch({
			type: UPDATE_USER_INFO,
			payload: response.data
		})

		return Promise.resolve(response);
	}
	catch (error) {
		return Promise.reject(error);
	}
};

const initState = {
	role: '',
	_id: '',
	email: '',
	firstName: '',
	lastName: '',
	activated: '',
	medicalProfile: {
		_id: '',
		birthDate: '',
		gender: '',
		height: '',
		weight: '',
		bloodType: '',
		years: '',
	},
	createdAt: '',
	updatedAt: '',
	avatar: {
		_id: '',
		name: '',
		mimetype: '',
		url: '',
	},
	phone: '',
	initials: '',
};

// Reducers
export default function reducer (state = initState, action = {}) {
	switch (action.type) {
		case FETCH_USER:
			let years;

            if (action.payload.medicalProfile && action.payload.medicalProfile.birthDate) {
                years = dayjs().diff(dayjs(action.payload.medicalProfile.birthDate), 'y');
            } else {
                years = null;
            }
			
			const initials = `${action.payload.firstName.slice(0,1)}${action.payload.lastName.slice(0,1)}`;

			return {
				...state,
				...action.payload,
				initials,
				medicalProfile: {
					...state.medicalProfile,
					...action.payload.medicalProfile,
					years
				}
			}

		case UPDATE_USER_INFO:
			return {
				...state,
				...action.payload,
				medicalProfile: {
					...state.medicalProfile,
					...action.payload.medicalProfile
				}
			}

		default:
			return state;
	}
}
