// User Packages Ducks
import dayjs from '@/components/configureDayjs';
import { api } from '@/apis';

// Actions Types
const FETCH_USER_PACKAGES = 'user-packages/FETCH_USER_PACKAGES';
const FETCH_USER_PACKAGE = 'user-packages/FETCH_USER_PACKAGE';

// Helper functions
const setResultsLabels = results => {
	return results.map(result => {
		const value = result.value;
		const min = result.referenceValues.min;
		const max = result.referenceValues.max;
		const minObserve = result.referenceValues.min * .8;
		const maxObserve = result.referenceValues.max * 1.2;

		if(value){
			if ((min === null || value >= min) && (max === null || value <= max)) {
				result.label = 'normal';
			} else if ((minObserve !== null && min !== null && value > minObserve && value < min) || (max !== null && maxObserve !== null && value > max && value < maxObserve)) {
				result.label = 'observation';
			} else {
				result.label = 'risk';
			}
		}

		return result;
	});
}

const parseDate = date => {
	return {
		day: dayjs(date).format('DD'),
		month: dayjs(date).format('MMMM'),
		year: dayjs(date).format('YYYY'),
	}
}

// Action Creators
export const fetchUserPackages = payload => async dispatch => {
	const response = await api.get('/user-health-bundles');

	dispatch({
		type: FETCH_USER_PACKAGES,
		payload: response.data
	});
};

export const fetchUserPackage = id => async dispatch => {

	try {
		const biomarkerResultsAll = {};
		const promises = [];
		const { data } = await api.get(`/user-health-bundles/${id}`);

		// Hit endpoint for each biomarker results in the current package and push it in promise
		for (let i = 0; i < data.results.length; i++) {
			promises.push(api.get(`/biomarkers/${data.results[i].biomarker._id}/results`));
		}

		const responses = await Promise.all(promises);

		// Handle data to have needed fields for parsing data in biomarkers
		responses.forEach((response, i) => {
			const { biomarker, referenceValues, value } = data.results[i];

			const biomarkerResults = {
				id: biomarker._id,
				label: biomarker.label,
				metric: biomarker.metric,
				referenceValues,
				packageCreatedAt: data.createdAt,
				currentPackageBiomarkerValue: value,
			}

			biomarkerResults.resultsAll = response.data;
			biomarkerResultsAll[biomarker._id] = biomarkerResults;
		});

		dispatch({
			type: FETCH_USER_PACKAGE,
			payload: {
				biomarkerResultsAll,
				current: {
					...data,
					date: parseDate(data.dateResults),
					results: setResultsLabels(data.results),
				}
			}
		});

		return Promise.resolve(data);
	}
	catch (error) {
		return Promise.reject(error);
	}
};

const initState = {
	list: [],
	current: {},
	biomarkerResults: {}
};

// Reducers
export default function reducer (state = initState, action = {}) {
	switch (action.type) {
		case FETCH_USER_PACKAGES:
			const list = action.payload
				.map(item => {
					// Set date object with separate day, month, year keys
					item.date = parseDate(item.dateResults);
					item.results = setResultsLabels(item.results);

					return item;
				})
				.sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));

			return { ...state, list };

		case FETCH_USER_PACKAGE:
			return {
				...state,
				current: action.payload.current,
				biomarkerResults: action.payload.biomarkerResultsAll
			};

		default:
			return state;
	}
}
