import React from "react";
import { connect } from "react-redux";

import { ChartBiomarkerSimple } from "@/components/charts/ChartBiomarker/ChartBiomarker";

import { Biomarker } from "./styles";

const BiomarkerSimple = (props) => {
	const {
		title,
		result,
		unit,
		id,
		referent,
		biomarkerResults,
		sharedResults,
		packageId,
		biomarkerHistory,
	} = props;

	const transformBiomarkerHistory = (biomarkerHistory) => {
		const resultsAll = biomarkerHistory.results
			.slice()
			.reverse()
			.map((result) => ({
				createdAt: result.dateResults,
				referenceValues: result.referenceValues,
				value: result.value,
			}));

		const transformedData = {
			id: biomarkerHistory.id,
			currentPackageBiomarkerValue: biomarkerHistory.results[0].value,
			referenceValues: {
				min: biomarkerHistory.results[0].referenceValues.min,
				max: biomarkerHistory.results[0].referenceValues.max,
			},
			resultsAll: resultsAll,
		};

		return transformedData;
	};

	return (
		<Biomarker className="biomarker">
			<div className="biomarker__row">
				<div className="biomarker__col large">
					<h4 className="biomarker__title">{title}</h4>
				</div>

				<div className="biomarker__col small">
					<div className="biomarker__desc__mobile">Резултат</div>
					<div className="biomarker__text">{result}</div>
				</div>

				<div className="biomarker__col small">
					<div className="biomarker__desc__mobile">Единици</div>
					<div className="biomarker__text">{unit}</div>
				</div>

				<div className="biomarker__col">
					<div className="biomarker__desc__mobile">Референтни стойности</div>
					<div className="biomarker__text">
						{referent.min} - {referent.max}
					</div>
				</div>

				<div className="biomarker__col medium">
					<div className="biomarker__desc__mobile">Графика</div>
					{biomarkerHistory && typeof biomarkerHistory !== "undefined" ? (
						<ChartBiomarkerSimple
							data={transformBiomarkerHistory(biomarkerHistory)}
						/>
					) : Object.keys(biomarkerResults).length > 0 &&
						biomarkerResults[id] ? (
						<ChartBiomarkerSimple data={biomarkerResults[id]} />
					) : (
						sharedResults?.[packageId]?.results?.[id] && (
							<ChartBiomarkerSimple
								data={sharedResults[packageId].results[id]}
							/>
						)
					)}
				</div>
			</div>
		</Biomarker>
	);
};

export default connect((state) => ({
	biomarkerResults: state.userPackages.biomarkerResults,
	sharedResults: state.sharedResults.list,
}))(BiomarkerSimple);
