import styled from "styled-components";

export const Profile = styled.div`
	text-align: center;
	.btn__logout {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		margin-left: 40px;
	}
`;
export const ProfileImage = styled.div`
	position: relative;
	overflow: hidden;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-bottom: 5px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
	font-size: 28px;
	color: ${(props) =>
		props.gender === "female" ? "var(--danger)" : "var(--blue)"};

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: ${(props) =>
			props.gender === "female" ? "var(--danger)" : "var(--blue)"};
		opacity: 0.2;
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}
`;
export const ProfileName = styled.div`
	font-size: 18px;
	font-weight: 500;
`;
