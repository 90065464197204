import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const CustomLoader = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: conic-gradient(#0000 10%, #15f472);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
	animation: ${rotate} 1s infinite linear;
`;
