import styled from "styled-components";

export const Section = styled.section`
	margin-bottom: 40px;

	&:last-child {
		// margin-bottom: 0;
	}
	margin: ${(props) => (props.addMargin ? "3rem" : "0")};

	@media (max-width: 599px) {
		margin: 0;
	}
`;
export const SectionHead = styled.div`
	display: ${({ flex }) => (flex ? "flex" : "block")};
	justify-content: space-between;

	h3 {
		margin-top: 0;
	}

	.section__back {
		margin-bottom: 16px;
	}

	.btn__logout {
		display: flex;
		gap: 5px;
	}

	@media (max-width: 768px) {
		flex-direction: ${({ flexDirection }) =>
			flexDirection ? "row" : "column"};
	}
`;
export const SectionBody = styled.div``;
export const SectionEntry = styled.div`
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}

	& + .packages {
		margin-top: 24px;
	}
`;
