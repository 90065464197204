import React from "react";
import { withRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import faqData from "./utils/faqData";
// import teamData from "./utils/teamData";
import strategyData from "./utils/strategyData";
import Nav from "./Nav";
import Header from "./Header";
import Customers from "./Customers";
// import Value from "./Value";
import Strategy from "./Strategy";
import Faq from "./Faq";
import Footer from "./Footer";
import Benefits from "./Benefits";
// import Team from "./Team";
import Process from "./Process";
import JoinBanner from "./JoinBanner";

const LandingPage = (props) => {
	return (
		<>
			<GlobalStyle />
			<Nav />
			<Header />
			<Customers />
			{/* <Value /> */}
			<Strategy data={strategyData} />
			<Process />
			<JoinBanner />
			<Benefits />
			{/* <Team teamMembers={teamData} /> */}
			<Faq data={faqData} />
			<Footer />
		</>
	);
};

const GlobalStyle = createGlobalStyle`
	body {
		font-family: 'Inter', sans-serif;
		color: #333333;
	}
	img {
		user-select: none;
		pointer-events: none;
	}
`;

export default withRouter(LandingPage);
