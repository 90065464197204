import React from "react";
import { connect } from "react-redux";
import {
	ChartBiomarker,
	ChartBiomarkerSimple,
} from "@/components/charts/ChartBiomarker/ChartBiomarker";
import { BiomarkerContainer } from "./styles";

const BiomarkerDetail = (props) => {
	const {
		id,
		description,
		biomarkerResults,
		sharedResults,
		packageId,
		biomarkerHistory,
		startDate,
		endDate,
	} = props;

	const transformBiomarkerHistory = (biomarkerHistory) => {
		const resultsAll = biomarkerHistory.results
			.slice()
			.reverse()
			.map((result) => ({
				createdAt: result.dateResults,
				referenceValues: result.referenceValues,
				value: result.value,
			}));

		const transformedData = {
			id: biomarkerHistory.id,
			currentPackageBiomarkerValue: biomarkerHistory.results[0].value,
			referenceValues: {
				min: biomarkerHistory.results[0].referenceValues.min,
				max: biomarkerHistory.results[0].referenceValues.max,
			},
			resultsAll: resultsAll,
		};

		return transformedData;
	};

	return (
		<BiomarkerContainer className="biomarker-detail">
			<div className="biomarker-detail__inner">
				<div className="biomarker-detail__media">
					{biomarkerHistory ? (
						<ChartBiomarker
							startDate={startDate}
							endDate={endDate}
							data={transformBiomarkerHistory(biomarkerHistory)}
						/>
					) : Object.keys(biomarkerResults).length > 0 &&
					  biomarkerResults[id] ? (
						<ChartBiomarker
							startDate={startDate}
							endDate={endDate}
							data={biomarkerResults[id]}
						/>
					) : (
						sharedResults[packageId] &&
						sharedResults[packageId].results &&
						sharedResults[packageId].results[id] && (
							<ChartBiomarkerSimple
								startDate={startDate}
								endDate={endDate}
								data={sharedResults[packageId].results[id]}
							/>
						)
					)}
				</div>

				<div className="biomarker-detail__content">
					<div className="biomarker-detail__entry">
						{description && <h4>Информация за биомаркера</h4>}

						{description && <p>{description}</p>}
					</div>
				</div>
			</div>
		</BiomarkerContainer>
	);
};

export default connect((state) => ({
	biomarkerResults: state.userPackages.biomarkerResults,
	sharedResults: state.sharedResults.list,
}))(BiomarkerDetail);
