import React from "react";
import history from '@/components/history';
import { api } from '@/apis';
import { connect } from 'react-redux';
import { signIn } from '@/ducks/auth';
import { fetchUserInfo } from '@/ducks/user';
import { fetchUserPackages } from '@/ducks/userPackages';
import { fetchCurrentUserDetails } from '@/ducks/currentUserDetails';
import { H } from "highlight.run";

import {
	LoginComponent,
} from './styles';  

const LoginMember = ({ profile, closeModal, signIn, fetchUserInfo, fetchUserPackages }) => {

	const handleLogin = () => {
		api.post(`/users/family/${profile._id}`).then((response) => {
			if (response.status === 200) {
				H.identify(`${profile.firstName} ${profile.lastName}`, {
					userId: `${profile._id}`
				});
				history.push('/dashboard');
				signIn();
				fetchUserInfo();
				fetchUserPackages();
				fetchCurrentUserDetails();
			}
		}).catch((error) => {
			// Can be used to show a message to the user
		});
		closeModal();
	};

	return (
		<LoginComponent>
            <h2 className="login__title">Влизане в профил</h2>
            <p className="login__desc">Сигурни ли сте, че искате да влезете в този профил?</p>
            <p className="login__names">
				{profile.firstName} {profile.lastName}
			</p>
            <div className="login__btns">
                <button className="btn btn__login" type="button" onClick={handleLogin}>Влез</button>
            </div>
        </LoginComponent>
	);
};

export default connect(
	null, 
	{
		fetchUserPackages,
		fetchCurrentUserDetails,
		fetchUserInfo,
		signIn
	}
  )(LoginMember);