import React, { useState } from 'react';

import {
	Tabs
} from './styles'

const TabsComponent = (props) => {
	const [ activeTabIndex, setActiveTabIndex ] = useState(0);

	const handleTabClick = (tabIndex) => {
		setActiveTabIndex(tabIndex === activeTabIndex ? activeTabIndex : tabIndex)
	}

	const renderChildrenWithTabsApiAsProps = () => {
		return React.Children.map(props.children, (child, index) => {
			return React.cloneElement(child, {
				onClick : handleTabClick,
				tabIndex: index,
				isActive: index === activeTabIndex
			});
		});
	}

	const renderActiveTabContent = () => {
		const {children} = props;

		if (!children) return;

		if(children[activeTabIndex]) {
			return children[activeTabIndex].props.children;
		}
	}

	return (
		<Tabs className="tabs">
			<div className="tabs__head">
				<ul className="tabs__nav">
					{renderChildrenWithTabsApiAsProps()}
				</ul>
			</div>

			<div className="tabs__body">
				{renderActiveTabContent()}
			</div>
		</Tabs>
	);
}

TabsComponent.Tab = (props) => {
	const { tabIndex, linkName, isActive, onClick } = props;

	const handleTabClick = (event) => {
		event.preventDefault();

		onClick(tabIndex);
	}

	return (
		<li className="tab">
			<button type="button" role="tab" aria-controls="" aria-selected={isActive ? 'true' : 'false'} className={`tab__link ${isActive ? 'active' : ''}`}
			onClick={handleTabClick}>
				{linkName}
			</button>
		</li>
	);
}

export default TabsComponent;
