import { useEffect, useRef, useState } from "react";

const useIntoView = (el) => {
	const [inView, setInView] = useState(false);
	const observer = useRef(null);

	useEffect(() => {
		observer.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => entry.isIntersecting && setInView(true));
			},
			{ threshold: window.innerWidth <= 500 ? 0.1 : 0.3 }
		);
		observer.current.observe(el.current);

		return () => {
			observer.current.disconnect();
		};
	}, [el]);

	return inView;
};

export default useIntoView;
