import React, { useState } from 'react';
import history from '@/components/history';
import { api } from '@/apis';
import FormInput from '@/components/FormElements/FormInput';

import {
	Form,
	FormTitle,
	FormRow,
	FormActions,
	FormMessage,
} from './styles';

const ResetPasswordForm = props => {
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [errors, setErrors] = useState('');
	const [hasError, setHasError] = useState(false);
	const [isPasswordValid, setIsPasswordValid] = useState(false);

	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return (
					<p key={i}>{error}</p>
				);
			});
		}
	}

	const onSubmit = (event) => {
		event.preventDefault();

		if (password !== passwordRepeat) {
			setIsPasswordValid(false);
			setHasError(true);
			setErrors({ error: 'Паролите не съвпадат' });
			return;
		}
		const userCode = window.location.pathname.replace('/auth/reset-password/', '');

		api.post('/users/password/reset', {
			userCode,
			password,
		})
			.then(response => {
				setIsPasswordValid(true);
				setHasError(false);

				history.push('/auth');
			})
			.catch(e => {
				setErrors(e.response.data.errors || { error: e.response.data.message });
				setIsPasswordValid(false);
				setHasError(true);
			})

	}

	return (
		<Form onSubmit={onSubmit}>
			<FormTitle>Презаписване на паролата!</FormTitle>
			<FormRow>
				<FormInput
					label="Парола"
					name="password"
					type="password"
					placeholder="Въведи нова парола"
					value={password}
					required
					onChange={e => { setPassword(e.target.value) }}
				/>
			</FormRow>

			<FormRow>
				<FormInput
					label="Повтори парола"
					name="passwordRepeat"
					type="password"
					placeholder="Повтори новата парола"
					value={passwordRepeat}
					required
					onChange={e => { setPasswordRepeat(e.target.value) }}
				/>
			</FormRow>

			{hasError &&
				<FormMessage color="var(--danger)">
					{renderErrors()}
				</FormMessage>
			}

			{isPasswordValid &&
				<FormMessage color="var(--success-dark)">
					<p>Паролата е успешно презаписана! <br />След 5 секунди ще бъдете пренасочен към логин страницата за да влезете с акаунта си.</p>
				</FormMessage>
			}

			<FormActions>
				<button className="btn btn--block" type="submit">
					Изпрати имейл
				</button>
			</FormActions>
		</Form>
	);
}

export default ResetPasswordForm;
