import React, { useState } from "react";
import { useDispatch } from "react-redux";

import FormInput from "@/components/FormElements/FormInput";
import Datepicker from "@/components/FormElements/Datepicker";

import { editFamilyMember } from "@/ducks/family";

import { EditComponent, FormMessage } from "./styles";

const EditMember = ({ id, closeModal, profile }) => {
	const [firstName, setFirstName] = useState(profile.firstName);
	const [lastName, setLastName] = useState(profile.lastName);
	const [birthDate, setBirthDate] = useState(
		new Date(profile.medicalProfile.birthDate)
	);
	const [gender, setGender] = useState(profile.medicalProfile.gender);
	const [errors, setErrors] = useState("");

	const dispatch = useDispatch();

	const handleEditClick = async (e) => {
		e.preventDefault();

		if (new Date(birthDate) > new Date()) {
			setErrors({ message: "Невалидна дата" });
			return;
		}
		if (
			firstName === "" ||
			lastName === "" ||
			gender === "" ||
			birthDate === ""
		) {
			setErrors({ message: "Моля попълнете всички полета" });
			return;
		}

		dispatch(
			editFamilyMember(id, {
				firstName,
				lastName,
				medicalProfile: {
					birthDate,
					gender,
				},
			})
		);

		closeModal();
	};

	const renderErrors = () => {
		if (errors != null) {
			return Object.values(errors).map((error, i) => {
				return <p key={i}>{error}</p>;
			});
		}
	};

	return (
		<EditComponent>
			<h2 className="edit__title">Промени данните на член от семейството</h2>
			<form className="modal__form" onSubmit={handleEditClick}>
				<div className="form__group">
					<div className="form__single">
						<FormInput
							label="Име"
							name="firstname"
							type="firstname"
							placeholder="Иван"
							value={firstName}
							onChange={(e) => {
								setFirstName(e.target.value);
							}}
						/>
					</div>
					<div className="form__single">
						<FormInput
							label="Фамилия"
							name="lastname"
							type="lastname"
							placeholder="Иванов"
							value={lastName}
							onChange={(e) => {
								setLastName(e.target.value);
							}}
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="form__single">
						<Datepicker
							label="Рождена дата"
							name="birthDate"
							type="text"
							placeholder="20/10/1990"
							value={birthDate}
							onChange={(date) => {
								setBirthDate(date);
							}}
							tooltipMessage="Рождената дата е нужна за правилното определяне на референтните стойности на биомаркерите."
						/>
					</div>
					<div className="form__single">
						<FormInput
							label="Пол"
							name="gender"
							placeholder="Пол"
							value={gender}
							options={[
								{ value: "", label: "Избери пол" },
								{ value: "male", label: "Мъж" },
								{ value: "female", label: "Жена" },
							]}
							onChange={(e) => {
								setGender(e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="form__actions">
					<button className="btn btn__edit" type="submit">
						Запази
					</button>
					<FormMessage color="var(--danger)">{renderErrors()}</FormMessage>
				</div>
			</form>
		</EditComponent>
	);
};

export default EditMember;
