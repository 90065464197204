import React, { createContext, useContext, useRef, useState } from "react";
import { Accordion } from "./styles";
import { api } from "@/apis";

export const accordionContext = createContext();

const AccordionComponent = ({
	children,
	id,
	gptResponseBuffered,
	showAnalyzeButtonInit,
}) => {
	const [active, setActive] = useState("");
	const [height, setHeight] = useState("0px");
	const [gptResponse, setGptResponse] = useState(gptResponseBuffered);
	const [showAnalyzeButton, setShowAnalyzeButton] = useState(
		showAnalyzeButtonInit
	);
	const [disabledButton, setDisableButton] = useState(false);
	const content = useRef(null);

	const toggleAccordion = () => {
		setActive(active === "" ? "active" : "");
		setHeight(
			active === "active" ? "0px" : `${content.current.scrollHeight}px`
		);
	};

	const gptResponseCallback = () => {
		setDisableButton(true);
		setShowAnalyzeButton(false);

		api
			.post(`/ai/${id}`)
			.then((data) => {
				setDisableButton(false);
				setShowAnalyzeButton(false);
				setGptResponse(data.data.response);
				if (active) setHeight(`${content.current.scrollHeight}px`);
				else toggleAccordion();
			})
			.catch((e) => {
				setDisableButton(false);
				setShowAnalyzeButton(true);
			});
	};

	return (
		<Accordion className={`accordion ${active}`}>
			<accordionContext.Provider
				value={{
					toggleAccordion,
					content,
					height,
					gptResponse,
					gptResponseCallback,
					disabledButton,
					showAnalyzeButton,
				}}
			>
				{children}
			</accordionContext.Provider>
		</Accordion>
	);
};

AccordionComponent.Head = function AccordionHead({ children }) {
	const { toggleAccordion } = useContext(accordionContext);
	return (
		<div className="accordion__head">
			{children}

			<button
				type="button"
				className="accordion__toggle"
				onClick={toggleAccordion}
			>
				<svg
					width="18"
					height="10"
					viewBox="0 0 18 10"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17 1L9 8.879 1 1"
						stroke="currentColor"
						strokeWidth="1.091"
						fill="none"
						fillRule="evenodd"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	);
};

AccordionComponent.Body = function AccordionBody({ children }) {
	const { content, height } = useContext(accordionContext);

	return (
		<div
			className="accordion__body"
			ref={content}
			style={{ maxHeight: `${height}` }}
		>
			<div className="accordion__body__inner">{children}</div>
		</div>
	);
};

export default AccordionComponent;
