import React from "react";

import { Section, SectionHead, SectionBody, SectionEntry } from "./styles";

const SectionComponent = ({ children, addMargin }) => {
	return <Section addMargin={addMargin}>{children}</Section>;
};

SectionComponent.Head = ({ children, flex, flexDirection }) => {
	return (
		<SectionHead flex={flex} flexDirection={flexDirection}>
			{children}
		</SectionHead>
	);
};

SectionComponent.Body = ({ children }) => {
	return <SectionBody>{children}</SectionBody>;
};

SectionComponent.Entry = ({ children }) => {
	return <SectionEntry>{children}</SectionEntry>;
};

export default SectionComponent;
