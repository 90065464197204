import { combineReducers } from "redux";

import auth from "./auth";
import userPackages from "./userPackages";
import user from "./user";
import laboratories from "./laboratories";
import sharedResults from "./sharedResults";
import family from "./family";
import currentUserDetails from "./currentUserDetails";

export default combineReducers({
	auth,
	userPackages,
	user,
	laboratories,
	sharedResults,
	family,
	currentUserDetails,
});
