// Navbar.js
import React from "react";
import styled from "styled-components";
import logoSrc from "@/assets/images/logo-image.png";
import { HoverBottomAnimation, SlideUp } from "./styles";
import { useRef } from "react";
import useIntoView from "../../hooks/useIntoView";
import { Link } from "react-router-dom";
import { media } from "../../GlobalStyles";

const Nav = styled(SlideUp)`
	display: flex;
	justify-content: space-between;
	max-width: 1200px;
	margin: 0 auto;
	align-items: center;
	padding: 20px 0;
	background-color: #fff;
	${media.md`
		max-width: 343px;`}
`;

const Logo = styled.h1`
	user-select: none;
	display: flex;
	margin: 0;
	font-size: 26px;
	align-items: center;
	& > img {
		height: 40px;
		margin-right: 15px;
	}
	${media.md`
		font-size: 18px;
		& > img {
			height: 25px;
			margin-right: 5px;
		}`}
`;

const NavItems = styled.div`
	display: flex;
	align-items: center;
	gap: 45px;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
`;

const NavItem = styled(HoverBottomAnimation)`
	text-decoration: none;
	color: #333333;

	border: 0;
	position: relative;

	${media.md`
		display: none;`}
`;

const LoginButton = styled(Link)`
	background-color: #060464;
	color: #fff;
	padding: 6px 25px;
	border-radius: 6px;
	line-height: 24px;
	text-decoration: none;
	border: 0;
	position: relative;
`;

const Navbar = () => {
	const observeEl = useRef(null);
	const seen = useIntoView(observeEl);
	return (
		<Nav ref={observeEl} seen={seen}>
			<Logo>
				<img src={logoSrc} alt="NeoLife лого" />
				NeoLife
			</Logo>
			<NavItems>
				<NavItem as={Link} to="/">
					Home
				</NavItem>
				<NavItem as="a" href="#strategy">
					What we offer
				</NavItem>
				<NavItem as="a" href="#process">
					How it works
				</NavItem>
				<NavItem as="a" href="#benefits">
					Medicine 3.0
				</NavItem>
				{/* <NavLink to="/team">Team</NavLink> */}
				<NavItem as="a" href="#faq">
					FAQ
				</NavItem>
				<LoginButton to="/auth">Log In</LoginButton>
			</NavItems>
		</Nav>
	);
};

export default Navbar;
