import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from '@/components/configureDayjs';

function PrivateRoute({ isSignedIn, expTimestamp, component: Component, ...rest }) {
	const isTimestampValid = dayjs().diff(dayjs(expTimestamp)) < 0;

	return (
		<Route
			{...rest}
			render={(props) =>
				isSignedIn && isTimestampValid ? (
					<Component {...props} />
				) : (
					<Redirect to="/auth" />
				)
			}
		/>
	);
}

export default connect(
	state => ({
		isSignedIn: state.auth.isSignedIn,
		expTimestamp: state.auth.expTimestamp
	})
)(PrivateRoute);
