import styled from 'styled-components';

export const FormContainer = styled.div`
	padding: 40px 40px 20px;

	.form-results__head {
		margin-bottom: 25px;
		text-align: center;
	}

	.form-results__title {
		margin: 0 0 15px;
	}

	.form-results__entry {
		font-size: 14px;
	}

	form {
		max-width: 340px;
		width: 100%;
		margin: 0 auto;
	}

	.form-results__row {
		margin-bottom: 30px;
	}

	.form-results__error {
		color: var(--danger);
	}

	.form-results__actions {
		margin: 0 -5px;
		text-align: center;

		.btn {
			margin: 0 5px 10px;
		}
	}

	.field__label span {
		color: var(--danger);
	}

	.loading__msg__hidden {
		display: none;
	}

	.loading__msg {
		display: block;
		text-align: center;
	}

	.loading {
		position: relative;
		cursor: wait;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			opacity: .6;
		}
	}
`;
