import styled from 'styled-components';

export const Biomarker = styled.div`
	.biomarker__row {
		display: flex;
		align-items: center;
	}

	.biomarker__col {
		width: 22%;

		&.small {
			width: 14%;
		}

		&.medium {
			width: 24%;
		}

		&.large {
			width: 26%;
		}
	}

	.biomarker__title {
		margin: 0;
	}

	.biomarker__desc__mobile {
		display: none;
	}

	@media (max-width: 768px) {
		.biomarker__row {
			flex-wrap: wrap;
			gap: 20px 22px;
		}

		.biomarker__col {
			width: 100%;
			flex-basis: 45%;
	
			&.small {
				width: 100%;
			}
	
			&.medium {
				width: 100%;
			}
	
			&.large {
				width: 100%;
				flex-basis: 100%;
			}
		}

		.biomarker__desc__mobile {
			display: block;
			font-size: 11px;
		}
	}
`;
