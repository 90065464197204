import axios from "axios";
import history from "@/components/history";

export const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api`,
	withCredentials: true,
});

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			localStorage.removeItem("persist:root");

			history.push("/auth");
		}

		return Promise.reject(error);
	}
);
