import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import isEmpty from "lodash/isEmpty";

import { api } from "@/apis";
import ProfileSmall from "@/components/profile/ProfileSmall";
import history from "@/components/history";

import { fetchFamily } from "@/ducks/family";
import { signIn } from "@/ducks/auth";
import { fetchUserInfo } from "@/ducks/user";
import { fetchUserPackages } from "@/ducks/userPackages";
import { fetchCurrentUserDetails } from "@/ducks/currentUserDetails";

import viewSrc from "@/assets/images/ico-view.svg";
import buyPackageSrc from "@/assets/images/ico-buy-package.svg";
import consultancySrc from "@/assets/images/ico-consultancy.svg";
import profileSrc from "@/assets/images/ico-profile.svg";

import {
	Sidebar,
	SidebarProfile,
	SidebarNav,
	SidebarNavItem,
	SidebarNavLink,
	SidebarNavLinkIcon,
	SidebarToggle,
	SidebarAddResults,
	SidebarBottom,
} from "./styles";

const listLinks = [
	{
		name: "Изследвания",
		link: "/dashboard/examinations",
		icon: consultancySrc,
		exact: true,
		role: "family",
	},
	{
		name: "Резултати",
		link: "/dashboard",
		icon: viewSrc,
		exact: true,
		role: "family",
	},
	{
		name: "Протоколи", //TODO: Програма?
		link: "/dashboard/protocols",
		icon: buyPackageSrc,
		exact: true,
		role: "family",
	},
	{
		name: "Профил",
		link: "/dashboard/profile",
		icon: profileSrc,
		role: "family",
	},
	{
		name: "Админ панел",
		link: "/dashboard/admin",
		icon: profileSrc,
		role: "admin",
	},

];

const DashboardSidebar = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { user, fetchFamily } = props;

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const loginToMain = () => {
		api
			.post("/users/family/login/parent")
			.then((response) => {
				if (response.status === 200) {
					props.signIn();
					props.fetchUserInfo();
					props.fetchUserPackages();
					props.fetchCurrentUserDetails();
					props.fetchFamily();
					history.push("/dashboard");
				}
			})
			.catch((e) => {
				console.log("Profile LOGOUT error", e.response);
			});
	};

	useEffect(() => {
		if (user.role === "patient") {
			fetchFamily();
		}
	}, [fetchFamily, user]);

	useEffect(() => {
		const body = document.querySelector("body");

		body.classList.toggle("modal-opened", isModalVisible);
	}, [isModalVisible]);

	const renderNavItems = () => {
		return listLinks
			.filter((item) => {
				if (user.role && user.role === "admin") {
					return item.role === "admin";
				}
				return user.role === item.role || item.role === "family";
			})
			.map((item, index) => {
				const { name, link, icon, exact } = item;

				return (
					<Fragment key={index}>
						{user.role && (
							<SidebarNavItem key={`sidebar-link-${index}`}>
								<SidebarNavLink
									onClick={toggleSidebar}
									to={link}
									exact={exact}
									activeClassName="active"
								>
									<SidebarNavLinkIcon>
										<ReactSVG src={icon} />
									</SidebarNavLinkIcon>
									{name}
								</SidebarNavLink>
							</SidebarNavItem>
						)}
					</Fragment>
				);
			});
	};

	const renderSmallProfile = () => {
		if (isEmpty(user)) {
			return <ProfileSmall gender={"male"} name={"Име Фамилия"} />;
		} else {
			return (
				<ProfileSmall
					name={`${user.firstName} ${user.lastName}` || "Име Фамилия"}
					initials={user.initials}
					gender={user.medicalProfile.gender || "male"}
				/>
			);
		}
	};

	return (
		<Sidebar isOpen={isOpen}>
			<SidebarToggle isOpen={isOpen} onClick={toggleSidebar}>
				{isOpen ? "X" : "☰"}
			</SidebarToggle>

			<SidebarProfile>{renderSmallProfile()}</SidebarProfile>

			<SidebarNav>{renderNavItems()}</SidebarNav>

			{user.role === "family" && (
				<SidebarBottom>
					<button
						type="button"
						className="btn btn-backtomain"
						onClick={loginToMain}
					>
						Към главния профил
					</button>
				</SidebarBottom>
			)}
		</Sidebar>
	);
};

export default connect(
	(state) => ({
		user: state.user,
		family: state.family.list,
	}),
	{
		signIn,
		fetchUserInfo,
		fetchUserPackages,
		fetchCurrentUserDetails,
		fetchFamily,
	}
)(DashboardSidebar);
