import styled from 'styled-components';

export const Accordion = styled.div`
	margin-bottom: 7px;
	border: 2px solid rgba(0,0,0, .02);
	border-radius: 4px;
	background: #fff;
	transition: background .3s;

	&.active {
		border-color: rgba(0,0,0, 0);
		background: #F4F4F9;

		.accordion__toggle svg {
			transform: rotate(180deg);
		}
	}

	.accordion__head {
		position: relative;
		min-height: 52px;
		padding: 10px 62px 10px 24px;
	}

	.accordion__toggle {
		position: absolute;
		top: 17px;
		right: 12px;
		display: inline-block;
		padding: 10px;
		border: 0;
		border-radius: 8px;
		outline: 0;
		background: transparent;
		cursor: pointer;
		appearance: none;
		transition: color .3s, background .3s;

		&:hover {
			background: var(--blue);
			color: #fff;
		}

		svg {
			transition: transform .3s;
		}
	}

	.accordion__body {
		overflow: hidden;
		transition: max-height .5s;
		will-change: max-height;
	}

	.accordion__body__inner {
		padding: 0 24px;
	}
`;
