import styled from "styled-components";

export const ProfileFamily = styled.div`
	.profile__table__row {
		display: flex;
        justify-content: space-between;
        padding: 15px 0 5px 0;
	}
	.profile__table__col {
        text-align: center;
		width: 33%;
	}
	.profile__table__label {
		font-size: 12px;
	}

    .profile__add {
        margin-top: 20px;
    }

	@media (max-width: 768px) {
		.profile__table__row {
			gap: 20px;
		}
		.desktop__only {
			display: none;
		}
		.profile__table__col {
			width: 100%;
		}
	}
`;
