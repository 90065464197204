import React, { useEffect } from "react";
import { api } from "@/apis";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { signOut } from "@/ducks/auth";

import logoutSrc from "@/assets/images/ico-logout.svg";

import Section from "@/components/section/Section/Section";
import Tabs from "@/components/Tabs/Tabs";
import FamilyProfile from '@/components/family/FamilyProfile/FamilyProfile'

import FormPersonalDetails from "@/components/forms/formsProfile/FormPersonalDetails";
import FormProfileDetails from "@/components/forms/formsProfile/FormProfileDetails";
import Questionnaire from "../../questionnaire/Questionnaire";
import { json, singlePageJson } from "../../questionnaire/questionnaireData";
// import PackageHistoryList from '@/components/packages/PackageHistoryList/PackageHistoryList';

const ProfileComponent = (props) => {
	const { user } = props;
	const history = useHistory();

	useEffect(() => {
		if (user.role === "admin") {
			history.push("/dashboard/admin");
		}
	}, [user, history]);

	const handleLogout = () => {
		api
			.post("/auth/logout")
			.then((response) => {
				props.signOut();
				localStorage.clear();
			})
			.catch((e) => {
				console.log("Profile LOGOUT error", e.response);
			});
	};

	return (
		<div>
			<Section addMargin>
				<Section.Head flex flexDirection>
					<h3>Профил</h3>

					<button
						type="button"
						className="link-back section__back btn__logout"
						aria-label="Изход от профила"
						onClick={handleLogout}
					>
						<img src={logoutSrc} alt="Иконка за излизане от профила" />
						Изход
					</button>
				</Section.Head>

				<Section.Body>
					{user.role === "patient" ? (
						<Tabs addMargin={true}>
							<Tabs.Tab linkName="Лични Данни">
								<FormPersonalDetails />
								<FormProfileDetails />
								{/* <Questionnaire json={json} /> */}
							</Tabs.Tab>
							<Tabs.Tab linkName="Медицински Данни">
								<Questionnaire json={singlePageJson} />
								{/* TODO Implement and update this part to show content */}
							</Tabs.Tab>
							<Tabs.Tab linkName="Семейство">
								<FamilyProfile />
								{/* <FormPasswordChange /> */}
								{/* TODO Update this part to show content */}
							</Tabs.Tab>
							{/* <Tabs.Tab linkName="Edit your survey">
								<Questionnaire json={json} />
							</Tabs.Tab> */}
							{/* <Tabs.Tab linkName="История на поръчките">
								<PackageHistoryList />
							</Tabs.Tab> */}
						</Tabs>
					) : (
						<Tabs>
							<Tabs.Tab linkName="Лични детайли">
								<FormPersonalDetails />
							</Tabs.Tab>
							<Tabs.Tab></Tabs.Tab>
						</Tabs>
					)}
				</Section.Body>
			</Section>
		</div>
	);
};

export default connect(
	(state) => ({
		user: state.user,
	}),
	{
		signOut,
	}
)(ProfileComponent);
