const translationMap = {
	"Sedentary (little to no physical activity)":
		"Седящ (малко или никаква физическа активност)",
	"Aerobic exercises (e.g., running, cycling, swimming)":
		"Аеробни упражнения (например, бягане, колоездене, плуване)",
	"Strength training (e.g., weightlifting, resistance exercises, bodyweight exercises)":
		"Силов тренинг (например, тежко алек, упражнения със съпротивление, упражнения с тегло на тялото)",
	"Flexibility and mobility exercises (e.g., yoga, stretching, Pilates)":
		"Упражнения за гъвкавост и подвижност (например, йога, стречинг, Пилатес)",
	"High-intensity interval training (HIIT)":
		"Тренировка с висока интензивност с интервали (HIIT)",
	"Balance exercises (e.g., tai chi, stability exercises)":
		"Упражнения за баланс (например, тай чи, упражнения за стабилност)",
	"Endurance activities (e.g., hiking, long walks)":
		"Активности за издръжливост (например, хайкинг, дълги разходки)",
	"No role": "Няма роля",
	Frequently: "Често",
	"Three or more": "Три или повече",
	"Less than 6 hours": "По-малко от 6 часа",
	Excellent: "Отлично",
	"Yes, consistently": "Да, постоянно",
	"Rarely or never": "Рядко или никога",
	Never: "Никога",
	"Very often": "Много често",
	Often: "Често",
	Daily: "Ежедневно",
	"Very well": "Много добре",
	"Yes, currently": "Да, в момента",
	None: "Никакви",
	"Yes, regularly": "Да, редовно",
	"Very enjoyable and sustainable": "Много удовлетворително и устойчиво",
	"Enjoyable but not sustainable": "Удовлетворително, но неустойчиво",
	"Sustainable but not enjoyable": "Устойчиво, но неудовлетворително",
	"Neither enjoyable nor sustainable": "Нито удоволствие, нито устойчивост",
	"Animal sources (meat, fish, poultry)":
		"Животински източници (мясо, риба, птици)",
	"Plant-based sources (legumes, tofu, tempeh)":
		"Растителни източници (бобови, тофу, темпе)",
	"Combination of both": "Комбинация от двете",
	"Healthy fats (avocado, nuts, olive oil)":
		"Здравословни мазнини (авокадо, ядки, зехтин)",
	"Saturated fats (butter, fatty meats)":
		"Наситени мазнини (масло, масни меса)",
	Occasionally: "От време на време",
	"Regularly, but in moderation": "Редовно, но в умереност",
};

const translationMapArray = {
	Affirmations: "Потвърждения",
	Journaling: "Водене на дневник",
	"Cognitive-behavioral techniques": "Когнитивно-поведенчески техники",
	"Seeking professional counseling": "Търсене на професионална консултация",
	"Pursuing personal goals": "Стремеж към лични цели",
	"Volunteering or helping others": "Доброволчество или помощ на другите",
	"Engaging in spiritual or religious practices":
		"Участие в духовни или религиозни практики",
	"Finding meaning in work or relationships":
		"Намиране на смисъл в работата или отношенията",
	"Regular exercise": "Редовни тренировки",
	"Mindfulness or meditation": "Нацелено внимание или медитация",
	"Seeking support from friends or family":
		"Търсене на подкрепа от приятели или семейство",
	"Engaging in hobbies or activities": "Участие в хобита или занимания",
	"Weight management": "Управление на теглото",
	"General health optimization (or specific symptoms)":
		"Оптимизация на общественото здраве (или конкретни симптоми)",
	"Find your personalized exercise routine":
		"Намерете своя персонализирана тренировъчна програма",
	"Regulating hormone balance": "Регулиране на баланса на хормоните",
	"Improve dietary habits": "Подобряване на хранителните навици",
	"Aerobic exercises (e.g., running, cycling, swimming)":
		"Аеробни упражнения (например, бягане, колоездене, плуване)",
	"Strength training (e.g., weightlifting, resistance exercises, bodyweight exercises)":
		"Силов тренинг (например, тежко алек, упражнения със съпротивление, упражнения с тегло на тялото)",
	"Flexibility and mobility exercises (e.g., yoga, stretching, Pilates)":
		"Упражнения за гъвкавост и подвижност (например, йога, стречинг, Пилатес)",
	"High-intensity interval training (HIIT)":
		"Тренировка с висока интензивност с интервали (HIIT)",
	"Balance exercises (e.g., tai chi, stability exercises)":
		"Упражнения за баланс (например, тай чи, упражнения за стабилност)",
	"Endurance activities (e.g., hiking, long walks)":
		"Активности за издръжливост (например, хайкинг, дълги разходки)",
};

export function translateArray(arr) {
	return arr.map((item) => translationMapArray[item] || item);
}

export function translateValues(obj) {
	const translatedObj = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];

			if (typeof value === "object" && value !== null) {
				translatedObj[key] = translateValues(value);
			} else {
				translatedObj[key] = translationMap[value] || value;
			}
		}
	}

	return translatedObj;
}
